import React, { useState, useRef, useEffect } from 'react';
import { MoreVertical, Edit, Trash, X, Pointer } from 'lucide-react';
import tracelableicon from '../../assets/images/tracelabel.png';
import uploadicon from '../../assets/images/upload.gif';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumb from '../../utils/Breadcrumb';
import Loader from '../../utils/Loader';
import { AlertNotification } from '../../utils/AlertNotification';
import { DeleteConfirmationModal } from '../../utils/DeleteConfirmationModal';

import {
    uploadFilesInDocproProject,
    getDocproDetails,
    deleteDocproProjectFile,
    updateDocproFileStatus,
    performTranslation,
    performOCR,
    docproIndexing,
    docproIndexingStatus
} from '../../services/chatService';

import { setFlag } from '../../store/actions';

import { Box, Typography, CircularProgress, Paper } from '@mui/material';
import { LinearProgress } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const DOCUMENT_API_URL = process.env.REACT_APP_DOCUMENT;

const GreenTickIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z" fill="#4CAF50" />
    </svg>
);

const DocProModal = () => {
    const activeUser = useSelector((state) => state.activeUser);
    const activeUserName = activeUser.username;
    const activeUserId = activeUser.user_id;

    const selectedDocProProject = useSelector((state) => state.selectedDocProProject);

    const [activeTab, setActiveTab] = useState(0);
    const [searchQuery, setSearchQuery] = useState(""); // State for search input
    const [openDropdownIndex, setOpenDropdownIndex] = useState(null);

    const [dragActive, setDragActive] = useState(false);
    const [filesToUpload, setFilesToUpload] = useState([]);
    const [uploadingFiles, setUploadingFiles] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState([]);

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('info');
    const [isButtonsDisabled, setIsButtonsDisabled] = useState(true);
    const [selectedRows, setSelectedRows] = useState([]);
    const [isDocumentIndexEnabled, setIsDocumentIndexEnabled] = useState(true);
    const [isUploadOpen, setIsUploadOpen] = useState(false);


    const [loading, setLoading] = useState(false);


    const dropdownRefs = useRef({});
    const dispatch = useDispatch();

    const [processingDocument, setProcessingDocument] = useState('');

    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const [fileToDelete, setFileToDelete] = useState(null);

    // ------------------------------------------ DocPro Translation
    const [isPerformOCROpen, setIsPerformOCROpen] = useState(false);
    const [ocrResult, setOCRResult] = useState('');
    // ------------------------------------------ DocPro Translation

    // ------------------------------------------ DocPro Translation
    const [isTranslateOpen, setIsTranslateOpen] = useState(false);
    const [originalContent, setOriginalContent] = useState('');
    const [translatedContent, setTranslatedContent] = useState('');
    // ------------------------------------------ DocPro Translation

    // ------------------------------------------ Document Indexing
    const [showDocumentIndexAlert, setShowDocumentIndexAlert] = useState(false);
    const [indexingStatus, setIndexingStatus] = useState('');
    const [indexingMessage, setIndexingMessage] = useState('');
    // ------------------------------------------ Document Indexing

    useEffect(() => {
        dispatch(setFlag('overlayFlag', isUploadOpen || isTranslateOpen));
    }, [isUploadOpen, isTranslateOpen]);

    // ===========================================================================
    const fetchFiles = async () => {
        try {
            console.log("selectedDocProProject: ", selectedDocProProject);
            const response = await getDocproDetails(activeUserName, selectedDocProProject);

            if (response?.status === true) {
                const { folder_path, total_files, files } = response.results;

                const formattedFiles = files.map((file) => ({
                    docproid: null,
                    name: file.document_name,
                    status: file.status,
                    updatedOn: new Date(file.last_updated).toLocaleString(),
                    owner: file.owner,
                    lastUpdatedBy: file.last_updated_by,
                    isActive: Boolean(file.is_active),
                }));

                setUploadedFiles(formattedFiles);
            } else {
                console.error("Unexpected response format:", response);
            }
        } catch (error) {
            console.error("Error fetching uploaded files:", error);
        }
    };

    useEffect(() => {
        console.log("page loaded")
        fetchFiles();
    }, [activeUserId, activeUser.username]);
    // ===========================================================================

    // useEffect(() => {
    //     const handleClickOutside = (event) => {
    //         if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
    //             setOpenDropdownIndex(null);
    //         }
    //     };

    //     document.addEventListener('mousedown', handleClickOutside);
    //     return () => document.removeEventListener('mousedown', handleClickOutside);
    // }, []);

    const toggleDropdown = (index) => {
        setOpenDropdownIndex(openDropdownIndex === index ? null : index);
    };
    // Function to handle search input
    const handleSearchChange = (e) => {
        // setSearchQuery(e.target.value);
        const query = e.target.value;
        console.log("Search Query:", query); // Log the search query to the console
        setSearchQuery(query);
    };

    // Filter files based on search query
    const filteredFiles = uploadedFiles.filter((file) =>
        file.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // useEffect(() => {
    //     const handleClickOutside = (event) => {
    //         const isOutsideClick = Object.values(dropdownRefs.current).every(
    //             (ref) => ref && !ref.contains(event.target)
    //         );

    //         if (isOutsideClick) {
    //             console.log("Click detected outside the dropdown. Closing.");
    //             setOpenDropdownIndex(null); // Close all dropdowns
    //         }
    //     };

    //     document.addEventListener("mousedown", handleClickOutside);
    //     return () => {
    //         document.removeEventListener("mousedown", handleClickOutside);
    //     };
    // }, []);
    // ----------------------------------------------------------------------------------------------

    // Toggle the selection of a row
    // const toggleRowSelection = (index) => {
    //     const newSelectedRows = selectedRows.includes(index)
    //         ? selectedRows.filter((rowIndex) => rowIndex !== index)
    //         : [...selectedRows, index];

    //     setSelectedRows(newSelectedRows);
    //     setIsButtonsDisabled(newSelectedRows.length !== 1); // Enable buttons only when one row is selected

    //     // Check if the selected row has a status of "OCR" for "Document Index"
    //     const selectedFile =
    //         newSelectedRows.length === 1
    //             ? uploadedFiles[newSelectedRows[0]]
    //             : null;

    //     setIsDocumentIndexEnabled(
    //         newSelectedRows.length === 1 && selectedFile?.status === "OCR"
    //     );
    // };
    const toggleRowSelection = (index) => {
        // Check if the current index is already selected
        const isCurrentlySelected = selectedRows.includes(index);

        // Toggle selection: deselect if already selected, otherwise select
        const newSelectedRows = isCurrentlySelected ? [] : [index];

        setSelectedRows(newSelectedRows);
        setIsButtonsDisabled(newSelectedRows.length !== 1); // Enable buttons only when one row is selected

        // Check if the selected row has a status of "OCR" for "Document Index"
        const selectedFile = newSelectedRows.length === 1 ? uploadedFiles[newSelectedRows[0]] : null;

        // setIsDocumentIndexEnabled(
        //     newSelectedRows.length === 1 && selectedFile?.status === "OCR"
        // );
    };


    // ----------------------------------------------------------------------------------------------
    const RefreshMyDocuments = async () => {
        setSelectedRows([]);
        setUploadedFiles([]);
        await fetchFiles();
    };
    // ----------------------------------------------------------------------------------------------
    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);

        const files = [...e.dataTransfer.files];
        const validExtensions = ['.pdf', '.jpg', '.jpeg', '.png'];

        // Validate files by extension
        const validFiles = files.filter(file => {
            const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
            return validExtensions.includes(fileExtension);
        });

        if (validFiles.length) {
            setFilesToUpload(prev => [
                ...prev,
                ...validFiles.map(file => ({
                    file,
                    name: file.name,
                })),
            ]);
        }

        // Check for invalid files
        const invalidFiles = files.filter(file => {
            const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
            return !validExtensions.includes(fileExtension);
        });

        if (invalidFiles.length) {
            setAlertType('error');
            setAlertMessage('Unsupported file type(s) detected. Please upload only PDF, JPG, JPEG, or PNG files.');
            setShowAlert(true);
        }
    };


    const handleFileInput = (e) => {
        const files = [...e.target.files];
        const validFiles = files.filter(file =>
            file.type === "application/pdf" || file.type === "text/csv"
        );

        if (validFiles.length) {
            setFilesToUpload(prev => [...prev, ...validFiles.map(file => ({
                file,
                name: file.name
            }))]);
        }
    };

    const handleUpload = async () => {
        if (filesToUpload.length === 0) {
            setIsUploadOpen(false);
            setAlertType('error');
            setAlertMessage('No files selected. Please add files to upload.');
            setShowAlert(true);
            return; // Prevent further execution
        }

        // let alrtMsg = '';
        setUploadingFiles(
            filesToUpload.map((file) => ({
                name: file.name,
                progress: 0,
            }))
        );

        let alertMsg = '';
        let alertTyp = '';

        try {
            // Simulate upload progress for each file
            for (const file of filesToUpload) {
                await simulateFileUpload(file); // Simulate upload for the file

                // Call your actual upload function here
                const response = await uploadFilesInDocproProject(
                    activeUserName,
                    selectedDocProProject,
                    [file]
                );

                if (response.status) {
                    // Update uploadedFiles state
                    setUploadedFiles((prev) => [...prev, { name: file.name, progress: 100 }]);

                    alertMsg = response.message;
                    alertTyp = 'success';
                } else {
                    alertMsg = response.message || 'File upload failed.';
                    alertTyp = 'error';
                }
            }

            // Fetch the latest uploaded files
            await fetchFiles(); // Refresh uploaded files list
        } catch (error) {
            console.error('Error during file upload:', error.message);
            alertMsg = `Upload failed: ${error.message}`;
            alertTyp = 'error';
        } finally {
            setIsUploadOpen(false);

            setAlertMessage(alertMsg);
            setAlertType(alertTyp);
            setShowAlert(true);

            setFilesToUpload([]); // Clear filesToUpload
            setUploadingFiles([]); // Clear uploadingFiles
        }
    };

    const handleUploadClose = (e) => {
        console.log("close button clicked")
        setIsUploadOpen(false)
        setFilesToUpload([]); // Clear filesToUpload
        setUploadingFiles([]); // Clear uploadingFiles
    };


    // Simulate file upload with progress updates
    const simulateFileUpload = (file) =>
        new Promise((resolve) => {
            let progress = 0;

            const interval = setInterval(() => {
                setUploadingFiles((prev) =>
                    prev.map((f) =>
                        f.name === file.name ? { ...f, progress: Math.min(progress + 20, 100) } : f
                    )
                );

                progress += 20;

                if (progress >= 100) {
                    clearInterval(interval);
                    resolve(); // Resolve the promise once upload completes
                }
            }, 1000); // Increment progress every 1 second
        });


    const removeUploadingFile = (fileName) => {
        setUploadingFiles(prev => prev.filter(file => file.name !== fileName));
    };
    // ===========================================================================

    const handleDeleteConfirmation = (name) => {
        console.log("Delete confirmation triggered for Name:", name);
        setFileToDelete(name);
        setDeleteConfirmationOpen(true); // Open the confirmation dialog
        setOpenDropdownIndex(null)
    };

    // Method to confirm the delete action
    const confirmDelete = async () => {
        console.log("Confirm delete triggered for file:", fileToDelete);
        if (!fileToDelete) return; // Ensure there's a file selected

        try {
            const response = await deleteDocproProjectFile(activeUserName, selectedDocProProject, fileToDelete);
            if (response?.status) {
                setUploadedFiles([]); // Clear uploaded files
                await fetchFiles(); // Fetch the updated file list
                setShowAlert(true);
                // setAlertMessage(alrtMsg || 'Upload completed successfully!');
                setAlertMessage(`The document "${fileToDelete}" has been successfully deleted.`);
                setAlertType('success');
            } else {
                console.error("Error deleting file:", response.message);
                setAlertType('error');
                setAlertMessage(response.message);
                setShowAlert(true);
            }
        } catch (error) {
            console.error("Error during file deletion:", error);
            setAlertType('error');
            setAlertMessage(`Error: ${error.message}`);
            setShowAlert(true);
        } finally {
            setDeleteConfirmationOpen(false); // Close the dialog
            setFileToDelete(null); // Clear the file to delete
        }
    };

    // -------------------------------------------------------------------- Perform OCR start
    const handlePerformOCR = async () => {
        if (selectedRows.length === 1) {
            const selectedFile = uploadedFiles[selectedRows[0]];
            const selectedFileName = selectedFile.name;
            console.log("selectedFile.name: ", selectedFileName)
            setProcessingDocument(selectedFileName);
            setIsPerformOCROpen(true);

            setLoading(true);
            setOCRResult('');

            try {
                const folderPaths = [`${selectedDocProProject}/${selectedFileName}`];

                const response = await performOCR(activeUserName, folderPaths);

                if (response?.status) {
                    if (response.results?.length > 0) {
                        const firstResult = response.results[0];
                        if (firstResult.extracted_text) {
                            setOCRResult(firstResult.extracted_text);

                            RefreshMyDocuments();
                        } else {
                            setOCRResult('No text extracted or invalid response from OCR service.');
                        }
                    }
                }
                else {
                    setOCRResult(`OCR failed: ${response.message}`);
                }
            } catch (err) {
                setOCRResult(`OCR failed: ${err.message}`);
            } finally {
                setLoading(false);
            }
        }
    };

    const handlePerformOCRClose = async () => {
        setIsPerformOCROpen(false);
        setOCRResult('');
        setLoading(false);
    };
    // -------------------------------------------------------------------- Perform OCR end

    // -------------------------------------------------------------------- Document Translation start
    const handleTranslate = async () => {
        if (selectedRows.length === 1) {
            const selectedFile = uploadedFiles[selectedRows[0]];
            setProcessingDocument(selectedFile.name);
            setIsTranslateOpen(true);
            setLoading(true);

            try {
                const folderPaths = [`${selectedDocProProject}/${selectedFile.name}`];

                const response = await performTranslation(activeUserName, folderPaths);

                if (response?.status) {
                    if (response.results?.length > 0) {
                        const firstResult = response.results[0];
                        setOriginalContent(firstResult.original_content);
                        setTranslatedContent(firstResult.translated_content);

                        RefreshMyDocuments();
                    }
                }
                else {
                    setTranslatedContent('Translation failed: ' + response.message);
                }
            } catch (err) {
                setTranslatedContent('Translation failed: ' + err.message);
            } finally {
                setLoading(false);
            }
        }
    }

    const handleTranslationClose = async () => {
        setIsTranslateOpen(false);
        setOriginalContent('');
        setTranslatedContent('');
        setLoading(false);
    };
    // -------------------------------------------------------------------- Document Translation close

    // -------------------------------------------------------------------- Document Indexing start
    const startIndexing = async () => {
        setIndexingMessage('');
        setIndexingStatus('running');

        try {
            const response = await docproIndexing(activeUserName, selectedDocProProject);
            console.log("Indexing response: ", response);

            if (response?.status === true) {
                setIndexingStatus('running');
            } else {
                setIndexingStatus('error');
            }
            setIndexingMessage(response.message);
        } catch (error) {
            setIndexingStatus('error');
            setIndexingMessage(error.message);
            console.error('Indexing error:', error);
        }
    };

    const checkStatus = async () => {
        try {
            const response = await docproIndexingStatus(activeUserName, selectedDocProProject);
            if (response?.status === true) {
                setIndexingStatus('completed')

                setUploadedFiles([]);
                await fetchFiles();
            } else {
                setIndexingStatus('error')
            }

            setIndexingMessage(response.message);

        } catch (error) {
            setIndexingMessage(error.message);
            console.error('Status check error:', error);
        }
    };

    useEffect(() => {
        let interval;
        if (indexingStatus === 'running' || indexingStatus.toLowerCase() === 'inprogress') {
            interval = setInterval(checkStatus, 5000);
        }
        return () => {
            if (interval) clearInterval(interval);
        };
    }, [indexingStatus]);


    const handleDocumentIndex = async () => {
        console.log("handleDocumentIndex")
        setShowDocumentIndexAlert(true)
        await startIndexing()
    };
    // -------------------------------------------------------------------- Document Indexing end


    // // -------------------------------------------------------------------- Document Indexing start
    // const startIndexing = async () => {
    //     setIndexingMessage('');
    //     setIndexingStatus('running');
    //     try {
    //         const response = await fetch(`${DOCUMENT_API_URL}/api/v1/indexing/start`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //         });

    //         if (!response.ok) {
    //             const errorData = await response.json();
    //             throw new Error(errorData.detail || 'Failed to start indexing');
    //         }

    //         const data = await response.json();
    //         setIndexerName(data.indexer_name);
    //     } catch (error) {
    //         setIndexingStatus('error');
    //         setIndexingMessage(error.message);
    //         console.error('Indexing error:', error);
    //     }
    // };

    // const checkStatus = async () => {
    //     if (!indexerName) return;

    //     try {
    //         const response = await fetch(`${DOCUMENT_API_URL}/api/v1/indexing/status/${indexerName}`);
    //         if (!response.ok) {
    //             const errorData = await response.json();
    //             throw new Error(errorData.detail || 'Failed to get indexer status');
    //         }

    //         const data = await response.json();
    //         setIndexingStatus(data.status.toLowerCase());

    //     } catch (error) {
    //         setIndexingMessage(error.message);
    //         console.error('Status check error:', error);
    //     }
    // };

    // useEffect(() => {
    //     let interval;
    //     if (indexingStatus === 'running' || indexingStatus.toLowerCase() === 'inprogress') {
    //         interval = setInterval(checkStatus, 5000);
    //     }
    //     return () => {
    //         if (interval) clearInterval(interval);
    //     };
    // }, [indexingStatus, indexerName]);

    // useEffect(() => {
    //     const handleSuccess = async () => {
    //         try {
    //             const response = await updateDocproFileStatus(activeUserId, myDocProId, 'Extracted');
    //             if (response?.status === "true") {
    //                 setUploadedFiles([]);
    //                 await fetchFiles();
    //             }
    //         } catch (error) {
    //             console.error('Error fetching uploaded files:', error);
    //         }
    //     };

    //     if (indexingStatus === 'success') {
    //         handleSuccess();
    //     }
    // }, [indexingStatus]);


    // const handleDocumentIndex = async () => {
    //     if (selectedRows.length === 1) {
    //         const selectedFile = uploadedFiles[selectedRows[0]];
    //         setProcessingDocument(selectedFile.name);
    //         setMyDocProId(selectedFile.docproid);

    //         // dispatch(setFlag('overlayFlag', true));

    //         setShowDocumentIndexAlert(true)
    //         await startIndexing()
    //     }
    // };
    // // -------------------------------------------------------------------- Document Indexing end






    return (
        <>
            <div id="modal" className="tab_content">
                {/* <div className="container-fluid"> */}
                <div className="d-flex justify-content-between" style={{ marginBottom: "13px" }}>
                    <Breadcrumb title="DocPro" homeLink="#" currentPage={`${selectedDocProProject} ${uploadedFiles && uploadedFiles.length > 0 ? `(${uploadedFiles.length} Docs)` : ''}`} myFlag="showDocProProjects" />

                    <div>
                    <button
                            style={{ paddingBottom: "7px", opacity: "1" }}
                            className="me-1 btn btn-warning btn-sm text-dark right-bar-toggle centered-button "
                            onClick={() => setIsUploadOpen(true)}
                        >
                            1. Upload Documents
                        </button>

                        <button
                            style={{ border: "1px solid #C1C2C7", backgroundColor: "#f8f9fa", color: "#000000", opacity: "1" }}
                            className="btn btn-light btn-sm perocr me-1 centered-button"
                            onClick={handlePerformOCR}
                            disabled={isButtonsDisabled}
                        >
                            2. Perform OCR
                        </button>

                        <button
                            style={{ border: "1px solid #C1C2C7", backgroundColor: "#f8f9fa", color: "#000000", opacity: "1" }}
                            className="btn btn-light btn-sm right-bar-toggle3 me-1 centered-button"
                            disabled={isButtonsDisabled}
                            onClick={handleTranslate}
                        >
                            3. Translate
                        </button>

                        <button
                            style={{ border: "1px solid #C1C2C7", backgroundColor: "#f8f9fa", opacity: "1" }}
                            className="btn btn-light btn-sm centered-button me-1"
                            disabled={!isDocumentIndexEnabled}
                            onClick={handleDocumentIndex}
                        >
                            4. Document Index
                        </button>
                    </div>
                </div>

                <div>
                    <input
                        type="text"
                        placeholder="Search by Document name"
                        className="form-control font-size-12 doc-search "
                        style={{
                            width: '340px',
                            height: '37px',
                            backgroundColor: '#fff',
                            marginBottom: "18px",
                            border: '1px solid #E1E0E3',
                            borderRadius: "8px",
                            marginTop: "18px"

                        }}
                        value={searchQuery}
                        onChange={handleSearchChange}
                    />
                    {filteredFiles.length > 0 ? (
                        <div>
                            <div className="doc-br"></div>
                            <ul className="nav nav-tabs nav-tabs-custom nav-justified w-fit-content" role="tablist">
                                <li className="nav-item">
                                    <a
                                        className={`nav-link ${activeTab === 0 ? 'active' : ''}`}
                                        data-bs-toggle="tab"
                                        onClick={() => setActiveTab(0)}
                                        role="tab"
                                        aria-selected="false"
                                        tabIndex="1"
                                        style={{ cursor: 'pointer', width: '90px' }}
                                    >
                                        <span className="d-none d-sm-block white-space-nowrap" style={{ color: "#12262E", fontWeight: "700" }}>
                                            All
                                        </span>
                                    </a>
                                </li>
                                {/* <li className="nav-item">
                                    <a
                                        className={`nav-link ${activeTab === 1 ? 'active' : ''}`}
                                        data-bs-toggle="tab"
                                        onClick={() => setActiveTab(1)}
                                        role="tab"
                                        aria-selected="false"
                                        tabIndex="2"
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <span className="d-none d-sm-block white-space-nowrap">PDF Documents</span>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className={`nav-link ${activeTab === 2 ? 'active' : ''}`}
                                        data-bs-toggle="tab"
                                        onClick={() => setActiveTab(2)}
                                        role="tab"
                                        aria-selected="false"
                                        tabIndex="3"
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <span className="d-none d-sm-block white-space-nowrap">Perform OCR</span>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className={`nav-link ${activeTab === 3 ? 'active' : ''}`}
                                        data-bs-toggle="tab"
                                        onClick={() => setActiveTab(3)}
                                        role="tab"
                                        aria-selected="false"
                                        tabIndex="4"
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <span className="d-none d-sm-block white-space-nowrap">Translated Documents</span>
                                    </a>
                                </li> */}
                            </ul>
                            <div className="tab-content" style={{ position: 'relative', bottom: '5px', overflowX: 'auto' }}>
                                {activeTab === 0 && (
                                    <div style={{ maxHeight: 'calc(96vh - 198px)', minHeight: '370px', overflowY: 'auto', position: 'relative' }}>
                                        <table className="scroll-vertical-datatable table dt-responsive nowrap w-100 scroll-tab">
                                            <thead
                                                className="table-head fw-bold"
                                                style={{
                                                    backgroundColor: 'rgba(227, 229, 230, 1)',
                                                    position: 'sticky',
                                                    top: -1,
                                                    zIndex: 2,
                                                }}
                                            >
                                                <tr>
                                                    <th></th>
                                                    <th style={{ fontWeight: '500' }}>Document Name</th>
                                                    <th style={{ fontWeight: '500' }}>Status</th>
                                                    <th style={{ fontWeight: '500' }}>Owner</th>
                                                    <th style={{ fontWeight: '500' }}>Updated On</th>
                                                    <th style={{ fontWeight: '500' }}>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody style={{ backgroundColor: 'white' }}>
                                                {filteredFiles.map((file, index) => (
                                                    <tr key={index} className={selectedRows.includes(index) ? 'highlight' : ''}>
                                                        <td
                                                            className="pt-2"
                                                            style={{
                                                                verticalAlign: 'middle',
                                                                color: '#6B7280',
                                                                fontWeight: '100',
                                                            }}
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                className="form-check"
                                                                checked={selectedRows.includes(index)}
                                                                onChange={() => toggleRowSelection(index)}
                                                            />
                                                        </td>
                                                        <td
                                                            className="pt-11px"
                                                            style={{ verticalAlign: 'middle', color: '#6B7280', fontWeight: '100' }}
                                                        >
                                                            {file.name}
                                                        </td>
                                                        <td className="pt-2" style={{ verticalAlign: 'middle' }}>
                                                            <div
                                                                className="w-fit-content pt-1 text-dark pb-1 bg-ext d-flex justify-content-center align-items-center"
                                                                style={{
                                                                    width: '60%',
                                                                    borderRadius: '5px',
                                                                    paddingLeft: '15px',
                                                                    paddingRight: '15px',
                                                                    textTransform: 'capitalize',
                                                                }}
                                                            >
                                                                {file.status}
                                                            </div>
                                                        </td>
                                                        <td
                                                            className="pt-11px"
                                                            style={{ verticalAlign: 'middle', color: '#6B7280', fontWeight: '100' }}
                                                        >
                                                            {file.owner}
                                                        </td>
                                                        <td
                                                            className="pt-11px"
                                                            style={{ verticalAlign: 'middle', color: '#6B7280', fontWeight: '100' }}
                                                        >
                                                            <div
                                                                style={{
                                                                    whiteSpace: 'nowrap',
                                                                    display: 'inline-block',
                                                                    lineHeight: '1.5',
                                                                }}
                                                            >
                                                                <i
                                                                    className="ri-calendar-2-line"
                                                                    style={{
                                                                        marginRight: '4px',
                                                                        verticalAlign: 'middle',
                                                                        fontSize: '14px',
                                                                    }}
                                                                ></i>
                                                                <span>{file.updatedOn}</span>
                                                            </div>
                                                        </td>
                                                        <td
                                                            className="pt-11px"
                                                            style={{ verticalAlign: 'middle', color: '#6B7280', fontWeight: '100' }}
                                                        >
                                                            <div
                                                                className="dropdown d-none d-sm-inline-block"
                                                                ref={(el) => (dropdownRefs.current[index] = el)}
                                                            >
                                                                <MoreVertical
                                                                    className="cursor-pointer"
                                                                    size={18}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        toggleDropdown(index);
                                                                    }}
                                                                />
                                                                <div
                                                                    className={`dropdown-menu dropdown-menu-end ${openDropdownIndex === index ? 'show' : ''}`}
                                                                    style={{
                                                                        minWidth: '100px',
                                                                        position: 'absolute',
                                                                        transform: 'translate3d(-130px, 0px, 0px)',
                                                                    }}
                                                                    onClick={(e) => e.stopPropagation()}
                                                                >
                                                                    <a
                                                                        href="#"
                                                                        className="dropdown-item notify-item"
                                                                        style={{ paddingLeft: '14px' }}
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            e.stopPropagation();
                                                                            handleDeleteConfirmation(file.name);
                                                                        }}
                                                                    >
                                                                        <p
                                                                            style={{
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                                marginBottom: 0,
                                                                                fontSize: '12px',
                                                                            }}
                                                                        >
                                                                            <Trash style={{ paddingRight: '4px' }} size={18} />
                                                                            <span style={{ marginTop: '4px' }}>Delete</span>
                                                                        </p>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : (
                        // Fallback UI when no files are available
                        <div
                        className="col-12 d-flex flex-column align-items-center justify-content-center text-center"
                        style={{
                            backgroundColor: 'white',
                            height: '65vh',
                            borderRadius: '8px',
                            marginTop: "28px"
                        }}
                    >
                        <img
                            src="/assets/images/nofiles.gif"
                            alt="No results found"
                            width="20%"
                            style={{ paddingTop: "0" }}
                        />
                        <div
                            style={{
                                fontSize: '20px',
                                fontWeight: 600,
                                color: 'rgba(29, 18, 46, 1)',
                                lineHeight: '45px',
                            }}
                            className="mt-3"
                        >
                            No File or Documents
                        </div>
                        <p style={{ fontSize: '16px', color: 'rgba(0, 0, 0, 1)' }}>
                            Looks like there are no files or documents uploaded yet
                            <br />
                            Ready to get started? Just tap the button to upload one!
                        </p>
                        <button
                            className="btn btn-warning btn-sm text-dark right-bar-toggle centered-button"
                            style={{ fontSize: "12.6px", fontWeight: "500" }}
                            onClick={() => setIsUploadOpen(true)}
                        >
                            Upload Documents
                        </button>
                    </div>
                    
                    )}
                    </div>



                    {/* Upload Sidebar */}
                    {isUploadOpen && (
                        // style={{marginTop: '62px'}}
                        <div className="right-bar p-3 " >
                            <div data-simplebar className="h-100">
                                <div className="d-flex align-item-center justify-content-between">
                                    <h6 className=" mb-0 fw-medium text-dark">Upload Document</h6>
                                    <X className="cursor-pointer ms-auto" onClick={() => setIsUploadOpen(false)} size={16} />
                                </div>
                                <div className="trace-label p-2 d-flex align-item-center mt-1 w-fit-content" style={{ marginBottom: "14px" }}><img src={tracelableicon} className="w-14px me-2" alt="trace label" />
                                    <h6 className="font-size-10 mb-0 text-dark">Add your document to begin processing and exploration</h6>
                                </div>

                                <div className={`upload-file p-3 mt-2 border-2 border-dashed rounded-lg p-6 text-center  cursor-pointer ${dragActive ? 'border-warning bg-warning bg-opacity-10' : 'border-gray-300'}`}
                                    onDragEnter={handleDrag}
                                    onDragLeave={handleDrag}
                                    onDragOver={handleDrag}
                                    onDrop={handleDrop}
                                    onClick={() => document.getElementById('file-upload').click()}
                                >
                                    <input
                                        type="file"
                                        className="hidden"
                                        id="file-upload"
                                        multiple
                                        accept=".pdf, .jpg, .jpeg, .png"
                                        onChange={handleFileInput}
                                        style={{ display: 'none' }}
                                    />
                                    <div className="text-center">
                                        <img src={uploadicon} className="w-100px" />
                                    </div>
                                    <h6 className="text-dark fw-medium text-center mb-1">Drag and Drop files here</h6>
                                    <p className="font-size-10 text-center mb-2">Files supported : JPG, JPEG, PNG, PDF</p>
                                </div>
                                
                                <div className="px-3">
                                    {/* Display files ready for upload */}
                                    {filesToUpload.length > 0 && (
                                        <div className="mt-4">
                                            {filesToUpload.map((file, index) => (
                                                <div key={index} className="p-2 mb-2">
                                                    <div className="d-flex align-items-center justify-content-between" style={{ fontSize: '11px', color: '#12262E', fontWeight: '100', marginLeft: "-10px" }}>
                                                        <p className="mb-0 text-truncate" style={{ marginLeft: "-8px", fontSize: "12px" }}>{file.name}</p>
                                                        <X
                                                            className="cursor-pointer ms-2"
                                                            style={{ marginRight: "-17px" }}
                                                            size={16}
                                                            onClick={() =>
                                                                setFilesToUpload((prev) =>
                                                                    prev.filter((f) => f.name !== file.name)
                                                                )
                                                            }
                                                        />
                                                    </div>

                                                    {/* Add progress bar below each file name */}
                                                    {uploadingFiles.find((f) => f.name === file.name) && (
                                                        <LinearProgress
                                                            variant="determinate"
                                                            value={uploadingFiles.find((f) => f.name === file.name)?.progress || 0}
                                                            sx={{
                                                                height: 5,
                                                                mt: 1,
                                                                width: "21vw",
                                                                marginLeft: "-17px",
                                                                backgroundColor: '#DDF5F5',
                                                                '& .MuiLinearProgress-bar': {
                                                                    backgroundColor: '#5DCDC6',
                                                                },
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    )}


                                    <div className="position-absolute  " style={{ bottom: "6px", height: "28px", width: "90%" }} >
                                        <div className="d-flex " style={{ gap: "10px", marginTop: "-6px" }}>
                                            <button
                                                style={{
                                                    marginLeft: "-13px", width: "fit-content"
                                                }}
                                                className="btn btn-light btn-sm centered-button"
                                                onClick={handleUploadClose}
                                            >
                                                Close
                                            </button>

                                            <button
                                                htmlFor="file-upload"
                                                className="btn btn-warning btn-sm text-dark centered-button "
                                                style={{ marginRight: "-14px" }}
                                                onClick={handleUpload}
                                                disabled={filesToUpload.length === 0}
                                            >
                                                Upload
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}


                    {deleteConfirmationOpen && (
                        <DeleteConfirmationModal
                            message={'Are you sure you want to delete this file?'}
                            onClose={() => setDeleteConfirmationOpen(false)}
                            onDelete={confirmDelete}
                        />
                    )}


                    {/* Alert Notification */}
                    {/* {showAlert && (
                    <>
                        <div className="alert-dismissible pe-3 fade performocr-status fw-medium align-item-center show" role="alert">
                            <div className="pe-4">{alertMessage}</div>
                            <button type="button" className="btn-close p-2" data-bs-dismiss="alert" aria-label="Close" onClick={() => setShowAlert(false)}></button>
                            <div className="d-flex justify-content-end pt-2">
                                <a href="#" className="btn btn-sm btn-dark right-bar-toggle2 preview" onClick={() => setShowAlert(false)}>Close</a>
                            </div>
                        </div>
                    </>
                )} */}


                    {showAlert && (
                        <AlertNotification
                            message={alertMessage}
                            type={alertType}
                            onClose={() => setShowAlert(false)}
                            autoClose={true}
                            autoCloseTime={3000}
                        />
                    )}


                    {/* Document Index Alert Notification */}
                    {showDocumentIndexAlert && (
                        <>
                            <div className="alert-dismissible pe-3 fade performocr-status fw-medium align-item-center show" role="alert">
                                {indexingStatus && (
                                    <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Typography variant="body1" sx={{ mr: 1 }}>
                                                Status:
                                            </Typography>
                                            {indexingStatus === 'completed' || indexingStatus === 'success' ? (
                                                <GreenTickIcon />
                                            ) : indexingStatus === 'error' ? (
                                                <ErrorIcon color="error" />
                                            ) : (
                                                <CircularProgress size={20} />
                                            )}
                                            <Typography variant="body1" sx={{ ml: 1 }}>
                                                {indexingStatus.charAt(0).toUpperCase() + indexingStatus.slice(1)}
                                            </Typography>
                                        </Box>
                                        <Typography variant="body1" sx={{ mt: 1 }}>
                                            {indexingMessage}
                                        </Typography>
                                    </Box>
                                )}
                                {/* <div className="d-flex justify-content-end pt-2">
                                <a href="#" className="btn btn-sm btn-dark right-bar-toggle2 preview" onClick={() => setShowDocumentIndexAlert(false)}>Close</a>
                            </div> */}

                                <div className="d-flex justify-content-end pt-2">
                                    <a
                                        href="#"
                                        className={`btn btn-sm btn-dark right-bar-toggle2 preview ${indexingStatus !== 'completed' && indexingStatus !== 'error' ? 'disabled' : ''}`}
                                        onClick={(e) => {
                                            if (indexingStatus === 'completed') {
                                                // dispatch(setFlag('overlayFlag', false));
                                                setShowDocumentIndexAlert(false);
                                            } else {
                                                e.preventDefault(); // Prevent interaction if the button is disabled
                                            }
                                        }}
                                        style={{ pointerEvents: indexingStatus === 'completed' ? 'auto' : 'none' }}
                                    >
                                        Close
                                    </a>
                                </div>
                            </div>
                        </>
                    )}


                    {/* Perfom OCR */}
                    {isPerformOCROpen && (
                        (
                            <div className="right-bar p-3" style={{ width: '50%' }}>
                                <div data-simplebar className="h-100">
                                    <div className="d-flex align-item-center justify-content-between">
                                        <h6 className="mb-0 fw-medium text-dark">Perform OCR</h6>
                                        <X className="cursor-pointer ms-auto" onClick={handlePerformOCRClose} />
                                    </div>
                                    <div className="trace-label p-2 d-flex align-item-center mt-2 w-fit-content">
                                        <img src={tracelableicon} className="w-14px me-2" alt="trace label" />
                                        <h6 className="font-size-10 mb-0 text-dark">Convert PDF or documents into editable text with precision</h6>
                                    </div>

                                    <div className="d-flex align-item-center justify-content-between pt-2">
                                        <h6 className="text-dark font-size-12 mb-1 pt-2">Selected Document</h6>
                                    </div>
                                    <p className="font-size-10 text-dark mt-0 mb-0">{processingDocument}</p>

                                    <div className="d-flex align-item-center justify-content-between pt-2">
                                        <h6 className="text-dark font-size-12 mb-0 pt-2 pb-1">OCR Results</h6>
                                    </div>

                                    <div
                                        className="trans-box p-3 custom-scrollbar"
                                        style={{
                                            flex: 1,
                                            overflowY: 'auto',

                                        }}
                                    >
                                        <p className="mb-0 font-size-12 ">
                                            {loading ? <Loader /> : ocrResult}
                                        </p>
                                    </div>

                                    <div className="d-flex  gap-2 mt-4" style={{ position: "absolute", bottom: "3px" }}>
                                        <button className="btn btn-light btn-sm centered-button" style={{ width: "fit-content", marginLeft: "2.3px" }} onClick={handlePerformOCRClose}>
                                            Close
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )
                    )}


                    {/* Translate Notification */}
                    {isTranslateOpen && (
                        (
                            <div className="right-bar p-3" style={{ width: '50%' }}>
                                {/* <div data-simplebar className="h-100"> */}
                                <div className="d-flex align-item-center justify-content-between">
                                    <h6 className="mb-0 fw-medium text-dark">Translate Document</h6>
                                    <X className="cursor-pointer ms-auto" onClick={handleTranslationClose} />
                                </div>
                                <div className="trace-label p-2 d-flex align-item-center mt-2 w-fit-content">
                                    <img src={tracelableicon} className="w-14px me-2" alt="trace label" />
                                    <h6 className="font-size-10 mb-0 text-dark">Effortlessly convert your document into the language you need</h6>
                                </div>

                                <div className="d-flex align-item-center justify-content-between pt-2">
                                    <h6 className="text-dark font-size-12 mb-1 pt-2">Selected Document</h6>
                                </div>
                                <p className="font-size-10 text-dark mt-0 mb-0">{processingDocument}</p>

                                <div className="d-flex align-item-center justify-content-between pt-2">
                                    <h6 className="text-dark font-size-12 mb-0 pt-2 pb-1">Original Content :</h6>
                                </div>

                                <div
                                    className="trans-box p-3 "
                                    style={{
                                        flex: 1,
                                        overflowY: 'auto',
                                        height: "30vh"
                                    }}
                                >
                                    <p className="mb-0 font-size-12">
                                        {loading ? <Loader /> : originalContent}
                                    </p>
                                </div>

                                <div className="d-flex  align-item-center justify-content-between pt-2">
                                    <h6 className="text-dark font-size-12 mb-0 pt-2 pb-1">Translated Content :</h6>
                                </div>

                                <div
                                    className="trans-box p-3"
                                    style={{
                                        flex: 1,
                                        overflowY: 'auto',
                                        height: "30vh"
                                    }}
                                >
                                    <p className="mb-0 font-size-12">
                                        {loading ? <Loader /> : translatedContent}
                                    </p>
                                </div>

                                <div className="d-flex  gap-2 mt-4" style={{ position: "absolute", bottom: "8px" }}>
                                    <button className="btn btn-light btn-sm centered-button" style={{ width: "fit-content" }} onClick={handleTranslationClose}>
                                        Close
                                    </button>
                                </div>
                                {/* </div> */}
                            </div>
                        )
                    )}
                </div>
            </>
            );
};

            export default DocProModal;