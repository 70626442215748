import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-bootstrap';
import Configuration from './Configuration';
import DBManagement from './DBManagement';
import FileManagement from './FileManagement';
import ModalSettings from './ModalSettings';
import UserManagement from './UserManagement/UserManagement';
import Breadcrumb from '../../utils/Breadcrumb';

import {
    setFlag,
} from '../../store/actions';

const SettingsTabsComponent = ({ activeTab, setActiveTab }) => {
    const tabs = [
        { id: 'Configuration', label: 'Configuration', href: '#ConfigurationDetails', icon: 'fas fa-cogs' },
        // { id: 'DB Management', label: 'DB Management', href: '#DBManagement', icon: 'fas fa-database' },
        // { id: 'File Management', label: 'File Management', href: '#FileManagement', icon: 'fas fa-folder' },
        { id: 'Modal Settings', label: 'Modal Usage', href: '#ModalSettings', icon: 'fas fa-window-restore' },
        { id: 'User Management', label: 'User Management', href: '#UserManagement', icon: 'fas fa-users' },
    ];

    return (
        <div className="w-100" style={{ paddingBottom: 10 }}>
            <Breadcrumb title="Settings" homeLink="#" currentPage='All Settings' />

            <input
                type="text"
                placeholder="Search by Document name"
                className="form-control font-size-12 doc-search mt-2 "
                style={{
                    width: '340px',
                    height: '37px',
                    backgroundColor: '#fff',
                    marginBottom: "20px",
                    border: '1px solid #E1E0E3',
                    borderRadius: "4px"

                }}
            />

            <div className="d-flex" style={{ paddingRight: '15px', alignItems: 'center' }}>
                <ul
                    className="nav nav-tabs nav-tabs-custom nav-justified"
                    role="tablist"
                    style={{ display: 'flex' }}
                >
                    {tabs.map((tab) => (
                        <li
                            className="nav-item"
                            key={tab.id}
                            style={{ flex: 1 }}
                        >
                            <a
                                className={`w-fit nav-link text-center ${activeTab === tab.id ? 'active' : ''}`}
                                data-bs-toggle="tab"
                                onClick={() => setActiveTab(tab.id)}
                                role="tab"
                                aria-selected={activeTab === tab.id}
                                style={{
                                    cursor: 'pointer',
                                    backgroundColor: activeTab === tab.id ? '#343a40' : 'transparent', // Active tab background
                                    color: activeTab === tab.id ? '#000000' : '#6B7280',
                                    fontWeight: activeTab === tab.id ? '700' : '500',
                                }}
                            >
                                <span className="d-none d-sm-block white-space-nowrap">
                                    {tab.label}
                                </span>
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};


const SettingDetails = () => {
    const dispatch = useDispatch();

    const [activeTab, setActiveTab] = useState('Configuration'); // Default to 'Configuration'

    useEffect(() => {
        if (activeTab === 'User Management') {
            dispatch(setFlag('showUserManagementUserInfo', true));
        }
    }, [activeTab]);

    return (
        <div className="settings">
            <SettingsTabsComponent activeTab={activeTab} setActiveTab={setActiveTab} />

            {/* Conditionally render the component based on the active tab */}
            {activeTab === 'Configuration' && <Configuration />}
            {activeTab === 'DB Management' && <DBManagement />}
            {activeTab === 'File Management' && <FileManagement />}
            {activeTab === 'Modal Settings' && <ModalSettings />}
            {activeTab === 'User Management' && <UserManagement />}
        </div>
    );
};

export default SettingDetails;
