import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getChatResponse, saveChat } from '../../services/chatService';
import { updateExclusiveFlags } from '../../utils/reduxUtils';
import QuestionInputForm from '../QuestionInputForm';
import myDemoData from './data';

import {
    setFlag,
    setUpdateChatHistory,
    addQuestionAndResponse,
    addPartialResponse,
    addCompleteResponse,
    setTriggerRaiseQuestion,
    setActiveHistoryTab,
    setClearQuestionAndResponse
} from '../../store/actions';


function RaiseQuestion() {
    const dispatch = useDispatch();
    const questionInputFormRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const activeHistoryTab = useSelector((state) => state.activeHistoryTab);
    const [myQuestion, setMyQuestion] = useState('');
    const [myAnswer, setMyAnswer] = useState('');
    const [myChartCode, setMyChartCode] = useState('');
    const [myChatId, setMyChatId] = useState('');
    const [mySqlQuery, setMySqlQuery] = useState('');
    const [myQueryResult, setMyQueryResult] = useState('');
    const [myDesc, setMyDesc] = useState('');
    const [mySource, setMySource] = useState('');

    const chatHistory = useSelector((state) => state.updateChatHistory);
    const activeUser = useSelector((state) => state.activeUser);
    const activeUserId = activeUser.user_id;
    const activeuser_access_rules = activeUser.access_rules;

    const triggerQuestion = useSelector((state) => state.triggerRaiseQuestion);

    const processedMessages = new Set()

    useEffect(() => {
        if (activeHistoryTab && myQuestion && myAnswer && myChatId) {
            let updatedChatHistory = { ...chatHistory };
            if (!Array.isArray(updatedChatHistory[activeHistoryTab])) {
                updatedChatHistory[activeHistoryTab] = [{
                    question: myQuestion,
                    response: myAnswer,
                    chartCode: myChartCode,
                    chatid: myChatId,
                    sql_query: mySqlQuery,
                    query_results: myQueryResult,
                    desc: myDesc,
                    source: mySource
                }];
            } else {
                updatedChatHistory[activeHistoryTab] = [
                    ...updatedChatHistory[activeHistoryTab],
                    {
                        question: myQuestion,
                        response: myAnswer,
                        chartCode: myChartCode,
                        chatid: myChatId,
                        sql_query: mySqlQuery,
                        query_results: myQueryResult,
                        desc: myDesc,
                        source: mySource
                    }
                ];
            }

            console.log("before update chat history")

            dispatch(setUpdateChatHistory(updatedChatHistory));
            setMyQuestion("");
            setMyAnswer("");
            setMyChartCode("");
            setMyChatId("");
            setMySqlQuery("");
            setMyQueryResult("");
            setMyDesc("");
            setMySource("");
            dispatch(setClearQuestionAndResponse(''));
        }
    }, [myQuestion, myAnswer, myChartCode, myChatId]);

    const handleSubmit = async (question) => {

        dispatch(setFlag('showUserCustomizeMessage', false));
        dispatch(setFlag('userRaisedQuestionFlag', true));

        setMyQuestion(question);
        setIsLoading(true);
        
        const responseId = `response_${Date.now()}`;

        dispatch(addQuestionAndResponse({
            question,
            response: {
                response_id: responseId,
                text: '',
                chartCode: '',
                sql_query: '',
                query_results: '',
                desc: '',
                source: ''
            }
        }));

        setIsLoading(true);

        try {
            console.log("activeuser_access_rules: ", activeuser_access_rules)
            const reader = await getChatResponse(question, activeuser_access_rules);
            const mySessionId = reader.session_id;

            if (!mySessionId) {
                setIsLoading(false);
                setMyAnswer("");
                dispatch(addPartialResponse(responseId, '', '', '', '', '', ''));
                setMyQuestion(question);
                setMyAnswer('');
                setMyChartCode('');
                setMySqlQuery('');
                setMyQueryResult('')
                setMyDesc('');
                setMySource('');
                questionInputFormRef.current?.clearInput();
                dispatch(setTriggerRaiseQuestion(null));
                throw new Error("Session ID is undefined or null");
            }

            const token = localStorage.getItem('access_token')?.replace(/"/g, '');
            const WEB_SOCKET_URL = process.env.REACT_APP_WEB_SOCKET;
            const fetchWSUrl = `${WEB_SOCKET_URL}/ws/stream_chat/${mySessionId}?token=${token}`;

            const ws = new WebSocket(fetchWSUrl);

            ws.onopen = () => {
                ws.send(JSON.stringify({ question: question }));
            };

            ws.onmessage = (event) => { 
                const message = event.data;
                const partial_log_sql_query = message.replace(`[Session ${mySessionId}]`, '').trim();
 
                // Check if this message has been processed already
                if (processedMessages.has(partial_log_sql_query)) {
                    return;  // Skip processing the duplicate message
                } 
                // If it's not a duplicate, add to the Set
                processedMessages.add(partial_log_sql_query); 
                // Dispatch the action with the message
                dispatch(addPartialResponse(responseId, '', '', partial_log_sql_query, '', '', ''));
            };

            ws.onerror = (error) => {
                console.log("ws.onerror")
                console.log(error.message)

                const partial_log_sql_query = `WebSocket error: ${error.message}`
                dispatch(addPartialResponse(responseId, '', '', partial_log_sql_query, '', '', ''));

            };

            ws.onclose = async () => {
                const API_URL_PYTHON = process.env.REACT_APP_RAG_ML;

                try {
                    const fetch_response = await fetch(`${API_URL_PYTHON}/session_result/${mySessionId}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${localStorage.getItem('access_token').replace(/"/g, '')}`
                        },
                    });

                    if (!fetch_response.ok) {
                        throw new Error('Failed to fetch session result');
                    }

                    const reader = await fetch_response.json();  // Wait for the response to be parsed

                    let { text, chartCode, sql_query, query_results, desc, source } = reader.result;
                    console.log("text: ", text)
                    console.log("chartCode: ", chartCode)
                    console.log("sql_query: ", sql_query)
                    console.log("query_results: ", query_results)
                    console.log("desc: ", desc)
                    console.log("source: ", source)

                    if (sql_query) {
                        processedMessages.add(sql_query);
                        const processedQueriesString = Array.from(processedMessages).join("; ");
                        sql_query = `${processedQueriesString}`;
                    }

                    if (!text || text.trim() === '') {
                        text = "No Response, Something went wrong";
                    }

                    if (!chartCode || chartCode.trim() === '') {
                        chartCode = "";
                    }

                    if (!sql_query || sql_query.trim() === '') {
                        sql_query = "";
                    }

                    if (!query_results || query_results === '') {
                        query_results = "";
                    }

                    if (!desc || desc.trim() === '') {
                        desc = "";
                    }

                    if (!source || source.trim() === '') {
                        source = "";
                    }

                    dispatch(addPartialResponse(responseId, text, chartCode, sql_query, query_results, desc, source));
                    setMyQuestion(question);
                    setMyAnswer(text);
                    setMyChartCode(chartCode);
                    setMySqlQuery(sql_query);
                    setMyQueryResult(query_results)
                    setMyDesc(desc);
                    setMySource(source);

                    // dispatch(addCompleteResponse(responseId, text, chartCode, sql_query, query_results, desc, source));
                    questionInputFormRef.current?.clearInput();
                    dispatch(setTriggerRaiseQuestion(null));

                    // for new chatlist
                    if (!activeHistoryTab) {
                        console.log("check -------------- for new chatlist")
                        const chat_info = {
                            userid: activeUserId,
                            chatid: 0,
                            newchat: true,
                            question: question,
                            response: text,
                            chartcode: chartCode,
                            sql_query: sql_query,
                            query_results: "",
                            desc: desc,
                            source: source
                        };

                        let myFlg2;
                        try {
                            myFlg2 = await saveChat(chat_info);
                        } catch (error) {
                            console.error("Error::2:", error);
                            myFlg2 = { chatid: Math.floor(Math.random() * 10000) + 1 };
                        }

                        setMyChatId(myFlg2.chatid);

                        const newChatHistoryTitle = question + "::" + myFlg2.chatid;
                        dispatch(setActiveHistoryTab(newChatHistoryTitle));
                        updateExclusiveFlags(dispatch, {
                            showQANewChat: false,
                        });

                    } else {
                        const chat_info = {
                            userid: activeUserId,
                            chatid: chatHistory[activeHistoryTab][0].chatid,
                            newchat: false,
                            question: question,
                            response: text,
                            chartcode: chartCode,
                            sql_query: sql_query,
                            query_results: "",
                            desc: desc,
                            source: source
                        };

                        try {
                            const myFlg = await saveChat(chat_info);
                            setMyChatId(chatHistory[activeHistoryTab][0].chatid);
                        } catch (error) {
                            console.error("Error::3:", error);
                        }
                    }

                } catch (error) {
                    console.error("Error::3:", error);
                }
                questionInputFormRef.current?.clearInput(); // Use ref to clear input
                setIsLoading(false);
            }
        }catch (e) { 
            questionInputFormRef.current?.clearInput(); // Use ref to clear input
            setIsLoading(false);
        }
    }

    return (
        <div>
            <QuestionInputForm
                onSubmit={handleSubmit}
                isLoading={isLoading}
                triggerQuestion={triggerQuestion}
            />
        </div>
    );
}

export default RaiseQuestion;
