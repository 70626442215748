import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HistoryList from './Q&A/HistoryList';
import { updateExclusiveFlags } from '../utils/reduxUtils';

import { Tooltip, tooltipClasses } from '@mui/material';


import {
    setFlag,
    loadTaskIntoRightContent,
    setUserQuestion,
    setClearQuestionAndResponse,
    setActiveHistoryTab
} from '../store/actions';
import { Tune } from '@mui/icons-material';
import { fontSize, styled } from '@mui/system';



const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#2B3D44',
        fontSize: "12px",
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: '#2B3D44',
    },
});

function LeftSidebar() {
    const renderMode = process.env.REACT_APP_RENDER;

    console.log("LeftSidebar.js" + renderMode)

    const dispatch = useDispatch();
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [isSettingsOpen, setIsSettingsOpen] = useState(false);
    const [showAllHistory, setShowAllHistory] = useState(false);
    const [historyItemsCount, setHistoryItemsCount] = useState(0);
    const [visibleItems, setVisibleItems] = useState(6);
    const [isQuestionResponseCollapsed, setIsQuestionResponseCollapsed] = useState(false);
    const [isHistoryOpen, setIsHistoryOpen] = useState(false);
    const [isDocProcOpen, setIsDocProcOpen] = useState(false);
    const [isTaskOpen, setIsTaskOpen] = useState(false);
    const [isMLTaskHistoryOpen, setIsMLTaskHistoryOpen] = useState(false);
    const [activeMenu, setActiveMenu] = useState('home');
    const [activeItem, setActiveItem] = useState(null);
    const [activeDocProItem, setActiveDocProItem] = useState(null);

    // ------------------------------------------------------------------------------
    const mlTaskHistory = useSelector(state => state.mlTask.mlTaskHistory);
    useEffect(() => {
        console.log(mlTaskHistory);
    }, [mlTaskHistory]);
    // ------------------------------------------------------------------------------
    const showQANewChat = useSelector((state) => state.flags.showQANewChat);
    useEffect(() => {
        if (showQANewChat) {
            setActiveMenu('qa');
            setIsQuestionResponseCollapsed(true);
            setIsTaskOpen(false);
            setIsDocProcOpen(false);
        }
    }, [showQANewChat]);
    // ------------------------------------------------------------------------------
    const showDocProProjects = useSelector((state) => state.flags.showDocProProjects);
    useEffect(() => {
        if (showDocProProjects) {
            setActiveMenu('docpro');
            setIsQuestionResponseCollapsed(false);
            setIsTaskOpen(false);
            setIsDocProcOpen(true);
        }
    }, [showDocProProjects]);
    // ------------------------------------------------------------------------------
    const isSidebarCollapsed = useSelector((state) => state.flags.isSidebarCollapsed)
    useEffect(() => {
        setIsCollapsed(isSidebarCollapsed);
    }, [isSidebarCollapsed]);
    // ------------------------------------------------------------------------------

    const toggleHistory = () => {
        setIsHistoryOpen(prevState => !prevState);
    };

    const toggleSidebarVisibility = () => {
        const newState = !isCollapsed;
        setIsCollapsed(newState);

        dispatch(setFlag('isSidebarCollapsed', newState));

        if (newState) {
            setIsSettingsOpen(false);
        }
    };


    const toggleShowMore = () => {
        setShowAllHistory(!showAllHistory);
        setVisibleItems(showAllHistory ? 2 : historyItemsCount);
    };

    const updateHistoryItemsCount = (count) => {
        setHistoryItemsCount(count);
    };

    const updateVisibleItems = (count) => {
        setVisibleItems(count);
    };

    const toggleDocIntelligence = () => {
        setIsCollapsed(true);
        dispatch(setFlag('isSidebarCollapsed', true));
        updateExclusiveFlags(dispatch, {
            showDocumentIntelligence: true,
        });
    };

    const toggleShowNewMLTask = () => {
        updateExclusiveFlags(dispatch, {
            showMLTask: true,
        });
    };

    const toggleShowSettingDetails = () => {
        updateExclusiveFlags(dispatch, {
            showSettingDetails: true,
        });
    };

    const togglePerformingOCR = () => {
        updateExclusiveFlags(dispatch, {
            showPerformingOCR: true,
        });
    }

    const toggleTranslation = () => {
        updateExclusiveFlags(dispatch, {
            showTranslation: true,
        });
    }

    const toggleIndexing = () => {
        updateExclusiveFlags(dispatch, {
            showIndexing: true,
        });
    }

    const toggleChatEngine = () => {
        dispatch(setUserQuestion(''));
        dispatch(setClearQuestionAndResponse(''));
        dispatch(setActiveHistoryTab(null));
        dispatch(setFlag('userRaisedQuestionFlag', false));

        updateExclusiveFlags(dispatch, {
            showChatEngine: true,
        });
    }

    const toggleMLTaskHistory = () => {
        setIsMLTaskHistoryOpen(!isMLTaskHistoryOpen);
    };

    const handleSelectMLTask = (task) => {
        dispatch(updateExclusiveFlags(dispatch, {
            showMLTask: true,
        }));
        // Dispatch an action to load the selected task into RightContent.js
        dispatch(loadTaskIntoRightContent(task));
    };

    const handleMenuClick = (menuName) => {
        console.log('Hai ', menuName);

        setActiveMenu(menuName);
        setActiveItem(null); // Added to reset active item when clicking elsewhere
        setActiveDocProItem(null)

        // Reset states for other sections
        switch (menuName) {
            case 'home':
                setIsQuestionResponseCollapsed(false);
                setIsTaskOpen(false);
                setIsDocProcOpen(false);
                dispatch(setUserQuestion(''));
                dispatch(setClearQuestionAndResponse(''));
                dispatch(setActiveHistoryTab(null));
                dispatch(setFlag('userRaisedQuestionFlag', false));
                updateExclusiveFlags(dispatch, {
                    showUserCustomizeMessage: true,
                });
                break;

            case 'qa':
                setIsQuestionResponseCollapsed(!isQuestionResponseCollapsed);
                setIsTaskOpen(false);
                setIsDocProcOpen(false);
                break;

            case 'task':
                setIsQuestionResponseCollapsed(false);
                setIsTaskOpen(!isTaskOpen);
                setIsDocProcOpen(false);
                break;

            case 'docpro':
                setIsQuestionResponseCollapsed(false);
                setIsTaskOpen(false);
                setIsDocProcOpen(!isDocProcOpen);
                break;

            case 'docintelligence':
                setIsQuestionResponseCollapsed(false);
                setIsTaskOpen(false);
                setIsDocProcOpen(false);
                dispatch(setUserQuestion(''));
                dispatch(setClearQuestionAndResponse(''));
                dispatch(setActiveHistoryTab(null));
                dispatch(setFlag('userRaisedQuestionFlag', false));

                setIsCollapsed(true);
                dispatch(setFlag('isSidebarCollapsed', true));

                updateExclusiveFlags(dispatch, {
                    showDocumentIntelligence: true,
                });
                break;

            case 'qa_new_chat':
                setActiveMenu('qa');
                updateExclusiveFlags(dispatch, {
                    showQANewChat: true,
                });
                dispatch(setUserQuestion(''));
                dispatch(setClearQuestionAndResponse(''));
                dispatch(setActiveHistoryTab(null));
                break;

            case 'docpro_modal':
                setActiveMenu('docpro');
                updateExclusiveFlags(dispatch, {
                    showDocProProjects: true,
                });
                dispatch(setUserQuestion(''));
                dispatch(setClearQuestionAndResponse(''));
                dispatch(setActiveHistoryTab(null));
                break;

            case 'settings':
                setActiveMenu('settings');
                setIsQuestionResponseCollapsed(false);
                setIsTaskOpen(false);
                setIsDocProcOpen(false);
                updateExclusiveFlags(dispatch, {
                    showSettingDetails: true,
                    showQANewChat: false,
                });
                dispatch(setActiveHistoryTab(null));
                break;
        }
    };


    return (
        <div
            className={`vertical-menu ${isCollapsed ? 'collapsed' : ''} ps-2 pe-2`}
            style={{
                background: '#12262d',
                overflow: 'hidden',
                width: isCollapsed ? '72px' : '240px',
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
                paddingLeft: isCollapsed ? '0px' : '16px',
                paddingRight: isCollapsed ? '0px' : '16px',
                paddingTop: '0px',
                paddingBottom: '0px',
            }}
        >
            <div className="pt-1 sidebar-menu" style={{ flexGrow: 1 }}>
                <div style={{ minHeight: 'calc(100vh - 200px)' }}>
                    <div className="pt-1 sidebar-menu">
                        <div
                            className="ver-col"
                            onClick={toggleSidebarVisibility}
                            id="vertical-menu-btn"
                            style={{
                                display: isCollapsed ? 'none' : 'block',
                                marginLeft: isCollapsed ? '-170px' : '1px',
                                marginTop: isCollapsed ? '13px' : '13px',
                                boxShadow: 'rgb(52, 52, 52) 0px 0px 2px 1px'
                            }}
                        >
                            <i className="fas fa-chevron-left font-size-10 text-white" style={{ marginLeft: '11px', marginTop: '10px' }}></i>
                        </div>

                        {/* Home */}
                        <ul className="list-unstyled tabs mb-1">
                            <li
                                className={`pt-2 ques p-2 pt-1 pb-1 ${activeMenu === 'home' ? 'active' : ''}`}
                                style={{ display: isCollapsed ? 'flex' : 'block', justifyContent: isCollapsed ? 'center' : '' }}
                            >
                                <a
                                    href="#"
                                    role="button"
                                    onClick={() => handleMenuClick('home')}
                                    style={{
                                        paddingLeft: isCollapsed ? '0px' : '14px',
                                        paddingRight: isCollapsed ? '0px' : '14px',
                                        textAlign: isCollapsed ? 'center' : "left",
                                        width: isCollapsed ? '40px' : '',
                                        height: isCollapsed ? '40px' : ''
                                    }}
                                    data-bs-toggle={isCollapsed ? "tooltip" : undefined}
                                    data-bs-placement="right"
                                >
                                    {/* Show Tooltip only when collapsed */}
                                    {isCollapsed && (
                                        <CustomTooltip title="Home" placement="right" arrow>
                                            <i
                                                className={`ri-home-5-line ${isCollapsed ? 'pe-0' : 'pe-2'} ${activeMenu === 'home' ? 'active' : ''}`}
                                                onClick={toggleSidebarVisibility}
                                                style={{
                                                    color: '#A8A8A8',
                                                    fontSize: "20px"
                                                }}
                                            ></i>
                                        </CustomTooltip>
                                    )}

                                    {/* Icon always visible */}
                                    {!isCollapsed && (
                                        <i
                                            className={`ri-home-5-line ${activeMenu === 'home' ? 'active' : ''}`}
                                            onClick={toggleSidebarVisibility}
                                            style={{
                                                color: '#A8A8A8',
                                                fontSize: "20px"
                                            }}
                                        ></i>
                                    )}

                                    {/* Text only visible when not collapsed */}
                                    <span style={{ display: isCollapsed ? 'none' : 'inline', fontSize: "12px", padding: "0px 0px 0px 4px" }}>Home</span>
                                </a>
                            </li>
                        </ul>

                        {(renderMode === 'Q&A' || renderMode === 'Both') && (
                        <>
                        {/* Q&A Menu */}
                        <ul className="list-unstyled tabs mb-1" id="side-menu">
                            <li
                                className={`pt-1 ques p-2 pt-1 pb-1 ${activeMenu === 'qa' ? 'active' : ''}`}
                                style={{ display: isCollapsed ? 'flex' : 'block', justifyContent: isCollapsed ? 'center' : '' }}
                            >
                                <a
                                    href="#"
                                    role="button"
                                    className={`${isCollapsed ? 'hov1' : 'has-arrow hov'} waves-effect`}
                                    style={{
                                        paddingLeft: isCollapsed ? '0px' : '14px',
                                        paddingRight: isCollapsed ? '0px' : '14px',
                                        textAlign: isCollapsed ? 'center' : "left",
                                        width: isCollapsed ? '40px' : '',
                                        height: isCollapsed ? '40px' : ''
                                    }}
                                    onClick={(e) => { e.preventDefault(); handleMenuClick('qa'); }}
                                >
                                    
                                    {isCollapsed && (
                                        <CustomTooltip title="Q&A" placement="right" arrow>
                                            <i
                                                className={`ri-chat-1-line ${isCollapsed ? 'pe-0' : 'pe-2'} ${activeMenu === 'qa' ? 'active' : ''}`}
                                                onClick={toggleSidebarVisibility}
                                                style={{
                                                    fontSize: "20px"
                                                }}
                                            ></i>
                                        </CustomTooltip>
                                    )}

                                   
                                    {!isCollapsed && (
                                        <i
                                            className={`ri-chat-1-line ${activeMenu === 'qa' ? 'active' : ''}`}
                                            onClick={toggleSidebarVisibility}
                                            style={{
                                                fontSize: "20px"
                                            }}
                                        ></i>
                                    )}

                                   
                                    <span style={{ display: isCollapsed ? 'none' : 'inline', fontSize: "12px", padding: "0px 0px 0px 4px" }}>Q & A</span>
                                </a>
                            </li>

                            <ul className="sub-menu ps-0 pe-0 pt-0 question" style={{ display: isQuestionResponseCollapsed ? 'block' : 'none' }} aria-expanded="false">
                                <li style={{ display: isCollapsed ? 'none' : 'block', justifyContent: isCollapsed ? 'center' : '', padding: "8px 8px 4px" }}>
                                    <a
                                        href="#"
                                        className={`${isCollapsed ? 'hov1' : 'hov'} waves-effect`}
                                        onClick={(e) => { e.preventDefault(); handleMenuClick('qa_new_chat'); setActiveItem('new_chat'); }}
                                        style={{
                                            paddingLeft: isCollapsed ? '0px' : '0px',
                                            paddingRight: isCollapsed ? '0px' : '0px',
                                            textAlign: isCollapsed ? 'center' : "left",
                                            width: isCollapsed ? '40px' : '',
                                            height: isCollapsed ? '40px' : '',
                                            
                                            padding: "8px 14px",
                                            color: activeItem === 'new_chat' && activeDocProItem === null ? '#ffffff' : '#8590a5' 
                                        }}
                                    >
                                        
                                        <span style={{ display: isCollapsed ? 'none' : 'inline', padding: "0px 0px 0px 24px" }}>New Chat</span>
                                    </a>
                                </li>

                                <li style={{ display: isCollapsed ? 'none' : 'block', justifyContent: isCollapsed ? 'center' : '', padding: "8px 8px 4px" }}>
                                    <a
                                        href="#"
                                        onClick={(e) => { e.preventDefault(); toggleHistory(); setActiveItem('chat_history'); }}
                                        className={`${isCollapsed ? 'hov1' : 'has-arrow hov'} waves-effect`}
                                        style={{
                                            paddingLeft: isCollapsed ? '0px' : '0px',
                                            textAlign: isCollapsed ? 'center' : "left",
                                            width: isCollapsed ? '40px' : '',
                                            height: isCollapsed ? '40px' : '',
                                            padding: "8px 14px",

                                            color: activeItem === 'chat_history' && activeDocProItem === null ? '#ffffff' : '#8590a5' 
                                            
                                        }}
                                    >
                                       
                                        <span style={{ display: isCollapsed ? 'none' : 'inline', padding: "0px 0px 0px 24px" }}>Chat History</span>
                                    </a>

                                    {isHistoryOpen && (
                                        <ul className="sub-menu ps-0 pe-0 pt-0 question" style={{ maxHeight: '350px', position: 'relative', left: '13px' }}>
                                            <HistoryList
                                                showAll={showAllHistory}
                                                updateItemsCount={updateHistoryItemsCount}
                                                updateVisibleItems={updateVisibleItems}
                                                visibleItems={visibleItems}
                                            />
                                            {!isCollapsed && historyItemsCount > 5 && (
                                                <li className="ps-0 pe-0 pt-2" style={{listStyle:"none"}}>
                                                    <a
                                                        href="#"
                                                        onClick={toggleShowMore}
                                                        className="hov waves-effect"
                                                        style={{ paddingLeft: '17px', paddingRight: '14px' }}
                                                    >
                                                        <i
                                                            className="fas fa-chevron-up pe-2"
                                                            style={{ transform: showAllHistory ? 'rotate(0deg)' : 'rotate(180deg)', position: 'relative', right: showAllHistory ? '-1px' : '10px', bottom: showAllHistory ? '-1px' : '2px', fontSize: '12px' }}></i>
                                                        <span style={{ display: isCollapsed ? 'none' : 'inline' }}>
                                                            {showAllHistory ? 'Show Less' : 'Show More'}
                                                        </span>
                                                    </a>
                                                </li>
                                            )}
                                        </ul>
                                    )}
                                </li>
                            </ul>
                        </ul>
                        </>
                        )}

                        {(renderMode === 'DocPro' || renderMode === 'Both') && (
                        /*{ Task Menu }*/
                        /*{/* <ul className="list-unstyled tabs mb-1" id="side-menu">
                            <li className={`pt-1 ques p-2 pt-1 pb-1 ${activeMenu === 'task' ? 'active' : ''}`} style={{ display: isCollapsed ? 'flex' : 'block', justifyContent: isCollapsed ? 'center' : '' }}>
                                <a
                                    href="#"
                                    role="button"
                                    className={`${isCollapsed ? 'hov1' : 'has-arrow hov'} waves-effect`}
                                    style={{ paddingLeft: isCollapsed ? '0px' : '14px', paddingRight: isCollapsed ? '0px' : '14px', textAlign: isCollapsed ? 'center' : "left", width: isCollapsed ? '40px' : '', height: isCollapsed ? '40px' : '' }}
                                    onClick={(e) => { e.preventDefault(); handleMenuClick('task'); }}
                                >
                                    <i className={`ri-bar-chart-box-line  ${isCollapsed ? 'pe-0' : 'pe-2'}`} onClick={toggleSidebarVisibility}></i>
                                    <span style={{ display: isCollapsed ? 'none' : 'inline' }}>Task</span>
                                </a>
                            </li>
                            <ul className="sub-menu ps-0 pe-0 pt-0 question" style={{ display: isTaskOpen ? 'block' : 'none' }} aria-expanded="false">
                                <li style={{ display: isCollapsed ? 'none' : 'block', justifyContent: isCollapsed ? 'center' : '' }}>
                                    <a
                                        href="#"
                                        className={`${isCollapsed ? 'hov1' : 'hov'}  waves-effect`}
                                        onClick={(e) => { e.preventDefault(); toggleShowNewMLTask(); }}
                                        style={{ paddingLeft: isCollapsed ? '0px' : '28px', paddingRight: isCollapsed ? '0px' : '14px', textAlign: isCollapsed ? 'center' : "left", width: isCollapsed ? '40px' : '', height: isCollapsed ? '40px' : '' }}
                                        
                                    >
                                        {/* <i className="mdi mdi-plus-circle-outline pe-2"></i> }*/
                        /* {<span>New Task</span>
                                    </a>
                                </li>
                                <li style={{ display: isCollapsed ? 'none' : 'block', justifyContent: isCollapsed ? 'center' : '' }}>
                                    <a
                                        href="#"
                                        onClick={(e) => { e.preventDefault(); toggleMLTaskHistory(); }}
                                        className={`${isCollapsed ? 'hov1' : 'has-arrow hov'}  waves-effect`}
                                        style={{ paddingLeft: isCollapsed ? '0px' : '28px', paddingRight: isCollapsed ? '0px' : '14px', textAlign: isCollapsed ? 'center' : "left", width: isCollapsed ? '40px' : '', height: isCollapsed ? '40px' : '' }}
                                    > }*/
                        /* {<i className="mdi mdi-history pe-2"></i> }*/
                        /*{ <span style={{ display: isCollapsed ? 'none' : 'inline' }}>Task History</span>
                                    </a> }*/

                        /*{ {isMLTaskHistoryOpen && (
                                        <ul> }*/
                        /*{ {mlTaskHistory.map((task, index) => (
                                                <li key={index}>
                                                    <a href="#" onClick={() => handleSelectMLTask(task)}>
                                                        {task.question}
                                                    </a>
                                                </li>
                                            ))} }*/

                        /*{ {Array.isArray(mlTaskHistory) && mlTaskHistory.length > 0 ? (
                                                mlTaskHistory.map((task, index) => (
                                                    <li key={index}>
                                                        <a href={`#task-${index}`} onClick={() => handleSelectMLTask(task)}>{task.question.substring(0, 30)}...</a>
                                                    </li>
                                                ))
                                            ) : (
                                                <li>No task history available</li>
                                            )}
                                        </ul>
                                    )}
                                </li>
                            </ul>
                        </ul> }*/

                        /*{ DocPro Menu }*/

                        <>
                        <ul className="list-unstyled tabs mb-1" id="side-menu">
                            <li className={`pt-1 ques p-2 pt-1 pb-1 ${activeMenu === 'docpro' ? 'active' : ''}`} style={{ display: isCollapsed ? 'flex' : 'block', justifyContent: isCollapsed ? 'center' : '' }}>
                                <a
                                    href="#"
                                    role="button"
                                    className={`${isCollapsed ? 'hov1' : 'has-arrow hov'} waves-effect`}
                                    style={{
                                        paddingLeft: isCollapsed ? '0px' : '14px',
                                        paddingRight: isCollapsed ? '0px' : '14px',
                                        textAlign: isCollapsed ? 'center' : "left",
                                        width: isCollapsed ? '40px' : '',
                                        height: isCollapsed ? '40px' : ''
                                    }}
                                    onClick={(e) => { e.preventDefault(); handleMenuClick('docpro'); }}
                                >
                                    {/* Tooltip for the icon */}
                                    <CustomTooltip title="DocPro" placement="right" arrow>
                                        <i
                                            className={`ri-file-list-2-line ${isCollapsed ? 'pe-0' : 'pe-2'} ${activeMenu === 'docpro' ? 'active' : ''}`}
                                            onClick={toggleSidebarVisibility}
                                            style={{
                                                fontSize: "20px"
                                            }}
                                        ></i>
                                    </CustomTooltip>

                                    {/* Text that is only visible when not collapsed */}
                                    <span style={{ display: isCollapsed ? 'none' : 'inline', fontSize: "12px" }}>DocPro</span>
                                </a>

                                {/* Sub-menu */}
                                <ul className="sub-menu ps-0 pe-0 pt-0 question" style={{ display: isDocProcOpen ? 'block' : 'none' }} aria-expanded="false">
                                    <li style={{ display: isCollapsed ? 'none' : 'block', justifyContent: isCollapsed ? 'center' : '', padding: "8px 8px 4px" }}>
                                        <a
                                            href="#"
                                            className={`${isCollapsed ? 'hov1' : 'hov'} waves-effect new-class-name`}
                                            onClick={(e) => { e.preventDefault(); handleMenuClick('docpro_modal'); setActiveDocProItem('my_projects'); }}
                                            style={{
                                                paddingLeft: isCollapsed ? '0px' : '14px',
                                                paddingRight: isCollapsed ? '0px' : '14px',
                                                textAlign: isCollapsed ? 'center' : "left",
                                                width: isCollapsed ? '40px' : '',
                                                height: isCollapsed ? '40px' : '',
                                                padding: "8px 14px ",
                                                color: activeDocProItem === 'my_projects' ? '#ffffff' : '#8590a5'

                                            }}
                                        >
                                            <span style={{ display: isCollapsed ? 'none' : 'inline', padding: "0px 0px 0px 24px" }}> My Projects</span>
                                        </a>
                                    </li>
                                    <li style={{ display: isCollapsed ? 'none' : 'block', justifyContent: isCollapsed ? 'center' : '', padding: "8px 8px 4px" }}>
                                        <a
                                            href="#"
                                            onClick={(e) => { e.preventDefault(); toggleChatEngine(); setActiveDocProItem('qa'); }}
                                            className={`${isCollapsed ? 'hov1' : 'hov'} waves-effect`}
                                            style={{
                                                paddingLeft: isCollapsed ? '0px' : '14px',
                                                paddingRight: isCollapsed ? '0px' : '14px',
                                                textAlign: isCollapsed ? 'center' : "left",
                                                width: isCollapsed ? '40px' : '',
                                                height: isCollapsed ? '40px' : '',
                                                padding: "8px 14px",
                                                color: activeDocProItem === 'qa' ? '#ffffff' : '#8590a5'
                                            }}
                                        >
                                            <span style={{ display: isCollapsed ? 'none' : 'inline', padding: "0px 0px 0px 24px" }}>Q & A</span>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        </>                 
                        )}
                        {/* Document Intelligence */}
                        {/* <ul className="list-unstyled tabs mb-1" id="side-menu">
                            <li
                                className={`pt-1 ques p-2 pt-1 pb-1 ${activeMenu === 'docintelligence' ? 'active' : ''}`}
                                style={{ display: isCollapsed ? 'flex' : 'block', justifyContent: isCollapsed ? 'center' : '' }}
                            >
                                <a
                                    href="#"
                                    className={`${isCollapsed ? 'hov1' : 'hov'}  waves-effect`}
                                    style={{
                                        paddingLeft: isCollapsed ? '0px' : '14px',
                                        paddingRight: isCollapsed ? '0px' : '14px',
                                        textAlign: isCollapsed ? 'center' : 'left',
                                        display: isCollapsed ? 'inline' : 'flex',
                                        alignItems: "center",
                                        width: isCollapsed ? '40px' : '',
                                        height: isCollapsed ? '40px' : ''

                                    }}
                                    onClick={(e) => { e.preventDefault(); handleMenuClick('docintelligence'); }}
                                >
                                    {isCollapsed ? (
                                        <CustomTooltip title="VisionPro" placement="right" arrow>  
                                            <i
                                                className={`mdi mdi-brain ${isCollapsed ? 'pe-0' : 'pe-2'} texttip ${activeMenu === 'visionpro' ? 'active' : ''}`}
                                                style={{
                                                    color: '#A8A8A8',
                                                    fontSize: '20px'
                                                }}
                                            ></i>
                                        </CustomTooltip>
                                    ) : (
                                        <i
                                            className={`mdi mdi-brain ${isCollapsed ? 'pe-0' : 'pe-2'} texttip ${activeMenu === 'visionpro' ? 'active' : ''}`}
                                            style={{
                                                color: '#A8A8A8',
                                                fontSize: '20px'
                                            }}
                                        ></i>
                                    )}

                                   
                                    <span style={{ display: isCollapsed ? 'none' : 'inline', fontSize: '12px' }}>VisionPro</span>
                                </a>
                            </li>
                        </ul> */}


                        {/* <ul className="list-unstyled tabs mb-1" id="side-menu">
                            <li className={`pt-1 ques p-2 pt-1 pb-1 ${activeMenu === 'docpro' ? 'active' : ''}`} style={{ display: isCollapsed ? 'flex' : 'block', justifyContent: isCollapsed ? 'center' : '' }}>
                                <a
                                    href="#"
                                    role="button"
                                    className={`${isCollapsed ? 'hov1' : 'has-arrow hov'}  waves-effect`}
                                    style={{ paddingLeft: isCollapsed ? '0px' : '14px', paddingRight: isCollapsed ? '0px' : '14px', textAlign: isCollapsed ? 'center' : "left", width: isCollapsed ? '40px' : '', height: isCollapsed ? '40px' : '' }}
                                    onClick={(e) => { e.preventDefault(); toggleDocProc(); handleMenuClick('docpro'); }}
                                >
                                    <i className={`ri-file-list-2-line  ${isCollapsed ? 'pe-0' : 'pe-2'}`} onClick={toggleSidebarVisibility}></i>
                                    <span style={{ display: isCollapsed ? 'none' : 'inline' }}>DocPro</span>
                                </a>
                            </li>
                            <ul className="sub-menu ps-0 pe-0 pt-0 question" style={{ display: isDocProcOpen ? 'block' : 'none' }} aria-expanded="false">
                                <li>
                                    <a href="#" className="hov waves-effect" style={{ paddingLeft: '28px' }} onClick={(e) => { e.preventDefault(); toggleUploadingDocument(); }}>
                                        <i className="mdi mdi-upload pe-2"></i>
                                        <span>Upload</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="hov waves-effect" style={{ paddingLeft: '28px' }} onClick={(e) => { e.preventDefault(); togglePerformingOCR(); }}>
                                        <i className="mdi mdi-text-recognition pe-2"></i>
                                        <span>Understand</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="hov waves-effect" style={{ paddingLeft: '28px' }} onClick={(e) => { e.preventDefault(); toggleTranslation(); }}>
                                        <i className="mdi mdi-translate pe-2"></i>
                                        <span>Translate</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="hov waves-effect" style={{ paddingLeft: '28px' }} onClick={(e) => { e.preventDefault(); toggleIndexing(); }}>
                                        <i className="mdi mdi-database-search pe-2"></i>
                                        <span>Index</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="hov waves-effect" style={{ paddingLeft: '28px' }} onClick={(e) => { e.preventDefault(); toggleChatEngine(); }}>
                                        <i className="mdi mdi-chat-processing pe-2"></i>
                                        <span>Q&A</span>
                                    </a>
                                </li>
                            </ul>
                        </ul> */}
                    </div>
                </div>


                {/* Bottom Menu */}
                <div className="bottom-menu" style={{ textAlign: "center", marginLeft: isCollapsed ? '0' : '-6px', marginTop: "-15px" }}>
                    <ul className="list-unstyled tabs mb-0" style={{ gap: '8px', display: "flex", flexDirection: "column" }}>
                        <li

                            className={`side-menus menu pt-1 pb-0 ps-2 pe-2 ${activeMenu === 'settings' ? 'active' : ''}`}
                            style={{ display: 'flex', justifyContent: isCollapsed ? 'center' : 'flex-start', alignItems: 'center' }}
                        >
                            <a
                                href="#"
                                className="menu-icons pt-1 pb-1 ps-3 pe-3 w-100 waves-effect"
                                onClick={(e) => {
                                    e.preventDefault();
                                    toggleShowSettingDetails();
                                    handleMenuClick('settings');
                                }}
                                style={{ padding: '8px 16px', display: 'flex', justifyContent: isCollapsed ? 'center' : 'flex-start', alignItems: "center" }}
                            >
                                {isCollapsed && (
                                    <CustomTooltip title="Settings" placement="right" arrow>
                                        <i
                                            className={`ri-settings-4-line font-size-20 m-0 bottom-sidemenu-icon ${activeMenu === 'settings' ? 'active' : ''}`}
                                        ></i>

                                    </CustomTooltip>
                                )}

                                {/* Icon always visible */}
                                {!isCollapsed && (
                                    <i
                                        className={`ri-settings-4-line font-size-20 m-0 bottom-sidemenu-icon ${activeMenu === 'settings' ? 'active' : ''}`}
                                    ></i>

                                )}

                                {/* Only show text if not collapsed */}
                                <span
                                    className="ps-2"
                                    style={{ display: isCollapsed ? 'none' : 'inline-block', fontSize: '12px',margin:"0px 0px 6px 0px" }}
                                >
                                    Settings
                                </span>
                            </a>
                        </li>

                        <li
                            className="side-menus menu pt-1 pb-0 ps-2 pe-2"
                            style={{ display: 'flex', justifyContent: isCollapsed ? 'center' : 'flex-start', alignItems: 'center' }}
                        >
                            <a
                                href="#"
                                className="menu-icons pt-1 pb-1 ps-3 pe-3 w-100"

                                style={{
                                    padding: '8px 16px',
                                    display: 'flex',
                                    justifyContent: isCollapsed ? 'center' : 'flex-start',
                                    alignItems: 'center'
                                }}
                            >
                                {isCollapsed && (
                                    <CustomTooltip title="Models v1.02.1 - Running" placement="right" arrow>
                                        <i className="mdi mdi-alert-circle-outline font-size-20 m-0 bottom-sidemenu-icon"></i>
                                    </CustomTooltip>
                                )}

                                {/* Icon always visible */}
                                {!isCollapsed && (
                                    <i className="mdi mdi-alert-circle-outline font-size-20 m-0 bottom-sidemenu-icon"></i>
                                )}
                                <div
                                    className="pt-1 content"
                                    style={{
                                        display: isCollapsed ? 'none' : 'inline-block',
                                        fontSize: '12px',
                                        paddingLeft: isCollapsed ? '0' : '0px'
                                    }}
                                >
                                    <p className="ps-2 mb-0 font-size-12">
                                        Models <span className="font-size-10 ps-2">v <span>1.02.1</span></span>
                                    </p>
                                    <div className="d-flex align-items-center ps-2">
                                        <div className="active-circle me-1"></div>
                                        <p className="mb-0 text-warning font-size-10">Running</p>
                                    </div>
                                </div>
                            </a>
                        </li>

                        <li
                            className="side-menus menu pt-0 pb-0 ps-2 pe-2"
                            style={{ display: 'flex', justifyContent: isCollapsed ? 'center' : 'flex-start', alignItems: 'center' }}
                        >
                            <a
                                href="#"
                                className="menu-icons pt-1 pb-1 ps-3 pe-3 w-100"

                                style={{ display: 'flex', justifyContent: isCollapsed ? 'center' : 'flex-start', alignItems: 'center', marginTop: "6px" }}
                            >
                                {isCollapsed && (
                                    <CustomTooltip title="Wizard Version" placement="right" arrow>
                                        <i className={`mdi mdi-flag-outline font-size-20 m-0 bottom-sidemenu-icon  ${activeMenu === 'settings' ? 'active' : ''}`}></i>
                                    </CustomTooltip>
                                )}

                                {/* Icon always visible */}
                                {!isCollapsed && (
                                    <i className={`mdi mdi-flag-outline font-size-20 m-0 bottom-sidemenu-icon  ${activeMenu === 'settings' ? 'active' : ''}`}></i>
                                )}

                                {/* Only show text if not collapsed */}
                                <div
                                    className="content"
                                    style={{
                                        display: isCollapsed ? 'none' : 'inline-block',
                                        fontSize: '12px',
                                        paddingLeft: isCollapsed ? '0' : '0px', // Remove padding when collapsed
                                    }}
                                >
                                    <p className="ps-2 mb-0 font-size-12" style={{marginTop:"-3px"}}>
                                        Wizard <span className="font-size-10 ps-2">v1.0</span>
                                    </p>
                                </div>
                            </a>
                        </li>

                    </ul>
                </div>


            </div>
        </div>
    );
}

export default LeftSidebar;
