import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateExclusiveFlags } from '../utils/reduxUtils';
import Breadcrumb from '../utils/Breadcrumb';
import docpro_img from "../assets/images/docpro.gif";
import qa_img from "../assets/images/q&a.gif";
import tas_img from "../assets/images/task.gif";
import vis_img from "../assets/images/visionpro.gif";


import {
    setFlag,
    setUserQuestion,
    setClearQuestionAndResponse,
    setActiveHistoryTab
} from '../store/actions';

function UserCustomizeMessage() {
    // const navigate = useNavigate();
    const dispatch = useDispatch();

    const renderMode = process.env.REACT_APP_RENDER;

    const activeUser = useSelector((state) => state.activeUser);
    const activeUserName = activeUser.name;
    // console.log("activeUserName: ", activeUserName)

    // const messages = useSelector((state) => state.updateUserCustomizeMessage);
    // console.log("messages: ", messages)

    const handleQAClick = () => {
        updateExclusiveFlags(dispatch, {
            showQANewChat: true,
        });
        dispatch(setUserQuestion(''));
        dispatch(setClearQuestionAndResponse(''));
        dispatch(setActiveHistoryTab(null));
    };

    const handleDocProClick = () => {
        updateExclusiveFlags(dispatch, {
            showDocProProjects: true,
        });
    };

    const handleDocumentIntelligence = () => {
        updateExclusiveFlags(dispatch, {
            showDocumentIntelligence: true,
        });

        dispatch(setFlag('isSidebarCollapsed', true));
    };

    return (
        <div id="maincontent" className="home" >
            <div className="user-customize-message"  >
                <Breadcrumb title="Home" homeLink="#" currentPage="Dasboard" />

                <div className="pt-66px qabefore" style={{ paddingLeft: '80px' }}>
                    <h2 className="fw-bold pt-0 mb-0">Hello {activeUserName}</h2>
                    <h2 className="text-secondary fw-medium mb-3">How can I help you?</h2>
                    <h6 className="fw-medium mb-0">Ready to explore your AI assistant's capabilities?</h6>
                    <p>Unlock answers, process documents, and track tasks effortlessly.</p>

                    <div className="row mb-3">
                        {[
                            {
                                title: 'Q & A',
                                description: 'Delivers instant feedback with the fastest response times, fact-based answers and user queries.',
                                imageSrc: qa_img,
                                onClick: handleQAClick,
                                renderModes: ['Both', 'Q&A'],
                            },
                            // {
                            //     title: 'Task',
                            //     description: 'Handles tasks with longer processing times, where the chatbot will guide users through each step.',
                            //     imageSrc: tas_img,
                            //     onClick: handleQAClick,
                            // },
                            {
                                title: 'DocPro',
                                description: 'Manages document retrieval with real-time responses.',
                                imageSrc: docpro_img,
                                onClick: handleDocProClick,
                                renderModes: ['Both', 'DocPro'],
                            },
                            // {
                            //     title: 'Vision Pro',
                            //     description: 'A future module for visual interactions or advanced AI functionalities.',
                            //     imageSrc: vis_img,
                            //     onClick: handleDocumentIntelligence,
                            // },
                        ]
                        .filter(item => 
                            item.renderModes.includes(renderMode) 
                        )
                        .map((item, index) => (

                            // if (renderMode === 'Both' || item.renderModes.includes(renderMode)) {

                            <div className="col-3" key={index}>
                                <div className="card min-h-100p mb-0 cursor-pointer" onClick={item.onClick} style={{borderRadius:"8px"}}>
                                    <div className="card-body  d-grid align-content-between">
                                        <div className={`d-flex justify-content-start card-index-${index + 1}`}>
                                            <div className="w-50 text-start">
                                                <h6 className="fw-medium">{item.title}<i className="mdi mdi-send text-dark ps-2"></i></h6>
                                                <p className="font-size-12 mb-1">{item.description}</p>
                                            </div>
                                            <div className="w-50">
                                                <img src={item.imageSrc} className="w-100" alt={item.title} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserCustomizeMessage;
