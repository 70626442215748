import React, { useState, useEffect, useMemo, useRef } from 'react';
import IconAnswer from '../../utils/IconAnswer';
import Loader from '../../utils/Loader';
import RenderParagraphs from './RenderParagraphs';
import GeoGraph from '../GeoGraph';
import Plot from 'react-plotly.js';
import { createRoot } from 'react-dom/client';
import { useDispatch, useSelector } from 'react-redux';

import { setFlag } from '../../store/actions';

import ReactECharts from 'echarts-for-react';

function RenderingTheResponse(props) {
    console.log("RenderingTheResponse")
    const dispatch = useDispatch();
    
    const { response, chartCode, sqlQuery, queryResult, source, desc } = props;
    const [chartError, setChartError] = useState(null);
    const chartContainerRef = useRef(null);
    const rootRef = useRef(null);
    const contentRef = useRef(null);
    const showTraceFlag = useSelector((state) => state.flags.showTrace);
    const isSidebarCollapsed = useSelector((state) => state.flags.isSidebarCollapsed);
    const [overlay, setOverlay] = useState(false);
    const isGeoChart = useMemo(() => {
        try {
            const { type } = JSON.parse(chartCode) || {};
            return type && type.toLowerCase() === 'geo';
        } catch {
            return false;
        }
    }, [chartCode]);

    const extractJsonData = (text) => {
        try { 
            
            // Helper function to clean JSON strings
        const cleanJsonString = (rawJson) => {
            return rawJson
                .replace(/\\n/g, '')          // Remove escaped newlines
                .replace(/\\"/g, '"')         // Replace escaped quotes
                .replace(/\\'/g, "'")         // Replace escaped single quotes
                .trim();                      // Trim any leading or trailing spaces
        };

    
            // Helper function to validate JSON
            const isValidJson = (jsonString) => {
                try {     
                    // Clean up any escaped quotes or newline characters
                const cleanedJsonString = jsonString
                .replace(/\\n/g, '')          // Remove escaped newlines
                .replace(/\\"/g, '"')         // Replace escaped quotes with actual quotes
                .replace(/\\'/g, "'")         // Replace escaped single quotes (if necessary)
                .replace(/^n{/, '{')
                .trim();                      // Trim any leading or trailing spaces

                let cleanedText = cleanedJsonString.trim(); 
                // Step 3: Parse the cleaned JSON
                const parsedJson = JSON.parse(cleanedText);
                return true;          
                    
                } catch {
                    return false;
                }
            };
    
            // Step 1: Validate the text as JSON directly
            if (isValidJson(text)) {
                const cleanedJsonString = text
                .replace(/\\n/g, '')          // Remove escaped newlines
                .replace(/\\"/g, '"')         // Replace escaped quotes with actual quotes
                .replace(/\\'/g, "'")         // Replace escaped single quotes (if necessary)
                .replace(/^n{/, '{')
                .trim();         
                return JSON.parse(cleanedJsonString);
            }
    
            // Step 2: Locate JSON block surrounded by ```json ... ```
            const startIndex = text.indexOf('```json');
            const endIndex = text.indexOf('```', startIndex + 7);
    
            if (startIndex !== -1 && endIndex !== -1) {
                const jsonString = text.substring(startIndex + 7, endIndex).trim();
                const cleanedJsonString = cleanJsonString(jsonString);
    
                if (isValidJson(cleanedJsonString)) {
                    return JSON.parse(cleanedJsonString);
                } else {
                    console.warn('Invalid JSON format in block.');
                }
            }
    
            // Step 3: Alternative case - Locate JSON after 'json\n'
            const startIndexAlt = text.indexOf('json\\n');
            if (startIndexAlt !== -1) {
                let rawJsonString = text.substring(startIndexAlt + 6).trim();
    
                // Adjust format if JSON doesn't start with "{"
                if (!rawJsonString.startsWith('{')) {
                    rawJsonString = `{"${rawJsonString}`;
                    rawJsonString = rawJsonString.replace(/""title/g, '"title');
                }
    
                let cleanedJsonString = cleanJsonString(rawJsonString);
                const lastBraceIndex = cleanedJsonString.lastIndexOf('}');
                if (lastBraceIndex !== -1) {
                    cleanedJsonString = cleanedJsonString.substring(0, lastBraceIndex + 1);
                }
    
                if (isValidJson(cleanedJsonString)) {
                    return JSON.parse(cleanedJsonString);
                } else {
                    console.warn('Invalid JSON format in alternative block.');
                }
            }
    
            return null;
        } catch (error) {
            console.error('Error extracting JSON:', error.message);
            console.info('Proceeding to fallback steps.');
            return null;

        }
    };  


    const setOverlayLast = () => {
        dispatch(setFlag('overlayFlag', false));
    };

    useEffect(() => {
        if (chartCode && chartContainerRef.current && !isGeoChart) {
            try {
                const sanitizedCode = chartCode
                    .replace(/import\s+.*?;/g, '')
                    .replace(/export\s+default\s+/, '');

                console.log("sanitizedCode: ", sanitizedCode)
                setChartError(null);
                const { data, layout, config } = JSON.parse(sanitizedCode);
                const plotData = Array.isArray(data) ? data : [data];
                // Avoid rendering if there are errors in the data
                const hasError = plotData.some(
                    (dataset) =>
                        dataset.x?.includes('Error') || dataset.y?.includes('Error')
                );


                if (!hasError) {
                    // Create React root if not already created
                    if (!rootRef.current) {
                        rootRef.current = createRoot(chartContainerRef.current);
                    }

                    const updatedPlotData = plotData.map((trace) => ({
                        ...trace,
                        textfont: { color: 'white' },
                    }));


                    // Calculate container width based on sidebar state
                    const containerWidth = isSidebarCollapsed ? '100%' : '96%';


                    rootRef.current.render(
                        <div
                            className="analytics-card p-3 mt-3"
                            style={{
                                width: containerWidth,
                                transition: 'width 0.3s ease', // Smooth transition for width changes
                            }}
                        >
                            <Plot
                                data={updatedPlotData}
                                layout={{
                                    ...layout,
                                    autosize: true, // Enable autosize
                                    paper_bgcolor: 'transparent',
                                    plot_bgcolor: 'transparent',
                                    margin: {
                                        ...layout.margin,
                                        t: 50,
                                        b: 100, // Increased bottom margin to accommodate legend
                                        l: 60,
                                        r: 30,
                                    },
                                    title: {
                                        text: layout.title?.text || '',
                                        font: { color: 'white', size: 24 },
                                        x: 0.5,
                                        xanchor: 'center',
                                    },
                                    xaxis: {
                                        ...layout.xaxis,
                                        title: {
                                            ...layout.xaxis?.title,
                                            font: { color: 'white', size: 16 },
                                        },
                                        tickfont: { color: 'white', size: 12 },
                                        tickangle: 45,
                                        showgrid: true,
                                        gridcolor: 'rgba(255, 255, 255, 0.1)',
                                        zeroline: false,
                                        showline: true,
                                        linecolor: 'white',
                                    },
                                    yaxis: {
                                        ...layout.yaxis,
                                        title: {
                                            ...layout.yaxis?.title,
                                            font: { color: 'white', size: 16 },
                                        },
                                        tickfont: { color: 'white', size: 12 },
                                        showgrid: true,
                                        gridcolor: 'rgba(255, 255, 255, 0.1)',
                                        zeroline: false,
                                        showline: true,
                                        linecolor: 'white',
                                    },
                                    legend: {
                                        font: { color: 'white', size: 14 },
                                        orientation: 'h', // Horizontal orientation
                                        x: 0.5,           // Center horizontally
                                        xanchor: 'center', // Align center of legend with chart center
                                        y: -0.3,           // Place below the x-axis title
                                        yanchor: 'top',    // Align top of legend with y-position
                                    },
                                    hoverlabel: {
                                        bgcolor: 'rgba(255, 255, 255, 0.8)',
                                        bordercolor: 'white',
                                        font: { color: 'black', size: 14 },
                                    },
                                }}
                                config={{
                                    responsive: true,
                                    displayModeBar: false,
                                    scrollZoom: true,
                                    showLink: false,
                                    displaylogo: false,
                                }}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                }}
                                useResizeHandler={true} // Enable resize handler
                            />
                        </div>
                    );
                }
            } catch (error) {
                console.error("Error evaluating chart code:", error);
                setChartError(error.message);
            }
        }

        return () => {
            if (rootRef.current) {
                rootRef.current.unmount(); // Properly unmount the React root
                rootRef.current = null;   // Reset the root reference
            }
        };
    }, [chartCode, isGeoChart, isSidebarCollapsed]);

    const chartOptions = chartCode ? extractJsonData(chartCode) : null;

    // const chartOptions = {
    //     title: {
    //       text: 'Sales Distribution',
    //       subtext: 'For the Year 2020',
    //       left: 'center',
    //       textStyle: {
    //         color: '#fff' // White color for text
    //       },
    //       subtextStyle: {
    //         color: '#ccc' // Lighter color for subtext
    //       }
    //     },
    //     tooltip: {
    //       trigger: 'item',
    //       formatter: '{a} <br/>{b}: {c} ({d}%)',
    //       backgroundColor: 'rgba(0, 0, 0, 0.7)', // Dark tooltip background
    //       textStyle: {
    //         color: '#fff' // White text for tooltip
    //       }
    //     },
    //     legend: {
    //       orient: 'vertical',
    //       left: 'left',
    //       data: ['Popular', 'Wine', 'Luxury', 'RTD','Premium'],
    //       textStyle: {
    //         color: '#fff' // White color for legend items
    //       }
    //     },
    //     series: [
    //       {
    //         name: 'Sales Volume',
    //         type: 'pie',
    //         radius: '50%',
    //         center: ['50%', '60%'],
    //         data: [
    //             { value: 11143224, name: 'Popular' },
    //             { value: 1392493, name: 'Wine' },
    //             { value: 7650056, name: 'Luxury' },
    //             { value: 7675684, name: 'RTD' },
    //             { value: 6954745, name: 'Premium' }
    //         ],
    //         itemStyle: {
    //           borderColor: '#2c343c', // Border color for the pie slices
    //           borderWidth: 1
    //         },
    //         emphasis: {
    //           itemStyle: {
    //             shadowBlur: 10,
    //             shadowOffsetX: 0,
    //             shadowColor: 'rgba(0, 0, 0, 0.5)' // Darker shadow effect
    //           }
    //         },
    //         label: {
    //           color: '#fff' // White text color for labels
    //         }
    //       }
    //     ],
    //     backgroundColor: '#132228' // Dark background for the entire chart
    //   };

    return (
        <div className='stylechat' >
        <div
        className={`QA-prompt ${
          isSidebarCollapsed ? 'sidebar-collapsed' : 'sidebar-expanded'
        } ${showTraceFlag ? 'show-trace' : ''}`}
      >
      
        <div className="answer-container p-3 mt-3" >
            <div className="d-flex"  >
                <IconAnswer />
                <div ref={contentRef} className="text-white font-size-12 ps-2" style={{ width: '98%' }}>
                    {response ? (
                        <>
                            {RenderParagraphs(response, source, desc, sqlQuery)}
                            {setOverlayLast()}
                        </>
                        
                    ) : (
                        <Loader />
                    )}

                    {isGeoChart ? (
                        <GeoGraph locations={chartCode} />
                    ) : (
                        chartError && (
                            <div style={{ color: 'red', padding: '20px' }}></div>
                        ))}

               
                {chartCode && !isGeoChart && chartOptions && (
                   
                   <ReactECharts option={chartOptions} />
                )}
                </div>
            </div>
        </div>
        </div>
        </div>
    );
}

export default React.memo(RenderingTheResponse, (prevProps, nextProps) => {
    return (
        prevProps.question === nextProps.question &&
        prevProps.response === nextProps.response &&
        prevProps.chartCode === nextProps.chartCode &&
        prevProps.sqlQuery === nextProps.sqlQuery &&
        prevProps.queryResult === nextProps.queryResult &&
        prevProps.desc === nextProps.desc &&
        prevProps.source === nextProps.source
    );
});
