import React, { useState } from 'react';

// Example icon URL, replace with your own icon or an SVG
const tableIconUrl = 'https://th.bing.com/th/id/OIP.jyjWMuqQt62Y-ZRrqu07eQAAAA?pid=ImgDet&w=60&h=60&c=7&dpr=1.5&rs=1';

// =================================================================================================
const SourceFunction = ({ sqlQuery }) => {
    console.log("SourceFunction");
    
    if (!sqlQuery) return null;

    const tablesMap = new Map();
    const aliasMap = new Map();

    // Reserved keywords and functions to ignore
    const reservedKeywords = new Set([
        "SELECT", "FROM", "WHERE", "GROUP", "BY", "ORDER", "AS", "SUM", "SUBSTRING", "FIELD", "EXTRACT",
        "CASE", "WHEN", "THEN", "ELSE", "END", "DISTINCT", "HAVING", "JOIN", "ON", "LEFT", "RIGHT", "FULL",
        "OUTER", "INNER", "WITH", "USING", "LIMIT", "OFFSET", "UNION", "ALL"
    ]);

    // Step 1: Extract tables and aliases
    const tableRegex = /\bFROM\s+([a-zA-Z0-9_]+)\s*(?:AS\s+([a-zA-Z0-9_]+)|\s+([a-zA-Z0-9_]+))?|JOIN\s+([a-zA-Z0-9_]+)\s*(?:AS\s+([a-zA-Z0-9_]+)|\s+([a-zA-Z0-9_]+))?/gi;
    let match;

    while ((match = tableRegex.exec(sqlQuery)) !== null) {
        const tableName = match[1] || match[4];
        const alias = match[2] || match[3] || match[5] || match[6] || tableName;

        // Remove table name if it contains "the" or "conditions"
        if (tableName.toLowerCase().includes('the') || tableName.toLowerCase().includes('conditions')|| tableName.toLowerCase().includes('dataset')) {
            continue; // Skip adding this table
        }

        aliasMap.set(alias, tableName);
        if (!tablesMap.has(tableName)) {
            tablesMap.set(tableName, new Set());
        }
    }

    // Step 2: Extract columns
    const selectSectionRegex = /\bSELECT\b\s+([\s\S]*?)\bFROM\b/i;
    const selectSectionMatch = selectSectionRegex.exec(sqlQuery);

    if (selectSectionMatch) {
        const selectContent = selectSectionMatch[1];
        const columnRegex = /(?:([a-zA-Z0-9_]+)\.)?([a-zA-Z0-9_]+)|\bAS\s+([a-zA-Z0-9_]+)/gi;

        while ((match = columnRegex.exec(selectContent)) !== null) {
            const tableAlias = match[1];
            const columnName = match[2] || match[3];

            // Filter out reserved keywords, numeric literals, and derived values
            if (
                !reservedKeywords.has(columnName.toUpperCase()) &&
                isNaN(columnName) && // Exclude numeric values
                !["Month", "month", "Year", "year"].includes(columnName) // Exclude specific derived values
            ) {
                if (tableAlias) {
                    // Assign to specific table via alias
                    const tableName = aliasMap.get(tableAlias);
                    if (tableName && tablesMap.has(tableName)) {
                        tablesMap.get(tableName).add(columnName);
                    }
                } else {
                    // Assign unqualified columns to all tables
                    for (const [tableName, columns] of tablesMap.entries()) {
                        columns.add(columnName);
                    }
                }
            }
        }
    }

    const tables = Array.from(tablesMap);
    console.log("Extracted tables and columns:", tables);

    return (
        <div className="row pb-2">
            {tables.map(([tableName, columns], index) => (
                <div className="col-6 ps-3" key={index}>
                    {tableName && <Accordion index={index} tableName={tableName} columns={columns} />}
                </div>
            ))}
        </div>
    );
};



// const SourceFunction = ({ sqlQuery }) => {
//     if (!sqlQuery) return null;
//     const tablesMap = new Map();
//     const aliasMap = new Map();

//     // Regex for extracting table names with optional alias and column names
//     const tableRegex = /\bFROM\s+([a-zA-Z0-9_]+)\s*(?:AS\s+)?([a-zA-Z0-9_]+)?|\bJOIN\s+([a-zA-Z0-9_]+)\s*(?:AS\s+)?([a-zA-Z0-9_]+)?/gi;
//     const columnRegex = /\b([a-zA-Z0-9_]+)\.([a-zA-Z0-9_]+)\b/gi;

//     let match;

//     // Extract table names and their aliases from the SQL query
//     while ((match = tableRegex.exec(sqlQuery)) !== null) {
//         const tableName = match[1] || match[3];
//         const alias = match[2] || match[4] || tableName;
//         aliasMap.set(alias, tableName);
//         if (!tablesMap.has(tableName)) {
//             tablesMap.set(tableName, new Set());
//         }
//     }

//     // Extract columns and associate them with their tables
//     while ((match = columnRegex.exec(sqlQuery)) !== null) {
//         const tableAlias = match[1];
//         const columnName = match[2];
//         const tableName = aliasMap.get(tableAlias);
//         if (tableName && tablesMap.has(tableName)) {
//             tablesMap.get(tableName).add(columnName);
//         }
//     }

//     // Filter out WITH clause tables
//     const withClauseRegex = /\bWITH\s+([a-zA-Z0-9_]+)\b/gi;
//     while ((match = withClauseRegex.exec(sqlQuery)) !== null) {
//         const tableName = match[1];
//         tablesMap.delete(tableName);
//     }

//     const tables = Array.from(tablesMap);

//     // Debug: Log to verify table and column data
//     console.log("Extracted tables and columns:", tables);

//     return (
//         <div className="row pb-2">
//             {tables.map(([tableName, columns], index) => (
//                 <div className="col-6 ps-3" key={index}>
//                     <Accordion index={index} tableName={tableName} columns={columns} />
//                 </div>
//             ))}
//         </div>
//     );
// };

// =================================================================================================
const Accordion = ({ index, tableName, columns }) => {
    const [isOpen, setIsOpen] = useState(false); // Track open/closed state of the accordion
    const toggleAccordion = () => {
        setIsOpen(!isOpen); // Toggle state on click
    };

    const sanitizedTableName = tableName.toLowerCase().includes('the') || tableName.toLowerCase().includes('conditions')
        ? null  // Replace with an empty string if the condition is met
        : tableName;

    return (
        <div id={`accordion-${index}`} className="custom-accordion analytics-card">
            <div className="card mb-1 shadow-none" style={{ backgroundColor: '#132228', border: "1px solid #1d323b" }}>
                <div
                    className={`text-reset card-header  ${isOpen ? 'active' : ''}`}
                    onClick={toggleAccordion} // Handle toggle
                    aria-expanded={isOpen ? 'true' : 'false'}
                    id={`heading-${index}`}
                    style={{
                        backgroundColor: "#25343a",
                        color: " #fff ",
                        padding: "8px 12px",
                        borderBottom: "none"
                    }}
                >
                    <h6 className="m-0">
                        <i className="mdi mdi-table"></i> Table: {sanitizedTableName}
                        <i className={`mdi float-end accor-plus-icon ${isOpen ? 'mdi-minus' : 'mdi-plus'}`}></i>
                    </h6>
                </div>

                <div
                    id={`collapse-${index}`}
                    className={`collapse ${isOpen ? 'show' : ''}`}
                    aria-labelledby={`heading-${index}`}
                    data-bs-parent={`#accordion-${index}`}
                >
                    <div className="card-body" style={{ backgroundColor: "#1f2b30", paddingLeft: "0px", paddingTop: "10px", paddingBottom: "1px" }}>
                        <ul className="multi-column-list" style={{ listStyle: "none" }}>
                            {Array.from(columns).map((columnName, colIndex) => (
                                <li key={colIndex} style={{ color: "#fff" }}>
                                    <i className="mdi mdi-check-circle-outline"></i> {columnName}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

// =================================================================================================
const ParagraphFunction = ({ text }) => {
    // Split the text by sentences ending with a period followed by a space
    console.log("Paragraph function is calling")
    const sentences = text.split('\n').map(sentence => sentence.trim());

    return (
        <div style={{ marginTop: '5px', paddingLeft: '5px', paddingRight: '20px' }}>
            {sentences.map((sentence, index) => {
                // Check if the sentence contains a number with a unit like million, billion, k, M, B, etc.
                const hasUnit = /\d+(\.\d+)?\s?(million|billion|k|M|B|thousand|trillion)/i.test(sentence);

                // If the sentence contains a unit, don't split it
                if (hasUnit) {
                    return (
                        <div key={index}>
                            <p style={{ marginLeft: '20px', fontSize: '14px',  textAlign: 'justify' }}>{sentence}</p>
                        </div>
                    );
                }

                // Otherwise, split the sentence by ": \n" if it exists
                const parts = sentence.split(':\n');

                // If the sentence doesn't contain ": \n", just return it as a single block
                if (parts.length < 2 || !parts[1]) {
                    return (
                        <div key={index}>
                            <p style={{ marginLeft: '20px', fontSize: '14px',  textAlign: 'justify' }}>{parts[0]?.trim()}</p>
                        </div>
                    );
                } else {
                    // If it contains ": \n", split into header and content
                    return (
                        <div key={index}>
                            <h6 className="text-white">{parts[0]?.trim()}:</h6>
                            <p style={{ marginLeft: '20px', fontSize: '14px', textAlign: 'justify' }}>{parts[1]?.trim()}</p>
                        </div>
                    );
                }
            })}
        </div>
    );
}


// =================================================================================================
const DescriptionFunction = ({ desc }) => {
    const [isOpen, setIsOpen] = useState(true);

    if (!desc) return null;

    // Split the description into an array, remove leading dashes, and join with spacing.
    const content = desc
        .split('\n')
        .map(item => item.replace(/^\s*-\s*/, ''))
        .filter(item => item.trim() !== '') // Remove any empty lines
        .join('\n\n'); // Add a double newline for spacing between items

    const handleToggle = () => {
        setIsOpen(isOpen);
    };

    return (
        <>
            <div style={{ width: '100%', margin: '0 auto', borderRadius: '8px' }}>
                <div
                    style={{
                        backgroundColor: '#25343a !important',
                        padding: '10px',
                        cursor: 'pointer',
                        border: '1px solid rgb(221, 221, 221)',
                        fontWeight: 'bold',
                        textAlign: 'center',
                    }}
                    onClick={handleToggle}
                >
                    {`Show Steps`}
                </div>
                <div
                    style={{
                        maxHeight: isOpen ? '200px' : '0',
                        overflowY: isOpen ? 'scroll' : 'hidden', // Always show scrollbar when open
                        transition: 'max-height 0.3s ease',
                        padding: isOpen ? '10px' : '0 10px',
                        backgroundColor: '#ffffff',
                        whiteSpace: 'pre-wrap',
                        borderRadius: '5px',
                    }}
                >
                    {content}
                </div>
            </div>
        </>
    );
};

// =================================================================================================
const RenderParagraphs = (text, source, desc, sqlQuery) => {
    return (
        <>
            <SourceFunction sqlQuery={sqlQuery} />
            {/* <DescriptionFunction desc={desc} /> */}
            <ParagraphFunction text={text} />
        </>
    );
};

export default RenderParagraphs;
