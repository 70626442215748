import React from 'react';
import { Drawer } from '@mui/material';

const FinishModal = ({ isOpen, onClose, optionSelection }) => {
    console.log("FinishModal rendered with isOpen:", isOpen, "Window Index:", optionSelection);

    // Position 1 - First UI Design
    const position1 = () => (
        <>
            {/* Arrow and Drawer for Position 1 */}
            <div
                className="introjs-arrow left"
                style={{
                    transform: 'rotate(90deg)',
                    display: isOpen ? 'block' : 'none', // Dynamically change display based on isOpen
                    position: "absolute", // Absolute positioning to control placemen
                    left: "3.8%",
                    marginLeft: "10px",

                    // Adjust the horizontal position of the arrow
                    top: '134px', // Adjust the vertical position to be near the modal                    
                    border: 'solid', // Arrow border styling
                    borderWidth: '10px', // Size of the arrow
                    borderColor: '#FFFFFF transparent transparent transparent', // Ensures the arrow looks like it's pointing left
                    zIndex: 9999 // Ensure the arrow is visible above other content
                }}
            ></div>

            <Drawer
                anchor="bottom" // Position modal at the bottom
                open={isOpen}
                onClose={onClose}
                PaperProps={{
                    sx: {
                        top: '150px',
                        position: 'absolute', // Use absolute positioning
                        bottom: '0', // Pin to the bottom
                        left: '7%', // Adjust to ensure horizontal centering
                        transform: 'translateX(-50%)', // Correct centering
                        width: '300px !important', // Set width to 300px with !important
                        height: '350px', // Set height
                        padding: '16px', // Adjust padding
                        bgcolor: '#FFFFFF', // Background color white
                        display: 'flex',
                        flexDirection: 'column', // Vertical layout
                        borderRadius: '12px 12px 0 0', // Rounded top corners
                        boxShadow: '0px -2px 8px rgba(0, 0, 0, 0.1)', // Optional shadow
                    }
                }}
            >
                {/* Header */}

                {/* Title and Image */}
                <div className="item" style={{ textAlign: 'center', paddingBottom: '10px' }}>
                    <img
                        src="assets/images/intro-step5.gif"
                        style={{ width: '164px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
                        alt="Step Illustration"
                    />
                    <p className="font-size-12 text-dark text-start ps-3 pe-3 pb-2 mb-0">
                        Ask any questions and interact with the AI by typing in the chat box below or exploring the suggested questions on the side to get started quickly!
                    </p>
                </div>

                {/* Footer with Pagination Controls */}
                <div style={{ marginTop: 'auto' }}>
                    <div
                        className="ps-3 pe-3 pt-3 pb-1 d-flex align-items-center justify-content-between"
                        style={{ borderTop: '1px solid #e9e9e9' }}
                    >
                        <h6
                            className="mb-0 fw-medium pt-1 pb-1 cursor-pointer text-start font-size-12"
                            onClick={onClose}
                        >
                            Skip Tour
                        </h6>

                        {/* <div className="d-flex align-items-center">
                            <button 
                                id="prevBtn" 
                                onClick={() => changePage(1)} 
                                className="paginate-btn p-0 me-1"
                            >
                                <i className="fas fa-chevron-left font-size-12"></i>
                            </button>

                            <p className="p-0 mb-0 font-size-12 me-1">
                                <span id="pageNumber">1</span> of <span id="totalPages">2</span>
                            </p>

                            <button 
                                id="nextBtn" 
                                onClick={() => changePage(2)} 
                                className="paginate-btn p-0"
                            >
                                <i className="fas fa-chevron-right font-size-12"></i>
                            </button>
                        </div> */}
                    </div>
                </div>
            </Drawer>
        </>



    );

    // Position 2 - Second UI Design
    const position2 = () => (
        <>
            <div
                className="introjs-arrow leftTwo"
                style={{
                    transform: 'rotate(90deg)',
                    display: isOpen ? 'block' : 'none', // Dynamically change display based on isOpen
                    position: "absolute", // Absolute positioning to control placemen
                    left: "4%",
                    marginLeft: "10px",

                    // Adjust the horizontal position of the arrow
                    top: '189px', // Adjust the vertical position to be near the modal                    
                    border: 'solid', // Arrow border styling
                    borderWidth: '10px', // Size of the arrow
                    borderColor: '#FFFFFF transparent transparent transparent', // Ensures the arrow looks like it's pointing left
                    zIndex: 9999 // Ensure the arrow is visible above other content
                }}
            ></div>

            <Drawer
                anchor="bottom" // Position modal at the bottom
                open={isOpen}
                onClose={onClose}
                PaperProps={{
                    sx: {
                        top: '200px',
                        position: 'absolute', // Use absolute positioning
                        bottom: '0', // Pin to the bottom
                        left: '-4%', // Adjust to ensure horizontal centering
                        transform: 'translateX(-50%)', // Correct centering
                        width: '300px !important', // Set width to 300px with !important
                        height: '350px', // Set height
                        padding: '16px', // Adjust padding
                        bgcolor: '#FFFFFF', // Background color white
                        display: 'flex',
                        flexDirection: 'column', // Vertical layout
                        borderRadius: '12px 12px 0 0', // Rounded top corners
                        boxShadow: '0px -2px 8px rgba(0, 0, 0, 0.1)', // Optional shadow
                    }
                }}
                className="position2Drawer"
            >
                {/* Header */}

                {/* Title and Image */}
                <div className="item" style={{ textAlign: 'center', paddingBottom: '10px' }}>
                    <img
                        src="assets/images/intro-step6.gif"
                        style={{ width: '164px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
                        alt="Step Illustration"
                    />
                    <p className="font-size-12 text-dark text-start ps-3 pe-3 pb-2 mb-0">
                        Upload a document to start an AI-powered conversation. Our system processes the content, allowing you to ask questions and extract insights in real time.
                    </p>
                </div>

                {/* Footer with Pagination Controls */}
                <div style={{ marginTop: 'auto' }}>
                    <div
                        className="ps-3 pe-3 pt-3 pb-1 d-flex align-items-center justify-content-between"
                        style={{ borderTop: '1px solid #e9e9e9' }}
                    >
                        <h6
                            className="mb-0 fw-medium pt-1 pb-1 cursor-pointer text-start font-size-12"
                            onClick={onClose}
                        >
                            Skip Tour
                        </h6>

                        {/* <div className="d-flex align-items-center">
                            <button 
                                id="prevBtn" 
                                onClick={() => changePage(1)} 
                                className="paginate-btn p-0 me-1"
                            >
                                <i className="fas fa-chevron-left font-size-12"></i>
                            </button>

                            <p className="p-0 mb-0 font-size-12 me-1">
                                <span id="pageNumber">1</span> of <span id="totalPages">2</span>
                            </p>

                            <button 
                                id="nextBtn" 
                                onClick={() => changePage(2)} 
                                className="paginate-btn p-0"
                            >
                                <i className="fas fa-chevron-right font-size-12"></i>
                            </button>
                        </div> */}
                    </div>
                </div>
            </Drawer>
        </>
    );


    // Render based on finalWindowIndex
    return (
        <>
            {optionSelection === 1 && position1()}
            {optionSelection === 2 && position2()}

        </>
    );
};

export default FinishModal;
