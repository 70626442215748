import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import RenderingTheResponse from './RenderingTheResponse';
import RenderingTheQuestion from './RenderingTheQuestion';
import RaiseAnswer from './RaiseAnswer';

// HistoryItem component
const HistoryItem = React.memo(({ item, tabId }) => {
    const { question, response, sql_query, chartCode, query_results, desc, source } = item;

    // Memoized RenderingTheQuestion to prevent re-renders
    const MemoizedQuestion = useMemo(() => (
        <RenderingTheQuestion question={question} sqlQuery={sql_query} />
    ), [question, sql_query]);

    // Memoized RenderingTheResponse to prevent re-renders
    const MemoizedResponse = useMemo(() => (
        <RenderingTheResponse
            response={response}
            chartCode={chartCode}
            sqlQuery={sql_query}
            queryResult={query_results}
            desc={desc}
            source={source}
        />
    ), [response, chartCode, sql_query, query_results, desc, source]);

    return (
        // <div className="tab-pane fade show" id={tabId} role="tabpanel" style={{overflow: 'scroll', height: '340px'}}>
        <div className="tab-pane fade show" id={tabId} role="tabpanel">
            <div className='page-cont2 searchafter'>
            <div className=''>           
                {MemoizedQuestion}
                {MemoizedResponse}   
                <div className="row pt-2 pb-4">
            
            </div>        
            </div>
                    </div>
        </div>
    );
});

// HistoryContent component
const HistoryContent = () => {
    const activeHistoryTab = useSelector((state) => state.activeHistoryTab);
    const chatHistory = useSelector((state) => state.updateChatHistory);
    const userRaisedQuestionFlag = useSelector((state) => state.flags.userRaisedQuestionFlag);
    const userClickedAnyChatHistory = useSelector((state) => state.flags.userClickedAnyChatHistory);
    // const showTraceFlag = useSelector((state) => state.flags.showTrace);
    // Memoize the history items
    const historyItems = useMemo(() => {
        if (!activeHistoryTab || !chatHistory[activeHistoryTab]) return null;

        return chatHistory[activeHistoryTab].map((item, index) => (
            <HistoryItem
                key={`${activeHistoryTab}-${index}`}
                item={item}
                tabId={`${activeHistoryTab}-${index}`}
            />
        ));
    }, [activeHistoryTab, chatHistory]);

    return (
        <div className="hiscon" style={{
            // marginTop:'45px',
            maxHeight: 'calc(80vh - 150px)',
            // paddingLeft: '70px',
            // paddingRight: '70px',
            fontSize: '16px',
            minHeight: 'calc(89vh - 130px)',
            overflowY: 'scroll',
            // width: '81vw'
        }}>
            {historyItems}
            {userRaisedQuestionFlag && !userClickedAnyChatHistory && <RaiseAnswer />}
        </div>
    );
};

export default React.memo(HistoryContent);
