import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import IconAnswer from '../../utils/IconAnswer';
import IconQuestion from '../../utils/IconQuestion';
import { getChatMLResponse } from '../../services/chatService';
import Plot from 'react-plotly.js';
import Loader from '../../utils/Loader';
import { addConversationMLTask } from '../../store/actions';
import QuestionInputForm from '../QuestionInputForm';

const MLTaskRaiseQuestion = () => {
    console.log("MLTaskRaiseQuestion.js")
    const dispatch = useDispatch();
    const questionInputFormRef = useRef(null);
    const savedTasks = useSelector(state => state.mlTaskHistory); // Move useSelector outside of useEffect
    const [isLoading, setIsLoading] = useState(false);
    const [myQuestion, setMyQuestion] = useState('');
    const [conversations, setConversations] = useState(() => savedTasks || []);
    const [showLogs, setShowLogs] = useState({});
    const activeUser = useSelector((state) => state.activeUser);
    const activeUserId = activeUser.user_id;
    const activeuser_access_rules = activeUser.access_rules;



    useEffect(() => {
        // This will run once when the component mounts and set saved tasks in conversations
        if (Array.isArray(savedTasks)) {
            setConversations(savedTasks);
        }
    }, [savedTasks]);

    // useEffect(() => {
    //     console.log("MLTaskRaiseQuestion check this ................", conversations)
    // }, [conversations]);

    const fetchSessionResult = async (sessionId, conversationIndex) => {
        console.log("fetchSessionResult")
        const API_URL_PYTHON = process.env.REACT_APP_RAG_ML;

        try {
            console.log("session_result: ", `${API_URL_PYTHON}/session_result/${sessionId}`)

            const response = await fetch(`${API_URL_PYTHON}/session_result/${sessionId}`, {
                method: 'GET', // You can adjust the method if needed (GET, POST, etc.)
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('access_token').replace(/"/g, '')}`
                },
            });

            console.log("response: ", response)

            if (!response.ok) {
                throw new Error('Failed to fetch session result');
            }
            const data = await response.json();
            console.log("data: ", data)

            if (data.status === 'failed') {
                throw new Error(data.status.error);
            }

            if (data) {
                // setConversations(prevConversations => {
                //     return prevConversations.map((conv, index) => {
                //         if (index === conversationIndex) {
                //             return {
                //                 ...conv,
                //                 answer: data.result.text,
                //                 logs: [...conv.logs, "Final result fetched and displayed."],
                //                 chartData: data.result.chartCode ? data.result.chartCode : null
                //             };
                //         }
                //         return conv;
                //     });
                // });


                setConversations(prevConversations => {
                    const updatedConversations = prevConversations.map((conv, index) => {
                        if (index === conversationIndex) {
                            return {
                                ...conv,
                                answer: data.result.text,
                                // logs: [...conv.logs, "Final result fetched and displayed."],
                                chartData: data.result.chartCode ? data.result.chartCode : null,
                                isLoading: false
                            };
                        }
                        return conv;
                    });

                    // Dispatch after state update
                    dispatch(addConversationMLTask(updatedConversations[conversationIndex]));

                    return updatedConversations;
                });
            }
        } catch (error) {
            console.error("Wizard : 1 : Error fetching session result:", error);
            setConversations(prevConversations => {
                return prevConversations.map((conv, index) => {
                    if (index === conversationIndex) {
                        return {
                            ...conv,
                            answer: "Sorry, there was an error processing your question. Please try again.",
                            logs: [...conv.logs, `Error fetching final result: ${error.message}`],
                            isLoading: false
                        };
                    }
                    return conv;
                });
            });
        } finally {
            questionInputFormRef.current?.clearInput();
            setIsLoading(false);
        }

        console.log("fetching result close...")
    };

    const handleSubmit = useCallback(async (question) => {
        setMyQuestion(question);
        setIsLoading(true);

        const newConversationIndex = conversations ? conversations.length : 0;
        console.log("newConversationIndex: ", newConversationIndex)

        const newConversation = {
            id: Date.now(), // Unique identifier for the conversation
            question: question,
            answer: '',
            logs: [],
            chartData: null,
            isLoading: true
        };

        // Update conversations state
        setConversations(prev => [...prev, newConversation]);

        // Dispatch action outside of setConversations
        // dispatch(addConversationMLTask(newConversation));

        try {
            // const reader = await getChatMLResponse(question, 'Wine,Luxury,Premium,RTD,Prestige,Popular');
            const reader = await getChatMLResponse(question, activeuser_access_rules);
            const mySessionId = reader.session_id;

            if (!mySessionId) {
                throw new Error("Session ID is undefined or null");
            }

            const WEB_SOCKET_URL = process.env.REACT_APP_WEB_SOCKET;
            const fetchWSUrl = `${WEB_SOCKET_URL}/ws/stream_chat/${mySessionId}`;

            console.log("fetchWSUrl: ", fetchWSUrl)

            const ws = new WebSocket(fetchWSUrl);

            ws.onopen = () => {
                console.log("ws.open")

                setConversations(prev => {
                    const updated = [...prev];
                    // updated[newConversationIndex].logs.push("WebSocket connection opened.");
                    return updated;
                });
                ws.send(JSON.stringify({ question: question }));

                console.log("ws.open.........1")
            };

            ws.onmessage = (event) => {
                console.log("ws.onmessage")

                const newLog = event.data.replace(`[Session ${mySessionId}]`, '').trim();
                setConversations(prev => {
                    const updated = [...prev];
                    if (updated[newConversationIndex].logs[updated[newConversationIndex].logs.length - 1] !== newLog) {
                        updated[newConversationIndex].logs.push(newLog);
                    }
                    return updated;
                });

                console.log("ws.onmessage.........1")
            };

            ws.onerror = (error) => {
                console.log("ws.onerror")

                setConversations(prev => {
                    const updated = [...prev];
                    updated[newConversationIndex].logs.push(`WebSocket error: ${error.message}`);
                    return updated;
                });

                console.log("ws.onerror.........1")
            };

            ws.onclose = () => {
                console.log("ws.onclose")

                setConversations(prev => {
                    const updated = [...prev];
                    // updated[newConversationIndex].logs.push("WebSocket connection closed.");
                    return updated;
                });

                console.log("ws.onclose2222")

                fetchSessionResult(mySessionId, newConversationIndex);

                console.log("ws.onclose.........1")
                questionInputFormRef.current?.clearInput(); // Use ref to clear input
                setIsLoading(false);
            };
        } catch (error) {
            console.error("Error processing question:", error);

            setConversations(prev => {
                const updated = [...prev];
                updated[newConversationIndex] = {
                    ...updated[newConversationIndex],
                    answer: "Sorry, there was an error processing your question. Please try again.",
                    logs: [...updated[newConversationIndex].logs, "Error occurred while processing."],
                    isLoading: false
                };
                return updated;
            });

            questionInputFormRef.current?.clearInput(); // Use ref to clear input
            setIsLoading(false);
        }

    }, [conversations, activeUser.access_rules]);

    const toggleLogs = useCallback((index) => {
        setShowLogs(prev => ({
            ...prev,
            [index]: !prev[index]
        }));
    }, []);

    const renderTable = (rows) => (
        <table className="table table-bordered">
            <tbody>
                {rows.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                        {row.map((cell, cellIndex) => (
                            <td key={cellIndex}>{cell.trim()}</td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );

    const formatAnswerText = (text) => {
        if (!text) return null;

        // Remove ##, ###, and ** from the text
        const cleanText = text
            .replace(/##+/g, '')   // Remove ## or ### from headings
            .replace(/\*\*/g, ''); // Remove ** from bold text

        const sections = cleanText.split('\n\n').filter(Boolean);

        return sections.map((section, index) => {
            const [title, ...content] = section.split('\n');

            const isTable = content.some(line => line.includes('|'));
            const formattedContent = isTable
                ? renderTable(content.map(row => row.split('|')))
                : (
                    <div>
                        {content.map((item, itemIndex) => (
                            <div key={itemIndex}>{item.trim()}</div>
                        ))}
                    </div>
                );

            return (
                <div key={index} className="mb-4">
                    <h5 className="mb-2">{title.trim()}</h5>
                    {formattedContent}
                </div>
            );
        });
    };


    return (
        <>
            <div className='pt-4' style={{ textAlign: 'center' }}>
                <div style={{ maxHeight: 'calc(100vh - 200px)', minHeight: 'calc(100vh - 200px)', paddingLeft: '70px', paddingRight: '70px', fontSize: '16px', overflowY: 'scroll' }} data-simplebar>
                    <div className="pt-3" style={{ height: '800px', textAlign: 'left' }}>
                        {conversations && Array.isArray(conversations) && conversations.length > 0 &&
                            conversations.map((conv, index) => (
                                <div key={index}>
                                    {/* Question section remains the same */}
                                    <div className="d-flex pb-1" style={{ marginTop: '20px' }}>
                                        <IconQuestion />
                                        <p className="mb-0 ms-2 inline-comments" style={{ fontSize: '16px', position: 'relative', top: '6px' }}>
                                            {conv.question}
                                        </p>
                                        <a onClick={() => toggleLogs(index)} className="btn btn-link ms-2" style={{ fontSize: '14px', whiteSpace: 'nowrap' }}>
                                            <i className={`fas fa-chevron-${showLogs[index] ? 'up' : 'down'}`}></i> Logs
                                        </a>
                                    </div>

                                    {/* Logs section remains the same */}
                                    {showLogs[index] && (
                                        <div
                                            className="logs-section mt-2 p-2"
                                            style={{
                                                width: 'auto',
                                                overflowY: 'scroll',
                                                maxHeight: '300px',
                                                scrollbarWidth: 'thin',
                                                scrollbarColor: 'grey transparent',
                                            }}
                                        >
                                            <p className="log-content" align="left" style={{ marginBottom: 0 }}>
                                                {conv.logs.join('\n') || 'No logs available'}
                                            </p>
                                        </div>
                                    )}

                                    {/* Answer section with updated formatting */}
                                    <div className="d-flex mt-3 mb-0">
                                        <IconAnswer />

                                        <div className="ms-0" style={{ width: '100%', paddingLeft: '19px' }}>
                                            <div className="d-flex flex-column mb-2"></div>

                                            {conv.isLoading && (<Loader />)} {/* Display Loader only for the loading conversation */

                                                <div style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', fontSize: '16px' }}>
                                                    {formatAnswerText(conv.answer)}
                                                </div>
                                            }

                                            {conv.chartData &&
                                                // Parse chartData if it's a string
                                                (() => {
                                                    try {
                                                        // Parse the JSON structure if it's a string
                                                        const parsedChartData = typeof conv.chartData === 'string'
                                                            ? JSON.parse(conv.chartData)
                                                            : conv.chartData;

                                                        // Check if `data` is an array and render the Plot component
                                                        const data = Array.isArray(parsedChartData.data)
                                                            ? parsedChartData.data
                                                            : [parsedChartData.data];  // Wrap it in an array if it's not

                                                        return (
                                                            <Plot
                                                                data={data}
                                                                layout={{
                                                                    ...parsedChartData.layout,
                                                                    paper_bgcolor: 'transparent',
                                                                    plot_bgcolor: 'transparent'
                                                                }}
                                                                config={parsedChartData.config}
                                                                style={{ width: '100%', height: '400px' }}
                                                            />
                                                        );
                                                    } catch (error) {
                                                        console.error("Error parsing chart data:", error);
                                                        return null;  // Return nothing if there's an error
                                                    }
                                                })()
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            </div>

            <QuestionInputForm
                onSubmit={handleSubmit}
                isLoading={isLoading}
            />
        </>
    );
}

export default MLTaskRaiseQuestion;