import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import Select from "react-select";
import IconAnswer from '../../utils/IconAnswer';
import IconQuestion from '../../utils/IconQuestion';
import QuestionInputForm from '../QuestionInputForm';
import { motion } from 'framer-motion';

import { Eye } from 'lucide-react';
import { styled, width } from '@mui/system';

import Breadcrumb from '../../utils/Breadcrumb';
import { setFlag } from '../../store/actions';

import Loader from '../../utils/Loader';

import tracelableicon from '../../assets/images/tracelabel.png';
import backgroundImage from '../../assets/images/bg.png'

import { docproChat, getDocproProjectNamesOnly, getDocProPageContent } from '../../services/chatService';


import {
    Box,
    Typography,
    Button,
    Paper,
    List,
    ListItem,
    CircularProgress,
    Chip,
    Modal,
    IconButton,
    Tooltip,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material';
import {
    Close,
    NoteAlt,
    InfoOutlined,
    ExpandMore,
    Psychology,
    Language
} from '@mui/icons-material';


const DOCUMENT_API_URL = process.env.REACT_APP_DOCUMENT;

const MessageBubble = styled(Paper)(({ theme, sender }) => ({
    padding: theme.spacing(2),
    borderRadius: '12px',
    maxWidth: '100%',
    wordWrap: 'break-word',
    marginBottom: theme.spacing(1),
    backgroundColor: sender === 'user' ? 'transparent' : 'transparent',
    alignSelf: sender === 'user' ? 'flex-start' : 'flex-start',
    boxShadow: sender === 'user' ? 'none' : 'none',
    textAlign: 'justify'
}));

const StyledChip = styled(Chip)(({ theme }) => ({
    backgroundColor: '#e0e7ff',
    color: '#4338ca',
    '&:hover': {
        backgroundColor: '#c7d2fe',
    },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    color: '#6366f1',
}));

const DocumentViewer = ({ document, onClose, initialPage, highlightChunk }) => {
    const [content, setContent] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [pdfUrl, setPdfUrl] = useState(null);
    const [fileType, setFileType] = useState('');


    const dispatch = useDispatch();

    const [showSidebar, setShowSidebar] = useState(false);

    const toggleSidebar = () => {
        setShowSidebar(!showSidebar);
        dispatch(setFlag('showTrace', !showSidebar));
    };

    const activeUser = useSelector((state) => state.activeUser);
    const activeUserName = activeUser.username;
    const bgClass = "";

    useEffect(() => {
        const fetchDocument = async () => {
            try {
                setLoading(true);
                setError(null);
                const response = await getDocProPageContent(activeUserName, document, initialPage);

                const blob = await response.blob();
                const blobUrl = URL.createObjectURL(blob);
                setPdfUrl(blobUrl);
            } catch (error) {
                console.error('Error fetching document:', error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };
        fetchDocument();

        return () => {
            if (pdfUrl) {
                URL.revokeObjectURL(pdfUrl);
            }
        };
    }, [document]);

    const highlightText = useCallback((text) => {
        if (!highlightChunk || !text) return text;

        const escapedChunk = highlightChunk.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        const regex = new RegExp(`(${escapedChunk})`, 'gi');
        return text.replace(regex, '<div style="background-color: #e5eef5;">$1</div>');
    }, [highlightChunk]);

    // Animation variants
    const overlayVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 }
    };

    const containerVariants = {
        hidden: {
            x: '100%',
            opacity: 0
        },
        visible: {
            x: 0,
            opacity: 1,
            transition: {
                type: "spring",
                damping: 25,
                stiffness: 200
            }
        },
        exit: {
            x: '100%',
            opacity: 0,
            transition: {
                type: "spring",
                damping: 25,
                stiffness: 200
            }
        }
    };

    return (
        <motion.div
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={overlayVariants}
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                zIndex: 9999,
                overflow: 'hidden'
            }}
        >
            <motion.div
                variants={containerVariants}
                style={{
                    position: 'relative',
                    width: '75%',
                    marginLeft: '25%',
                    height: '100%',
                    backgroundColor: '#fff',
                    boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.25)',
                    borderRadius: '10px 0px 0px 10px',
                    padding: '32px',
                    overflow: 'hidden',
                    display: 'flex'
                }}
            >



                <Button onClick={onClose} style={{ position: 'absolute', right: '8px', top: '8px' }}><Close /></Button>


                <div style={{ width: '80%', height: '100%', paddingRight: '16px', maxWidth: '100%' }}>



                    <h6 style={{ marginBottom: '16px' }}>
                        {document}
                    </h6>
                    {loading ? (
                        <CircularProgress />
                    ) : error ? (
                        <div style={{ color: 'red' }}>{error}</div>
                    ) : (
                        <iframe
                            src={`${pdfUrl}#page=${initialPage}`}
                            style={{ width: '100%', height: 'calc(100% - 40px)', border: 'none' }}
                            title="PDF Viewer"
                        />
                    )}
                </div>
                <motion.div
                    initial={{ x: 100, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ delay: 0.2 }}
                    style={{
                        width: '50%',
                        height: '100%',
                        paddingLeft: '16px',
                        borderLeft: '1px solid #ccc'
                    }}
                >
                    <h6 style={{ marginBottom: '16px' }}>
                        Supporting Content
                    </h6>
                    <div style={{
                        padding: '16px',
                        maxHeight: 'calc(100% - 60px)',
                        overflow: 'auto',
                        backgroundColor: '#fff',
                        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',
                        borderRadius: '4px',
                        fontSize: '12px',
                        color: 'black'
                    }}>
                        <div
                            dangerouslySetInnerHTML={{ __html: highlightText(highlightChunk) }}
                        />
                    </div>
                </motion.div>
                <div className="position-absolute  bottom-0px" style={{ width: '65%' }}>
                    <div className="d-flex justify-content-between" style={{ marginBottom: "8px" }}>
                        <a onClick={onClose} className="btn btn-light btn-sm border-trace right-bar-toggle5 centered-button">Close</a>
                        <a onClick={onClose} className="btn btn-warning btn-sm text-dark right-bar-toggle5 centered-button">Done</a>
                    </div>
                </div>
            </motion.div>

        </motion.div>
    );
};

const MetricsModal = ({ metrics, onClose, thoughtSteps }) => {
    const [isVisible, setIsVisible] = React.useState(true);
    const [alertMessage, setAlertMessage] = useState('');

    const showAlert = (message) => {
        setAlertMessage(
            `<ul style="margin: 0; padding-left: 20px;">${message
                .split('(bullet point)')
                .map((item) => item.trim())
                .filter((item) => item)
                .map((item) => `<li>${item}</li>`)
                .join('')}</ul>`
        );
        setTimeout(() => setAlertMessage(''), 10000); // Hide alert after 10 minutes
    };



    const handleClose = () => {
        setIsVisible(false);
        // Delay the actual close callback until after animation completes
        setTimeout(onClose, 200);
    };

    const metricsInfo = {
        Faithfulness: "Measures how truthful and accurate the response is to the source documents. (bullet point) Shows whether the AI sticks to the facts without making things up.",
        Relevancy: "Shows how well the response matches what you actually asked about. (bullet point) Indicates if the answer directly addresses your specific question.",
        Contextrecall: "Tells you how much of the available important information was used in the response. (bullet point) Shows whether key details from the documents were included or missed.",
        Summaryscore: "Rates how well the response captures and presents the main points. (bullet point) Indicates the quality of information organization and presentation."
    };


    // Function to determine background className based on metric key and value
    const getKeyAndBgClass = (key, value) => {
        // Convert the key to title case
        const toTitleCase = (str) => {
            return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
        };

        const upperCaseKey = toTitleCase(key);

        // Assign background color based on key or value
        let bgClass = '';
        if (upperCaseKey.toUpperCase() === 'FAITHFULNESS') {
            bgClass = 'faith-bg';
        } else if (upperCaseKey.toUpperCase() === 'RELEVANCY') {
            bgClass = 'rele-bg';
        } else if (upperCaseKey.toUpperCase() === 'CONTEXTRECALL') {
            bgClass = 'cont-bg';
        } else if (upperCaseKey.toUpperCase() === 'SUMMARYSCORE') {
            bgClass = 'summ-bg';
        }

        return { upperCaseKey, bgClass };
    };

    const ThoughtProcessSidebar = () => {
        return (
            <>
                <div
                    className="rightbar-overlay"
                    style={{
                        display: isVisible ? 'block' : 'none',
                        position: 'fixed',
                        top: 0,
                        right: 0,
                        left: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 1000
                    }}
                    onClick={onClose}
                />
                <motion.div
                    className="right-bar7"
                    initial={{ x: "100%" }}
                    animate={{ x: isVisible ? 0 : "100%" }}
                    transition={{
                        type: "spring",
                        duration: 0.2
                    }}
                    style={{
                        position: 'fixed',
                        top: 0,
                        right: 0,
                        width: '400px',
                        height: '100%',
                        backgroundColor: 'white',
                        zIndex: 1001,
                        boxShadow: '-4px 0 6px rgba(0, 0, 0, 0.1)',
                        padding: '20px'
                    }}
                >
                    <div className="d-flex align-items-center justify-content-between mb-4">
                        <h6 className="mb-0 fw-medium text-dark">AI Thought Process</h6>
                        <i
                            className="mdi mdi-close text-dark font-size-14 cursor-pointer"
                            onClick={onClose}
                        />
                    </div>

                    <div className="thought-process-content" style={{ height: 'calc(100% - 120px)', overflowY: 'auto' }}>
                        {thoughtSteps.map((step, index) => (
                            <div key={index} className="thought-step mb-4">
                                <div className="step-number bg-light rounded-circle d-inline-flex align-items-center justify-content-center"
                                    style={{ width: '24px', height: '24px', marginRight: '8px' }}>
                                    <span className="text-dark">{index + 1}</span>
                                </div>
                                <div className="step-content mt-2">
                                    <h6 className="text-dark mb-2">{step.title}</h6>
                                    <p className="text-muted mb-2">{step.description}</p>
                                    {step.props && (
                                        <div className="step-props">
                                            {Object.entries(step.props).map(([key, value]) => (
                                                <span
                                                    key={key}
                                                    className="badge bg-light text-dark me-2 mb-2"
                                                    style={{ padding: '6px 12px' }}
                                                >
                                                    {key}: {value}
                                                </span>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="position-absolute w-100 bottom-10px" style={{ left: 0, padding: '0 20px' }}>
                        <button
                            className="btn btn-light btn-sm w-100"
                            onClick={onClose}
                        >
                            Close
                        </button>
                    </div>
                </motion.div>
            </>
        );
    };

    const openThoughtProcessWindow = () => {
        onClose();
        const thoughtProcessWindow = window.open('', 'AI Thought Process', 'width=600,height=600');
        thoughtProcessWindow.document.write(`
            <html>
                <head>
                    <title>AI Thought Process</title>
                    <style>
                        body { font-family: Arial, sans-serif; padding: 20px; }
                        h1 { color: #333; }
                        .step { margin-bottom: 20px; }
                        .step-title { font-weight: bold; color: #0066cc; }
                        .step-description { margin-top: 5px; }
                        .step-props { margin-top: 10px; }
                        .prop { display: inline-block; background-color: #f0f0f0; padding: 5px 10px; margin-right: 10px; border-radius: 3px; }
                    </style>
                </head>
                <body>
                    <h1>AI Thought Process</h1>
                    ${thoughtSteps.map((step, index) => `
                        <div className="step">
                            <div className="step-title">${index + 1}. ${step.title}</div>
                            <div className="step-description">${step.description}</div>
                            ${step.props ? `
                                <div className="step-props">
                                    ${Object.entries(step.props).map(([key, value]) => `
                                        <span className="prop">${key}: ${value}</span>
                                    `).join('')}
                                </div>
                            ` : ''}
                        </div>
                    `).join('')}
                </body>
            </html>
        `);
        thoughtProcessWindow.document.close();
    };

    return (
        <motion.div
            className='right-bar6'
            initial={{ x: "100%" }}
            animate={{ x: isVisible ? 0 : "100%" }}
            transition={{
                type: "spring",

                duration: 0.2
            }}

            onClick={(e) => e.stopPropagation()}
        >
            <div data-simplebar="init" style={{ padding: '16px', height: '93%' }}>
                <div className="d-flex align-item-center justify-content-between">
                    <h6 className="mb-0 fw-medium text-dark">Metrics</h6>
                    <i
                        className="mdi mdi-close text-dark font-size-14 close-trace cursor-pointer right-bar-toggle6"
                        onClick={handleClose}
                    ></i>
                </div>

                {/* <div className="trace-label p-2 d-flex align-item-center mt-3 w-fit-content">
                    <img src={tracelableicon} className="w-14px me-2" alt="trace label" />
                    <h6 className="font-size-10 mb-0 text-dark">Sample text can come here</h6>
                </div> */}

                {Object.entries(metrics).map(([key, value]) => {
                    const { upperCaseKey, bgClass } = getKeyAndBgClass(key, value);
                    return (
                        <div className="card mt-2 mb-2" key={key}>
                            <div className="card-body" style={{ padding: '10px' }}>
                                <div className="row align-item-center">
                                    <div className="col-8"><Eye
                                        className="cursor-pointer"
                                        style={{ position: 'absolute', right: '5px', bottom: '5px' }}
                                        size={16}
                                        onClick={() => showAlert(metricsInfo[upperCaseKey])}
                                    />

                                        <h6 className="mb-0 fw-medium text-dark">{upperCaseKey}</h6>

                                    </div>
                                    <div className={`metric-card ${bgClass} col-4 d-flex justify-content-end`} style={{ width: '60px', height: '50px', borderRadius: '5px' }}>
                                        <div className={`metric-card ${bgClass} d-flex align-items-center justify-content-center`} style={{ width: '60px', height: '50px', borderRadius: '5px' }}>
                                            <h4 className="mb-0 fw-medium text-dark value-className">{value}</h4>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
                {alertMessage && (
                    <div
                        className="alert "
                        role="alert"
                        style={{
                            position: 'absolute',
                            bottom: '-3px',
                            left: '20px',
                            right: '20px',
                            maxHeight: '31vh',
                            height: 'fit-content',
                            overflowY: "scroll",
                            whiteSpace: 'pre-wrap',
                            backgroundColor: "white",
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.08)",
                            color: "black"

                        }}
                        dangerouslySetInnerHTML={{ __html: alertMessage }} // Allow rendering of bullet points
                    ></div>
                )}

            </div>

            <div className="position-absolute  bottom-10px pe-4" style={{ marginLeft: '8px' }}>
                <div className="d-flex  centered-button" style={{ gap: "8px" }}>
                    <a
                        href="#"
                        className="btn btn-light btn-sm border-trace right-bar-toggle6"
                        onClick={(e) => {
                            e.preventDefault();
                            handleClose();
                        }}
                    >
                        Close
                    </a>
                    <a
                        href="#"
                        className="btn btn-warning btn-sm text-dark right-bar-toggle7"
                        onClick={(e) => {
                            e.preventDefault();
                            openThoughtProcessWindow();
                        }}
                    >
                        View AI Thought Process
                    </a>

                </div>
            </div>
        </motion.div>
    );
};

const ThoughtProcessAccordion = styled(Accordion)(({ theme }) => ({
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    '&:before': {
        display: 'none',
    },
}));



const MultiSelectDropdown = ({ options, selectedOptions, setSelectedOptions }) => {
    console.log("options: ", options)
    const customStyles = {
        control: (provided) => ({
            ...provided,
            backgroundColor: "#fff",
            borderColor: "#ccc",
            boxShadow: "none",
            fontSize: "1rem",
            lineHeight: "1.5",
        }),
        option: (provided, { isFocused, isSelected }) => ({
            ...provided,
            backgroundColor: isSelected
                ? "#ffd700"
                : isFocused
                    ? "#f0f0f0"
                    : "#fff",
            color: "#000",
            padding: 10,
        }),
    };

    const handleChange = (selected) => {
        setSelectedOptions(selected || []);
    };

    return (
        <div style={{ minWidth: "300px", position: 'absolute' }}>
            <Select
                options={options}
                isMulti
                styles={customStyles}
                value={selectedOptions}
                onChange={handleChange}
                placeholder="Select Project/Projects to Q & A"
                closeMenuOnSelect={false} // Keep the dropdown open for multiple selection
                isSearchable // Allow users to search for options
            />
        </div>
    );
};



const ChatEngine = () => {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [loading, setLoading] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [selectedPage, setSelectedPage] = useState(null);
    const [showMetrics, setShowMetrics] = useState(false);
    const [highlightChunk, setHighlightChunk] = useState('');
    const messagesEndRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const questionInputFormRef = useRef(null);

    const [myQuestion, setMyQuestion] = useState('');
    // --------------------- dropdown variables
    const [options, setOptions] = useState([]); // For dropdown options
    const [selectedOptions, setSelectedOptions] = useState([]); // Selected projects
    // --------------------- dropdown variables

    const currentConfigurationInfo = useSelector((state) => state.currentConfigurationInfo);
    const activeUser = useSelector((state) => state.activeUser);
    const activeUserName = activeUser.username;

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    const fetchDocProProjectNames = async () => {
        try {
            const response = await getDocproProjectNamesOnly(activeUserName);
            console.log("response@@@@@@@@@: ", response)

            if (response.status && Array.isArray(response.results)) {
                console.log("inside if")
                // Map the results array to the required format for the dropdown
                const formattedOptions = response.results.map((project) => ({
                    value: project,
                    label: project,
                }));

                console.log("formattedOptions: ", formattedOptions)

                setOptions(formattedOptions);
            } else {
                console.error("Unexpected response format or missing results:", response);
            }
        } catch (error) {
            console.error("Error fetching project names:", error);
        }
    };

    useEffect(() => {
        fetchDocProProjectNames();
    }, [activeUserName]);

    useEffect(scrollToBottom, [messages]);

    const handleSend = async (question) => {
        // console.log("handleSend")

        setInput(question)

        console.log("question: ", question)

        const newMessage = { text: question, sender: 'user' };
        setMessages((prevMessages) => [...prevMessages, newMessage]);
        questionInputFormRef.current?.clearInput(); // Use ref to clear input
        setLoading(true);

        try {
            const projectNames = selectedOptions.map(option => option.value);

            const config = {
                prompt: question,
                length: currentConfigurationInfo.length,
                temperature: currentConfigurationInfo.temperature,
                includeInternetSearch: currentConfigurationInfo.includeInternetSearch,
                includeWebScraping: currentConfigurationInfo.includeWebScraping,
                webScrapingUrl: currentConfigurationInfo.webScrapingUrl,
                folderSelections: [
                    {
                        container_name: "wizard-docpro",
                        folder_names: projectNames,
                    },
                ],
            };

            const data = await docproChat(activeUserName, config);

            console.log(data)
            console.log("data.source_documents: ", data.source_documents)

            const aiResponse = {
                text: data.content,
                sender: 'ai',
                sourceDocuments: data.source_documents,
                webScrapedData: data.web_scraped_data,
                metrics: data.metrics,
                thoughtSteps: data.thought_steps
            };
            setMessages((prevMessages) => [...prevMessages, aiResponse]);
        } catch (error) {
            const errorMessage = { text: 'Error: ' + error.message, sender: 'system' };
            setMessages((prevMessages) => [...prevMessages, errorMessage]);
        } finally {
            questionInputFormRef.current?.clearInput(); // Use ref to clear input
            setLoading(false);
        }

    };

    const renderWebScrapedData = (webScrapedData) => (
        <Box mt={1}>
            <Typography variant="subtitle2" component="div">Web Scraped Data:</Typography>
            {webScrapedData.map((data, index) => (
                <Chip
                    key={index}
                    icon={<Language />}
                    label={`${data.url.substring(0, 30)}...`}
                    size="small"
                    onClick={() => window.open(data.url, '_blank')}
                    sx={{ mr: 1, mt: 1 }}
                />
            ))}
        </Box>
    );

    const handleDocumentClick = (doc, pageNumber, chunk) => {
        setSelectedDocument(doc);
        setSelectedPage(pageNumber);
        setHighlightChunk(chunk);
    };

    // Helper function to preprocess custom tags
    const preprocessMessage = (message) => {
        return message
            .replace(/<bold<quote>/g, '<bold>')
            .replace(/<bold>/g, '**').replace(/<\/bold>/g, '**')
            .replace(/<quote>/g, '').replace(/<\/quote>/g, '')
            .replace(/<section>/g, '\n\n').replace(/<\/section>/g, '\n\n')
            .replace(/<intro>/g, '').replace(/<\/intro>/g, '')
            .replace(/<italic>/g, '_').replace(/<\/italic>/g, '_');
    };

    // Function to render a table from JSON
    const renderTable = (jsonTable) => {
        console.log("renderTable: ", jsonTable);
        try {
            const { headers, rows } = JSON.parse(jsonTable);

            // Check if rows are in array of arrays format or array of objects format
            const isArrayFormat = Array.isArray(rows[0]);

            return (
                <Table sx={{ borderCollapse: 'collapse', width: '100%' }}>
                    <TableHead>
                        <TableRow>
                            {headers.map((header, idx) => (
                                <TableCell
                                    key={idx}
                                    className="text-dark"
                                    sx={{
                                        border: '1px solid black',
                                        padding: '8px',
                                        textAlign: 'center',
                                        fontWeight: 'bold',
                                        backgroundColor: '#f1f1f1',
                                    }}
                                >
                                    {header}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isArrayFormat ? (
                            // Handle array of arrays format
                            rows.map((row, rowIndex) => (
                                <TableRow key={rowIndex}>
                                    {row.map((cell, cellIndex) => (
                                        <TableCell
                                            key={cellIndex}
                                            className="text-dark"
                                            sx={{
                                                border: '1px solid black',
                                                padding: '8px',
                                                textAlign: 'center',
                                            }}
                                        >
                                            {cell !== null ? cell : 'N/A'}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))
                        ) : (
                            // Handle array of objects format
                            rows.map((row, rowIndex) => (
                                <TableRow key={rowIndex}>
                                    {headers.map((header, cellIndex) => (
                                        <TableCell
                                            key={cellIndex}
                                            className="text-dark"
                                            sx={{
                                                border: '1px solid black',
                                                padding: '8px',
                                                textAlign: 'center',
                                            }}
                                        >
                                            {row[header] !== null ? row[header] : 'N/A'}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            );
        } catch (error) {
            console.error("Error parsing table JSON: ", error);
            return <Box component="pre">{jsonTable}</Box>; // Show raw JSON if parsing fails
        }
    };

    // Markdown renderer component
    const MarkdownRenderer = ({ content }) => {
        console.log("content: ", content);

        // Check if the content includes raw HTML tags
        const isRawHTML = /<\w+[^>]*>/.test(content);

        if (isRawHTML) {
            // Render raw HTML
            return (
                <div
                    className="html-content"
                    dangerouslySetInnerHTML={{ __html: content }}
                />
            );
        } else {
            // Render preprocessed Markdown content
            const preprocessedContent = preprocessMessage(content);

            return (
                <ReactMarkdown
                    rehypePlugins={[rehypeRaw]} // Enable rendering of raw HTML if Markdown contains it
                    components={{
                        h3: ({ node, ...props }) => (
                            <h5 style={{ marginTop: '32px', marginBottom: '16px', color: 'white' }} {...props} />
                        ),
                        h2: ({ node, ...props }) => (
                            <h2 style={{ marginTop: '32px', marginBottom: '16px' }} {...props} />
                        ),
                        h1: ({ node, ...props }) => (
                            <h1 style={{ marginTop: '32px', marginBottom: '16px', color: 'white' }} {...props} />
                        ),
                        p: ({ node, ...props }) => (
                            <p className='mb-1' {...props} />
                        ),
                        ul: ({ node, ...props }) => (
                            <ul style={{ paddingLeft: '20px', listStyleType: 'disc', marginBottom: '16px' }} {...props} />
                        ),
                        li: ({ node, ...props }) => (
                            <li style={{ marginBottom: '0.5rem' }} {...props} />
                        ),
                        code: ({ node, inline, className, children, ...props }) => {
                            const json = node.children[0]?.value?.trim();

                            if (!inline && json.includes('"type": "table"')) {
                                // Handle table rendering here
                                return renderTable(json); // Assuming renderTable is a function to render a table.
                            }
                            return (
                                <pre style={{ backgroundColor: '#f4f4f4', padding: '8px', borderRadius: '4px' }} {...props}>
                                    {children}
                                </pre>
                            );
                        },
                    }}
                >
                    {preprocessedContent}
                </ReactMarkdown>
            );
        }
    };

    const renderMessageContent = (message) => (
        <>
            {message.sender === 'user' && (
                <>
                    <div className="d-flex" style={{ paddingTop: '20px' }}>
                        <IconQuestion />
                        <div className="ps-2 w-100">
                            <p className="mb-0 font-size-12 pt-1">{activeUserName}</p>

                            <div className="d-flex align-items-center pt-1 justify-content-between">
                                <div>
                                    <h6 className="text-dark font-size-12 fw-bold mb-0">{message.text}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}

            {message.sender === 'ai' && (
                <>
                    <div className="answer-container p-3 mt-3">
                        <div className="d-flex">
                            <div>
                                <div className="ans-profile">
                                    <i className="mdi mdi-cards-diamond"></i>
                                </div>
                            </div>
                            <div className="text-white w-100 font-size-12 ps-2" style={{ fontSize: '1rem', lineHeight: '1.5' }}>
                                <MarkdownRenderer content={message.text} />
                                <div className="d-flex align-item-center pt-2 justify-content-between">
                                    <div>

                                        {message.metrics && (
                                            <p onClick={() => setShowMetrics(true)} className=" font-size-12 mb-0 d-flex align-item-center text-warn right-bar-toggle6 cursor-pointer">
                                                View Metrics <i className="fas fa-chevron-right ps-1"></i>
                                            </p>
                                        )}



                                    </div>
                                    {/* <div>
                            <i className="far fa-thumbs-up font-size-12 pe-2 ans-comment"></i>
                            <i className="far fa-thumbs-down ans-comment font-size-12 pe-2"></i>
                            <i className="fas fa-sync-alt ans-comment font-size-12"></i>
                          </div> */}
                                </div>
                            </div>
                        </div>
                    </div>


                    {message.sourceDocuments && (
                        <div className="row p-3">
                            {message.sourceDocuments.map((doc, idx) => (
                                <div key={idx} className="col-6 pe-1">
                                    <div
                                        className="p-2 bg-secondary border-radius-8px d-flex align-items-center justify-content-between right-bar-toggle5 cursor-pointer"
                                        onClick={() => handleDocumentClick(doc.title, doc.page_number, doc.chunk)}
                                        style={{ marginBottom: '0.5rem' }} // Add spacing between cards
                                    >
                                        <div className="col-11">
                                            <p className="mb-0 font-size-12 text-dark fw-bold">
                                                {`${doc.title} - Page ${doc.page_number}`}
                                            </p>
                                        </div>
                                        <div className="col-1">
                                            <div className="d-flex justify-content-end">
                                                <i className="mdi mdi-send"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}



                    {currentConfigurationInfo.includeWebScraping && message.webScrapedData && renderWebScrapedData(message.webScrapedData)}

                </>

            )}

        </>
    );

    const renderThoughtProcess = (thoughtSteps) => (
        <ThoughtProcessAccordion>
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="thought-process-content"
                id="thought-process-header"
            >
                <Typography className="text-dark" component="div">AI Thought Process</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <List>
                    {thoughtSteps.map((step, index) => (
                        <ListItem key={index}>
                            <Box>
                                <Typography variant="subtitle1" component="div">{step.title}</Typography>
                                <Typography variant="body2" component="div">{step.description}</Typography>
                                {step.props && (
                                    <Box mt={1}>
                                        {Object.entries(step.props).map(([key, value]) => (
                                            <Chip
                                                key={key}
                                                label={`${key}: ${value}`}
                                                size="small"
                                                sx={{ mr: 1, mt: 1 }}
                                            />
                                        ))}
                                    </Box>
                                )}
                            </Box>
                        </ListItem>
                    ))}
                </List>
            </AccordionDetails>
        </ThoughtProcessAccordion>
    );

    return (
        <>
            <Breadcrumb title="DocPro" homeLink="#" currentPage='Q & A' myFlag="showDocProProjects" />

            <div style={{ margin: '0px 160px', height: '20px' }}>
                {/* <h2 className="fw-bold pt-0 mb-0">Hello {activeUser.name}</h2>
                <h2 className="text-secondary fw-medium mb-3">How can I help you?</h2> */}

                <MultiSelectDropdown
                    options={options}
                    selectedOptions={selectedOptions}
                    setSelectedOptions={setSelectedOptions}
                />

                <div className='searchafter' style={{ marginLeft: '-10px' }}>
                    <div className='max-h-150px'>
                        {messages.map((message, index) => (
                            <>
                                {renderMessageContent(message)}
                            </>
                        ))}

                        {loading && (<div className="answer-container p-3 mt-3">
                            <div className="d-flex">
                                <div>
                                    <div className="ans-profile">
                                        <i className="mdi mdi-cards-diamond"></i>
                                    </div>
                                </div>
                                <div className="text-white w-100 font-size-12 ps-2" style={{ fontSize: '1rem', lineHeight: '1.5' }}>
                                    <Loader />
                                </div>
                            </div>
                        </div>

                        )}

                        <div ref={messagesEndRef} />



                        {selectedDocument && (
                            <DocumentViewer
                                document={selectedDocument}
                                onClose={() => setSelectedDocument(null)}
                                initialPage={selectedPage}
                                highlightChunk={highlightChunk}
                            />
                        )}
                        {showMetrics && messages.length > 0 && messages[messages.length - 1].metrics && (
                            <MetricsModal
                                metrics={messages[messages.length - 1].metrics}
                                thoughtSteps={messages[messages.length - 1].thoughtSteps}
                                onClose={() => setShowMetrics(false)}
                            />
                        )}
                    </div>
                </div>
            </div>
            <QuestionInputForm
                onSubmit={handleSend}
                isLoading={loading}
            />
        </>
    );
};

export default ChatEngine;
