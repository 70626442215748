import React from 'react';

/**
 * AlertNotification Component
 * @param {string} message - The alert message to display.
 * @param {function} onClose - Function to handle closing the alert.
 * @param {string} type - Type of alert (e.g., "success", "error", "info", "warning").
 * @param {boolean} autoClose - Whether the alert should close automatically after a timeout.
 * @param {number} autoCloseTime - Duration (in milliseconds) before the alert auto-closes (default: 3000ms).
 */
export const AlertNotification = ({
    message,
    onClose,
    type = 'info', // default alert type
    autoClose = false,
    autoCloseTime = 8000,
}) => {
    React.useEffect(() => {
        if (autoClose) {
            const timer = setTimeout(() => {
                onClose();
            }, autoCloseTime);
            return () => clearTimeout(timer); // Cleanup the timer on unmount
        }
    }, [autoClose, autoCloseTime, onClose]);

    const alertClass = {
        success: 'alert-success',
        error: 'alert-danger',
        info: 'alert-info',
        warning: 'alert-warning',
    }[type];

    const iconColors = {
        success: '#53C627', // Green
        error: '#dc3545',   // Red
        info: '#17a2b8',    // Blue
        warning: '#ffc107'  // Yellow
    };
    
    const iconStyle = {
        fontSize: '24px',
        color: iconColors[type] || '#000', // Default to black if type is not recognized
        minWidth: '24px',
        marginRight: '0.75rem' // Equivalent to 'me-3' in Bootstrap
    };
    
    const iconClasses = {
        success: 'fas fa-check-circle',    // Check icon for success
        error: 'fas fa-times-circle',      // Cross icon for error
        info: 'fas fa-info-circle',        // Info icon for informational messages
        warning: 'fas fa-exclamation-circle' // Exclamation icon for warnings
    };
    
    const iconClass = iconClasses[type] || 'fas fa-question-circle'; // Default to a question mark icon

    return (
        <div className="alert-dismissible pe-3 fade performocr-status fw-medium align-items-center show" role="alert">
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <i className={iconClass} style={iconStyle}></i>
                <div style={{ paddingRight: '1rem', flexGrow: 1 }}>{message}</div>
            </div>
            <button type="button" className="btn-close p-2" data-bs-dismiss="alert" aria-label="Close" onClick={onClose}></button>
        </div>
    );
};