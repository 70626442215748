import React from 'react';
import { LineChart, Line, AreaChart, Area } from 'recharts';

const MetricCard = ({ title, value, highlight, data }) => {
  const chartData = data || Array(7).fill(0).map((_, i) => ({
    value: Math.random() * parseFloat(value || 1)
  }));

  return (
    <div className="bg-white rounded-lg border p-4 min-w-[200px] flex-shrink-0" style={{width:'230px'}}>
      <h6 className="text-gray-500 text-sm mb-1">{title}</h6>
      <p className={`text-lg font-medium ${highlight ? 'text-indigo-600' : ''}`}>{value}</p>
      <div className="h-16 mt-2">
        {value > 1 ? (
          <LineChart width={160} height={60} data={chartData}>
            <Line type="monotone" dataKey="value" stroke={highlight ? "#4F46E5" : "#9CA3AF"} strokeWidth={1.5} dot={false} />
          </LineChart>
        ) : (
          <AreaChart width={160} height={60} data={chartData}>
            <Area type="monotone" dataKey="value" stroke={highlight ? "#4F46E5" : "#9CA3AF"} fill={highlight ? "#EEF2FF" : "#F3F4F6"} strokeWidth={1.5} />
          </AreaChart>
        )}
      </div>
    </div>
  );
};


const MetricSection = ({ title, metrics }) => (
    <div className="bg-white rounded-lg border p-4 mb-4">
      <h6 className="text-sm font-medium mb-4">{title}</h6>
      <div className="overflow-x-auto">
        {/* Flex container for row layout */}
        <div className="row mb-3 flex gap-3 overflow-x-auto">{metrics}</div>
      </div>
    </div>
  );
  

const MetricsDashboard = () => {
  const sampleData = {
    raqQuality: {
      contextAdherence: { value: "0.405", data: Array(7).fill(0).map(() => ({ value: Math.random() * 0.5 })) },
      completeness: { value: "0.697", data: Array(7).fill(0).map(() => ({ value: Math.random() * 0.8 })) },
      attribution: { value: "0.250", data: Array(7).fill(0).map(() => ({ value: Math.random() * 0.3 })) },
      chunkUtilization: { value: "0.046", data: Array(7).fill(0).map(() => ({ value: Math.random() * 0.1 })) }
    },
    outputQuality: {
      correctness: { value: "0.167", data: Array(7).fill(0).map(() => ({ value: Math.random() * 0.2 })) }
    },
    systemMetrics: {
      latency: { value: "108129", data: Array(7).fill(0).map(() => ({ value: Math.random() * 120000 })) },
      totalCost: { value: "0.018800", data: Array(7).fill(0).map(() => ({ value: Math.random() * 0.02 })) },
      totalResponses: { value: "14", data: Array(7).fill(0).map(() => ({ value: Math.random() * 20 })) },
      avgCost: { value: "0.001343", data: Array(7).fill(0).map(() => ({ value: Math.random() * 0.002 })) }
    },
    safetyMetrics: {
      avgPII: { value: "0", data: Array(7).fill(0).map(() => ({ value: Math.random() * 0.1 })) },
      countPII: { value: "0", data: Array(7).fill(0).map(() => ({ value: Math.random() * 1 })) },
      avgSexist: { value: "0.103", data: Array(7).fill(0).map(() => ({ value: Math.random() * 0.2 })) }
    }
  };

  const tableData = [
    {
      nodeType: "RunnableSequence",
      input: "What was Costco's revenue...",
      output: "Costco's revenue in...",
      contextAdherence: "low",
      completeness: "high",
      attribution: "1 of 4",
      utilization: "low",
      correctness: "low",
      latency: "108,133 ms",
      cost: "$0.0014",
      pii: "None"
    }
    // Add more rows as needed
  ];

  return (
    <div className="min-h-screen bg-gray-50 p-4">
      <div className="min-h-screen bg-gray-50 p-4 flex flex-col space-y-4" style={{ maxHeight: "calc(80vh - 60px)",overflow:"scroll" }}>
        <MetricSection
          title="RAQ Quality"
          metrics={Object.entries(sampleData.raqQuality).map(([key, data]) => (
            <MetricCard
              key={key}
              title={key.charAt(0).toUpperCase() + key.slice(1)}
              value={data.value}
              data={data.data}
              highlight
            />
          ))}
        />

        <MetricSection
          title="Output Quality"
          metrics={Object.entries(sampleData.outputQuality).map(([key, data]) => (
            <MetricCard
              key={key}
              title={key.charAt(0).toUpperCase() + key.slice(1)}
              value={data.value}
              data={data.data}
              highlight
            />
          ))}
        />

        <MetricSection
          title="System Metrics"
          metrics={Object.entries(sampleData.systemMetrics).map(([key, data]) => (
            <MetricCard
              key={key}
              title={key.charAt(0).toUpperCase() + key.slice(1)}
              value={data.value}
              data={data.data}
            />
          ))}
        />

        <MetricSection
          title="Safety Metrics"
          metrics={Object.entries(sampleData.safetyMetrics).map(([key, data]) => (
            <MetricCard
              key={key}
              title={key.charAt(0).toUpperCase() + key.slice(1)}
              value={data.value}
              data={data.data}
              highlight={key === 'avgSexist'}
            />
          ))}
        />

        <div className="bg-white rounded-lg border overflow-hidden">
          <div className="overflow-x-auto">
            <table className="w-full min-w-[1200px]">
              <thead className="bg-gray-50">
                <tr>
                  {['Node Type', 'Input', 'Output', 'Context Adherence', 'Completeness', 'Attribution', 
                    'Utilization', 'Correctness', 'Latency', 'Cost', 'PII'].map(header => (
                    <th key={header} className="p-3 text-left text-sm font-medium text-gray-600">
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="divide-y">
                {tableData.map((row, index) => (
                  <tr key={index}>
                    <td className="p-3">{row.nodeType}</td>
                    <td className="p-3 max-w-xs truncate">{row.input}</td>
                    <td className="p-3 max-w-xs truncate">{row.output}</td>
                    <td className="p-3">
                      <span className={`px-2 py-1 rounded text-xs ${
                        row.contextAdherence === 'low' ? 'bg-red-100 text-red-800' :
                        row.contextAdherence === 'medium' ? 'bg-yellow-100 text-yellow-800' :
                        'bg-green-100 text-green-800'
                      }`}>
                        {row.contextAdherence}
                      </span>
                    </td>
                    <td className="p-3">
                      <span className={`px-2 py-1 rounded text-xs ${
                        row.completeness === 'low' ? 'bg-red-100 text-red-800' :
                        row.completeness === 'medium' ? 'bg-yellow-100 text-yellow-800' :
                        'bg-green-100 text-green-800'
                      }`}>
                        {row.completeness}
                      </span>
                    </td>
                    <td className="p-3">{row.attribution}</td>
                    <td className="p-3">{row.utilization}</td>
                    <td className="p-3">{row.correctness}</td>
                    <td className="p-3">{row.latency}</td>
                    <td className="p-3">{row.cost}</td>
                    <td className="p-3">{row.pii}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MetricsDashboard;