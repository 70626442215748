// src/components/Login.js

import React, { useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { updateExclusiveFlags } from '../utils/reduxUtils';
import logo_icon from "../assets/images/logo_new.svg";
import signin_up_git from '../assets/images/signin-up.gif';

const Login = () => {

    const dispatch = useDispatch();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const { login } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        console.log("handle login submit")
        e.preventDefault();
        setErrorMessage('');

        if (username === '' || password === '') {
            setErrorMessage("Uh-oh! Something's not quite right with your username or password. Give it another try - we've got this! ");
            return;
        }

        try {
            const response = await login(username, password);
            console.log("response.result: ", response)
            if ('access_token' in response) {
                updateExclusiveFlags(dispatch, {
                    showUserCustomizeMessage: true
                });

                // Replace `response.chatId` with the actual chat ID from your login response
                // const chatId = uuidv4().split('-').join('-');; // Set a default or fallback chat ID
                // navigate(`/chat/New_Chat`);
                navigate('/');
            } else {
                setErrorMessage(response.message || "Invalid username or password");
            }
        } catch (error) {
            console.error('Login error:', error);
            setErrorMessage('An unexpected error occurred. Please try again.');
        }
    };
    return (
        <div className="main-content h-100 m-0">
            <div className="h-100">
                <div className="container-fluid h-100">
                    <div className="row h-100">
                        <div className="col-7 pe-5">
                            <div className="d-flex h-100 justify-content-end align-item-center pe-5">
                                <div className="mw-500px">
                                    <img
                                        src={signin_up_git}
                                        className="w-450px pb-2 position-relative left-m28"
                                        alt="Sign In GIF"
                                    />
                                    <h1 className="fw-bold font-size-48">
                                        Your AI Assistant for Instant Answers and Smarter Tasks!
                                    </h1>
                                    {/* <p className="fw-bold">
                                        If you don't have an account, Let{' '}
                                        <a href="/registernow" className="text-warning reg fw-bold">
                                            Register Now
                                        </a>
                                    </p> */}
                                </div>
                            </div>
                        </div>

                        <div className="col-5 ps-5">
                            <div className="d-flex h-100 justify-content-start align-item-center">
                                <div className="mw-320px">
                                    <div className="d-flex justify-content-center pb-5">
                                        <img
                                            src={logo_icon}
                                            className="w-160px"
                                            alt="Logo"
                                        />
                                    </div>

                                    <form onSubmit={handleSubmit}>
                                        <input
                                            type="text"
                                            className={`form-control w-100 mb-4 ${errorMessage ? "error" : ""}`}
                                            placeholder="Username"
                                            value={username}
                                            onChange={(e) => {
                                                setUsername(e.target.value);
                                                if (errorMessage) setErrorMessage('');
                                            }}
                                            autoComplete="username"
                                            style={{ paddingLeft: '15px' }}
                                        />

                                        <input
                                            type="password"
                                            className={`form-control w-100 mb-4 ${errorMessage ? "error" : ""}`}
                                            placeholder="Password"
                                            value={password}
                                            onChange={(e) => {
                                                setPassword(e.target.value);
                                                if (errorMessage) setErrorMessage('');
                                            }}
                                            autoComplete="current-password"
                                            style={{ paddingLeft: '15px' }}
                                        />

                                        {/* <div className="d-flex justify-content-end mb-4 pt-1">
                                            <a href='#' style={{paddingTop: '5px'}} className="font-size-1">Forget Password?</a>
                                        </div> */}

                                        <div className="mb-2">
                                            {errorMessage && (
                                                <p id="err-msg" className="error-msg font-size-12 fw-bold mb-4">
                                                    {errorMessage}
                                                </p>
                                            )}
                                        </div>

                                        <button 
                                            type="submit" 
                                            className="btn btn-warning fw-bold w-100 align-items-center justify-content-center"
                                            style = {{height: '55px', fontSize: '16px', borderRadius: '10px'}}
                                        >
                                            Sign In
                                        </button>


                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
