// src/services/chatService.js
import CryptoJS from 'crypto-js';

// Base64 encoded key and IV
const base64Key = "K5UXUYLSMQ======";
const base64IV = "V2l6YXJk";

// Decode the base64 key and pad to 32 bytes (AES-256)
const decodedKey = CryptoJS.enc.Base64.parse(base64Key);
const secretKey = CryptoJS.lib.WordArray.create(decodedKey.words.slice(0, 8), 32);  // Ensure 32 bytes

// Decode the base64 IV and pad to 16 bytes
const decodedIV = CryptoJS.enc.Base64.parse(base64IV + "=".repeat((4 - (base64IV.length % 4)) % 4)); // Proper padding for base64
const iv = CryptoJS.lib.WordArray.create(decodedIV.words.slice(0, 4), 16);  // Ensure 16 bytes

// Encrypt data
const encryptData = (username, password) => {
    const data = `${username}::##::${password}`;

    const encrypted = CryptoJS.AES.encrypt(data, secretKey, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    });

    return encrypted.toString();  // Base64-encoded ciphertext
};


const encryptUsername = (username) => {
    // try {
    //     // Prepare encryption key and IV
    //     // const base64Key = "K5UXUYLSMQ======";
    //     // let key = CryptoJS.enc.Base64.parse(base64Key);
    //     // key = CryptoJS.enc.Utf8.parse(key.toString(CryptoJS.enc.Utf8).padEnd(32, "\0")); // Ensure 32 bytes for AES-256

    //     // const base64IV = "V2l6YXJk";
    //     // let iv = CryptoJS.enc.Base64.parse(base64IV);
    //     // iv = CryptoJS.enc.Utf8.parse(iv.toString(CryptoJS.enc.Utf8).padEnd(16, "\0")); // Ensure 16 bytes for IV



    //     const base64Key = "K5UXUYLSMQ======";
    //     let key = CryptoJS.enc.Base64.parse(base64Key);
    //     key = CryptoJS.enc.Hex.parse(key.toString(CryptoJS.enc.Hex).padEnd(64, "0")); // Ensure 32 bytes (64 hex chars)

    //     const base64IV = "V2l6YXJk";
    //     let iv = CryptoJS.enc.Base64.parse(base64IV);
    //     iv = CryptoJS.enc.Hex.parse(iv.toString(CryptoJS.enc.Hex).padEnd(32, "0")); // Ensure 16 bytes (32 hex chars)


    //     // Create credentials string
    //     const credentials = username;
    //     console.debug(`Created credentials string for user: ${username}`);

    //     // Encrypt
    //     const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(credentials), key, {
    //         iv: iv,
    //         mode: CryptoJS.mode.CBC,
    //         padding: CryptoJS.pad.Pkcs7,
    //     });

    //     return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
    // } catch (error) {
    //     console.error(`Encryption error: ${error.message}`);
    //     throw error;
    // }

    // const data = `${username}::##::${password}`;

    const encrypted = CryptoJS.AES.encrypt(username, secretKey, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    });

    return encrypted.toString();  // Base64-encoded ciphertext
};


// Updated getChatResponse method
export const getChatResponse = async (query, access_rules) => {
    const API_URL_PYTHON = process.env.REACT_APP_RAG;

    try {
        // Attempt to make the API request
        let response = await fetch(`${API_URL_PYTHON}/stream_chat`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('access_token').replace(/"/g, '')}`,
            },
            body: JSON.stringify({ query: query, rules: access_rules }),
        });

        if (response.status === 401) {
            throw new Error('Access token expired'); // Trigger refresh in the catch block
        }

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error in API call:', error);

        if (error.message === 'Access token expired') {
            try {
                const refreshToken = localStorage.getItem('refresh_token').replace(/"/g, '');
                const newAccessToken = await refreshAccessToken(refreshToken); // Refresh the token

                // Retry the original request with the new access token
                const response = await fetch(`${API_URL_PYTHON}/stream_chat`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${newAccessToken}`,
                    },
                    body: JSON.stringify({ query: query, rules: access_rules }),
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                return data;
            } catch (refreshError) {
                console.error('Failed to retry after refreshing token:', refreshError);
                throw refreshError; // Bubble up if refreshing or retrying fails
            }
        } else {
            return "Please retry"; // Re-throw other errors
        }
    }
};

export const getChatMLResponse = async (query, access_rules) => {
    const API_URL_PYTHON = process.env.REACT_APP_RAG_ML;
    const time_stamp = Date.now()

    const response = await fetch(`${API_URL_PYTHON}/stream_chat`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token').replace(/"/g, '')}`
        },

        body: JSON.stringify({ query: query, rules: 'Wine,Luxury,Premium,RTD,Prestige,Popular' }),
    });

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return data;
};

export const getChatHistory = async (userid) => {
    const API_URL_PYTHON = process.env.REACT_APP_LOGIN;
    const response = await fetch(`${API_URL_PYTHON}/getchathistory`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ "userid": userid }),
    });

    const ch_data = await response.json();
    return ch_data;
}

export const getUserCustomizeMessage = async (hostname) => {
    const API_URL_PYTHON = process.env.REACT_APP_LOGIN;
    const response = await fetch(`${API_URL_PYTHON}/getusercustomizemessage`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ "hostname": hostname }),
    });

    const ucm_data = await response.json();
    return ucm_data;
}

export const saveChat = async (chat_info) => {
    const API_URL_PYTHON = process.env.REACT_APP_LOGIN;

    try {
        const response = await fetch(`${API_URL_PYTHON}/savechat`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(chat_info),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const ch_data = await response.json();
        return ch_data;
    } catch (error) {
        throw new Error('Network response was not ok');;
    }
}

export const getLoginCredentials = async (username, password) => {
    try {
        const API_URL_PYTHON = process.env.REACT_APP_DOCUMENT;

        const response = await fetch(`${API_URL_PYTHON}/api/v1/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ request_token: encryptData(username, password) }),
            // body: JSON.stringify({ username: username, password: password }),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Failed to fetch login credentials:', error);
        throw error; // Propagate the error to be handled by the caller
    }
};

export const getUserDetails = async () => {
    try {
        const API_URL_PYTHON = process.env.REACT_APP_LOGIN;

        const response = await fetch(`${API_URL_PYTHON}/getuserdetails`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            // body: JSON.stringify({ username: username, password: password }),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Failed to fetch users details:', error);
        throw error;
    }
};

export const saveNewUser = async (newUserListInfo) => {
    try {
        const API_URL_PYTHON = process.env.REACT_APP_LOGIN;

        const response = await fetch(`${API_URL_PYTHON}/addnewuser`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ newuserlist: newUserListInfo }),

        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const sv_data = await response.json();
        return sv_data;
    } catch (error) {
        console.error('Failed to fetch users details:', error);
        throw error;
    }
}

export const updateUserDetails = async (updatedUserInfo) => {
    try {
        const API_URL_PYTHON = process.env.REACT_APP_LOGIN;

        const response = await fetch(`${API_URL_PYTHON}/updateuser`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedUserInfo),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const up_data = await response.json();
        return up_data;
    } catch (error) {
        console.error('Failed to update user details:', error);
        throw error;
    }
};

export const getUserRoles = async () => {
    try {
        const API_URL_PYTHON = process.env.REACT_APP_LOGIN;

        const response = await fetch(`${API_URL_PYTHON}/userroles`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            // body: JSON.stringify(),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const user_roles_data = await response.json();
        return user_roles_data;
    } catch (error) {
        console.error('Failed to update user details:', error);
        throw error;
    }
};

// ------------------------------------------------------------------------------------------- DocPro -> saveUploadedFiles
export const saveUploadedFiles = async (userid, file_name, status) => {
    try {
        const API_URL_PYTHON = process.env.REACT_APP_LOGIN;

        const response = await fetch(`${API_URL_PYTHON}/savedocpro`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                userid: userid,
                file_name: file_name,
                status: status
            }),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const saved_details = await response.json();
        return saved_details;

    } catch (error) {
        console.error('error:', error);
        throw error;
    }
}
// ------------------------------------------------------------------------------------------- DocPro -> deleteDocproUploadedFile
export const deleteDocproUploadedFile = async (userid, docproid) => {
    try {
        const API_URL_PYTHON = process.env.REACT_APP_LOGIN;

        const response = await fetch(`${API_URL_PYTHON}/deletedocprodetails`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userid: userid, docproid: docproid }),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const del_docpro_details = await response.json();
        return del_docpro_details;
    } catch (error) {
        console.error('error:', error);
        throw error;
    }
};
// ------------------------------------------------------------------------------------------- DocPro -> updateDocproFileStatus
export const updateDocproFileStatus = async (userid, docproid, status) => {
    try {
        const API_URL_PYTHON = process.env.REACT_APP_LOGIN;

        const response = await fetch(`${API_URL_PYTHON}/updatedocprostatus`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userid: userid, docproid: docproid, status: status }),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const update_docpro_details = await response.json();
        return update_docpro_details;
    } catch (error) {
        console.error('error:', error);
        throw error;
    }
};
// ------------------------------------------------------------------------------------------- DocPro -> My Projects -> create project
export const createDocproProject = async (username, projectname) => {
    try {
        const API_URL_PYTHON = process.env.REACT_APP_DOCUMENT;

        const response = await fetch(`${API_URL_PYTHON}/api/v1/documents/folder`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('access_token').replace(/"/g, '')}`
            },
            // body: JSON.stringify({ encrypted_username: 'X6hhnZv+9wOgfG0RPtg68w==', folder_path: projectname }),
            body: JSON.stringify({ encrypted_username: encryptUsername(username), folder_path: projectname }),

        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Failed to create DocPro Project:', error);
        throw error;
    }
};
// ------------------------------------------------------------------------------------------- DocPro -> My Projects -> Delete project
export const deleteDocproProject = async (username, projectname) => {
    try {
        const API_URL_PYTHON = process.env.REACT_APP_DOCUMENT;

        const response = await fetch(`${API_URL_PYTHON}/api/v1/documents/folder`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('access_token').replace(/"/g, '')}`
            },
            body: JSON.stringify({ encrypted_username: encryptUsername(username), folder_path: projectname }),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        // console.log("create project api response - data: " + data);
        return data;
    } catch (error) {
        console.error('Failed to create DocPro Project:', error);
        throw error;
    }
};
// ------------------------------------------------------------------------------------------- DocPro -> My Projects -> list all projects when the page is load for the user
export const getAllDocProProjectsForUser = async (username) => {
    try {
        const API_URL_PYTHON = process.env.REACT_APP_DOCUMENT;

        // Add the `encrypted_username` as a query parameter in the URL
        const queryParams = new URLSearchParams({
            // encrypted_username: 'X6hhnZv+9wOgfG0RPtg68w==',
            encrypted_username: encryptUsername(username),
        });

        const response = await fetch(`${API_URL_PYTHON}/api/v1/folders/folder-statistics?${queryParams}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('access_token').replace(/"/g, '')}`,
            },
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Failed to get DocPro Project Details:', error);
        throw error;
    }
};
// ------------------------------------------------------------------------------------------- DocPro -> My Projects -> click anyone of the project -> (getDocproDetails)
export const getDocproDetails = async (username, projectname) => {
    try {
        const API_URL_PYTHON = process.env.REACT_APP_DOCUMENT;

        const queryParams = new URLSearchParams({
            encrypted_username: encryptUsername(username),
            folder_path: projectname,
        });

        const response = await fetch(`${API_URL_PYTHON}/api/v1/folders/folder-files?${queryParams}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('access_token').replace(/"/g, '')}`,
            },
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const docpro_details = await response.json();
        return docpro_details;
    } catch (error) {
        console.error('error:', error);
        throw error;
    }
};
// ------------------------------------------------------------------------------------------- DocPro -> Q & A -> dropdown list
export const getDocproProjectNamesOnly = async (username) => {
    try {
        const API_URL_PYTHON = process.env.REACT_APP_DOCUMENT;

        const queryParams = new URLSearchParams({
            encrypted_username: encryptUsername(username),
        });

        const response = await fetch(`${API_URL_PYTHON}/api/v1/folders/folder-list?${queryParams}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('access_token').replace(/"/g, '')}`,
            },
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const docpro_projectNames = await response.json();
        return docpro_projectNames;
    } catch (error) {
        console.error('error:', error);
        throw error;
    }
};
// ------------------------------------------------------------------------------------------- DocPro -> My Projects -> click project -> upload files
export const uploadFilesInDocproProject = async (username, projectname, filesToUpload) => {
    try {
        const API_URL_PYTHON = process.env.REACT_APP_DOCUMENT;

        const formData = new FormData();
        formData.append('encrypted_username', encryptUsername(username));
        formData.append('folder_path', projectname);
        filesToUpload.forEach(({ file }) => formData.append('files', file));

        const response = await fetch(`${API_URL_PYTHON}/api/v1/documents/upload`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token').replace(/"/g, '')}`,
            },
            body: formData,
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const docpro_projectNames = await response.json();
        return docpro_projectNames;
    } catch (error) {
        console.error('error:', error);
        throw error;
    }
};
// ------------------------------------------------------------------------------------------- DocPro -> My Projects -> Delete project
export const deleteDocproProjectFile = async (username, projectname, file) => {
    console.log("createDocproProject")
    try {
        const API_URL_PYTHON = process.env.REACT_APP_DOCUMENT;

        const file_name = projectname + "/" + file
        const response = await fetch(`${API_URL_PYTHON}/api/v1/documents/file`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('access_token').replace(/"/g, '')}`
            },
            body: JSON.stringify({ encrypted_username: encryptUsername(username), file_path: file_name }),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        // console.log("create project api response - data: " + data);
        return data;
    } catch (error) {
        console.error('Failed to create DocPro Project:', error);
        throw error;
    }
};
// ------------------------------------------------------------------------------------------- DocPro -> MyProject -> Project -> select file -> performOCR
export const performOCR = async (username, folderPaths) => {
    try {
        const DOCUMENT_API_URL = process.env.REACT_APP_DOCUMENT;

        const url = `${DOCUMENT_API_URL}/api/v1/ocr/perform`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('access_token').replace(/"/g, '')}`
            },
            body: JSON.stringify({ encrypted_username: encryptUsername(username), folder_paths: folderPaths }),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        // console.log("create project api response - data: " + data);
        return data;
    } catch (error) {
        console.error('error:', error);
        throw error;
    }
};
// -------------------------------------------------------------------------------------------DocPro -> MyProject -> Project -> select file -> Translation
export const performTranslation = async (username, folderPaths) => {
    try {
        const DOCUMENT_API_URL = process.env.REACT_APP_DOCUMENT;

        const url = `${DOCUMENT_API_URL}/api/v1/translation/perform`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('access_token').replace(/"/g, '')}`
            },
            body: JSON.stringify({ encrypted_username: encryptUsername(username), folder_paths: folderPaths }),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('error:', error);
        throw error;
    }
};
// -------------------------------------------------------------------------------------------DocPro -> Indexing
export const docproIndexing = async (username, folder_name) => {
    try {
        const DOCUMENT_API_URL = process.env.REACT_APP_DOCUMENT;

        const url = `${DOCUMENT_API_URL}/api/v1/indexing/create-index`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('access_token').replace(/"/g, '')}`
            },
            body: JSON.stringify({ encrypted_username: encryptUsername(username), folder_name: folder_name }),
        });


        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        return data;
    } catch (error) {
        console.error('error:', error);
        throw error;
    }
};
// -------------------------------------------------------------------------------------------DocPro -> Indexing
export const docproIndexingStatus = async (username, folder_name) => {
    try {
        const DOCUMENT_API_URL = process.env.REACT_APP_DOCUMENT;

        const encoded = encodeURIComponent(encryptUsername(username));
        console.log(encoded);

        // Include parameters in the query string
        const url = `${DOCUMENT_API_URL}/api/v1/indexing/status/${folder_name}?encrypted_username=${encoded}`;

        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('access_token').replace(/"/g, '')}`
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('error:', error);
        throw error;
    }
};
// -------------------------------------------------------------------------------------------DocPro -> Q&A
export const docproChat = async (username, props) => {
    try {
        const {
            prompt,
            length,
            temperature,
            includeInternetSearch,
            includeWebScraping,
            webScrapingUrl,
            folderSelections,
        } = props;

        const DOCUMENT_API_URL = process.env.REACT_APP_DOCUMENT;

        const url = `${DOCUMENT_API_URL}/api/v1/chat/chat`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('access_token').replace(/"/g, '')}`,
            },
            body: JSON.stringify({
                encrypted_username: encryptUsername(username),
                prompt,
                length,
                temperature,
                include_internet_search: includeInternetSearch,
                include_web_scraping: includeWebScraping,
                web_scraping_url: webScrapingUrl,
                folder_selections: folderSelections,
            }),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        return data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};
// ------------------------------------------------------------------------------------------- DocPro -> Q & A -> Document
export const getDocProPageContent = async (username, document, initialPage) => {
    // console.log("document: ", document)
    // console.log("initialPage: ", initialPage)

    try {
        const API_URL_PYTHON = process.env.REACT_APP_DOCUMENT;

        const queryParams = new URLSearchParams({
            encrypted_username: encryptUsername(username),
            page: initialPage,
        });

        const response = await fetch(`${API_URL_PYTHON}/api/v1/documents/document/${document}?${queryParams}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('access_token').replace(/"/g, '')}`,
            },
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
            // if (response.status === 401) {
            //     const newCredentials = await getLoginCredentials(username, password); // Add your password fetching logic here

            //     // If new credentials are returned, store the new access token
            //     if (newCredentials && newCredentials.access_token) {
            //         localStorage.setItem('access_token', `"${newCredentials.access_token}"`);

            //         // Retry the original request with the new token
            //         console.log("Retrying the createDocproProject with the new token...");
            //         return await createDocproProject(username, projectname); // Recursive call with the new token
            //     } else {
            //         throw new Error('Failed to retrieve new credentials');
            //     }
            // } else {
            //     throw new Error('Network response was not ok');
            // }
        }

        // const data = await response.json();
        return response;
    } catch (error) {
        console.error('error:', error);
        throw error;
    }
};


export const refreshAccessToken = async (refreshToken) => {
    try {
        const API_URL_PYTHON = process.env.REACT_APP_RAG;

        const response = await fetch(`${API_URL_PYTHON}/api/refresh`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ refresh_token: refreshToken })
        });

        if (!response.ok) {
            throw new Error('Failed to refresh token');
        }

        const data = await response.json();
        const newAccessToken = data.access_token;
        const newRefreshToken = data.refresh_token;

        // Store new tokens in localStorage
        localStorage.setItem('access_token', newAccessToken);
        localStorage.setItem('refresh_token', newRefreshToken);

        return newAccessToken;  // Return the new access token

    } catch (error) {
        console.error('Failed to refresh token:', error);
        throw error;
    }
};