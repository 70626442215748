import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Polyline, Marker, Popup, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

import  aeroimage from '../assets/images/aero.png';

// Function to generate the curve between origin and destination
const generateCurve = (start, end, numPoints = 20) => {
    const curve = [];
    for (let i = 0; i <= numPoints; i++) {
        const t = i / numPoints;
        const lat = start[0] * (1 - t) + end[0] * t;
        const lng = start[1] * (1 - t) + end[1] * t;
        const offsetLat = Math.sin(t * Math.PI) * 0.3;
        const offsetLng = Math.sin(t * Math.PI) * 0.3;
        curve.push([lat + offsetLat, lng + offsetLng]);
    }
    return curve;
};

const findPointOnCurve = (curve, t) => {
    const index = Math.round(t * (curve.length - 1));
    return curve[index];
};

const parseLocations = (locations) => {
    try {
        return typeof locations === 'string' ? JSON.parse(locations) : locations;
    } catch (error) {
        console.error('Error parsing locations:', error);
        return { data: { rows: [] } };
    }
};

const cityCoords = {
    Bengaluru: [12.9716, 77.5946],
    Chennai: [13.0827, 80.2707],
    Dubai: [25.276987, 55.296249],
    'New York': [40.7128, -74.0060]
};

// const MapUpdater = ({ center, zoom }) => {
//     const map = useMap();
//     useEffect(() => {
//         map.setView(center, zoom);
//     }, [center, zoom, map]);
//     return null;
// };

const MapUpdater = ({ center, zoom }) => {
    const map = useMap();
    
    useEffect(() => {
        if (map) {
            map.setView(center, zoom);
        }
        // No return statement here, so no invalid cleanup function is returned
    }, [center, zoom, map]);

    return null;
};

const Geograph = ({ locations }) => { 
    const [zoom, setZoom] = useState(8);
    const [center, setCenter] = useState([12.9716, 77.5946]);
    const prevStateRef = useRef({ zoom, center });

    const parsedLocations = parseLocations(locations);
    const rows = parsedLocations?.data?.rows || [];
    console.log('Extracted rows:', rows);

    const airplaneIconUrl = {aeroimage};
    const originIconUrl = 'https://upload.wikimedia.org/wikipedia/commons/f/fb/Map_pin_icon_green.svg';
    const destinationIconUrl = 'https://upload.wikimedia.org/wikipedia/commons/f/fb/Map_pin_icon_green.svg';

    const originIcon = L.icon({
        iconUrl: originIconUrl,
        iconSize: [25, 25],
        iconAnchor: [16, 32]
    });

    const destinationIcon = L.icon({
        iconUrl: destinationIconUrl,
        iconSize: [25, 25],
        iconAnchor: [16, 32]
    });

    const polylineStyle = {
        color: 'blue',
        weight: 4,
        dashArray: '10, 10'
    };

    const calculateAngle = (start, end) => {
        const dx = end[1] - start[1];
        const dy = end[0] - start[0];
        let angle = Math.atan2(dy, dx) * 180 / Math.PI;
        angle = (angle + 360) % 360;
        return angle + 20;
    };

    const createRotatedIcon = (iconUrl, angle) => {
        return L.divIcon({
            html: `<img src="${aeroimage}" style="width:60px;transform: rotate(${angle}deg);">`,
            iconSize: [10, 10],
            iconAnchor: [20, 20],
            className: 'rotated-icon'
        });
    };

    const selectAndRotateAirplaneIcon = (origin, destination) => {
        const originCoords = cityCoords[origin];
        const destinationCoords = cityCoords[destination];
        const angle = calculateAngle(originCoords, destinationCoords);
        return createRotatedIcon(airplaneIconUrl, angle);
    };

    const calculateDistance = (lat1, lon1, lat2, lon2) => {
        const R = 6371; // Radius of the Earth in km
        const dLat = (lat2 - lat1) * Math.PI / 180;
        const dLon = (lon2 - lon1) * Math.PI / 180;
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return R * c; // Distance in km
    };

    const calculateZoomLevel = (distance) => {
        if (distance < 500) return 7;
        if (distance < 1000) return 6;
        if (distance < 2000) return 5;
        if (distance < 4000) return 4;
        return 3;
    };

    useEffect(() => {
        // Only process if there are rows
        if (rows.length === 0) return;

        let maxDistance = 0;
        let totalLat = 0;
        let totalLon = 0;
        let count = 0;

        rows.forEach(row => {
            const originCity = Object.values(row).find(value => cityCoords[value]);
            const destinationCity = Object.values(row).filter(value => cityCoords[value]).find(value => value !== originCity);

            if (originCity && destinationCity) {
                const originCoords = cityCoords[originCity];
                const destinationCoords = cityCoords[destinationCity];
                const distance = calculateDistance(...originCoords, ...destinationCoords);
                maxDistance = Math.max(maxDistance, distance);

                totalLat += originCoords[0] + destinationCoords[0];
                totalLon += originCoords[1] + destinationCoords[1];
                count += 2;
            }
        });

        if (count > 0) {
            const newZoom = calculateZoomLevel(maxDistance);
            const newCenter = [totalLat / count, totalLon / count];

            if (
                Math.abs(newZoom - prevStateRef.current.zoom) > 0.1 ||
                Math.abs(newCenter[0] - prevStateRef.current.center[0]) > 0.001 ||
                Math.abs(newCenter[1] - prevStateRef.current.center[1]) > 0.001
            ) {
                setZoom(newZoom);
                setCenter(newCenter);
                prevStateRef.current = { zoom: newZoom, center: newCenter };
            }
        }
    }, [rows]);

    return (
        <MapContainer center={center} zoom={zoom} style={{ height: "400px", width: "100%" }}>
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <MapUpdater center={center} zoom={zoom} />

            {rows.map((row, index) => {
                console.log('Processing row:', row);

                const originCity = Object.values(row).find(value => cityCoords[value]);
                const destinationCity = Object.values(row).filter(value => cityCoords[value]).find(value => value !== originCity);

                if (!originCity || !destinationCity) return null;

                const originCoords = cityCoords[originCity];
                const destinationCoords = cityCoords[destinationCity];

                const route = generateCurve(originCoords, destinationCoords);
                const airplanePosition = findPointOnCurve(route, 0.5);
                const airplaneIcon = selectAndRotateAirplaneIcon(originCity, destinationCity);

                return (
                    <React.Fragment key={index}>
                        <Polyline positions={route} pathOptions={polylineStyle} />
                        <Marker position={airplanePosition} icon={airplaneIcon}>
                            <Popup>
                                <div style={{ textAlign: 'left' }}>
                                    <h5>Flight Route</h5>
                                    {Object.entries(row).map(([key, value]) => (
                                        <p key={key}>{key.replace('_', ' ')}: {value}</p>
                                    ))}
                                </div>
                            </Popup>
                        </Marker>
                        <Marker position={originCoords} icon={originIcon}>
                            <Popup>
                                <div style={{ textAlign: 'left' }}>
                                    <h5>Origin</h5>
                                    <p>{originCity}</p>
                                </div>
                            </Popup>
                        </Marker>
                        <Marker position={destinationCoords} icon={destinationIcon}>
                            <Popup>
                                <div style={{ textAlign: 'left' }}>
                                    <h5>Destination</h5>
                                    <p>{destinationCity}</p>
                                </div>
                            </Popup>
                        </Marker>
                    </React.Fragment>
                );
            })}
        </MapContainer>
    );
};

export default Geograph;