import { useSelector } from 'react-redux';
import React from 'react';
import RenderingTheResponse from './RenderingTheResponse';
import RenderingTheQuestion from './RenderingTheQuestion';

const RaiseAnswer = () => {
    const questionsAndResponses = useSelector((state) => state.questionsAndResponses) || [];
    console.log("RaiseAnswer.js")
    console.log("questionsAndResponses: ", questionsAndResponses)
    return (
        <div>
            {questionsAndResponses.map((qa, index) => {
                const { question, response } = qa;
                const { text, chartCode, sql_query, query_results, desc, source } = response || {};

                // Use question or any unique identifier if available instead of dynamic key
                const stableKey = `qa-${index}`;

                return (
                    <div
                        key={stableKey}
                        className="tab-pane fade show"
                        role="tabpanel"
                    >
                        <div className='page-cont2 searchafter'>
                            <div className=''>
                                {/* Always render the question immediately */}
                                <RenderingTheQuestion question={question} sqlQuery={sql_query} />
                                <>
                                    {/* Only render the response component if we have a response */}
                                    {/* {response && text !== '' && ( */}
                                        <RenderingTheResponse
                                            response={text}
                                            chartCode={chartCode}
                                            sqlQuery={sql_query}
                                            queryResult={query_results}
                                            desc={desc}
                                            source={source}
                                        />
                                    {/* )} */}
                                </>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default React.memo(RaiseAnswer);
