import React from 'react';

export const DeleteConfirmationModal = ({ message, onClose, onDelete }) => {
    return (
        <div style={styles.overlay}>
            <div style={styles.modal}>
                <div style={styles.header}>
                    <h3 style={styles.title}>Delete</h3>
                    <button style={styles.closeButton} onClick={onClose}>×</button>
                </div>
                <div style={styles.body}>
                    <img
                        src="/assets/images/delete.gif"
                        alt="delete-icon"
                        style={styles.icon}
                    />
                    <p style={styles.text}>
                        {message}
                        {/* Are you sure you want to delete this project or the entire folder? */}
                    </p>
                </div>
                <div style={styles.footer}>
                    <button
                        className="btn btn-light"
                        style ={{fontWeight: '100', width: "fit-content" }}
                        onClick={onClose}
                    >
                        Close
                    </button>

                    <button
                        className="btn"
                        style={styles.deleteButton}
                        onClick={onDelete}
                    >
                        Delete
                    </button>
                </div>
            </div>
        </div>
    );
};

const styles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
    },
    modal: {
        backgroundColor: '#fff',
        borderRadius: '8px',
        width: '359px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        // padding: '12px',
        position: 'relative',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        // marginBottom: '12px',
    },
    title: {
        margin: 0,
        fontSize: '18px',
        fontWeight: '500',
        padding: '12px'
    },
    closeButton: {
        background: 'none',
        border: 'none',
        fontSize: '28px',
        cursor: 'pointer',
        fontWeight: '200',
        paddingRight: '12px'
    },
    body: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px', // Adjusted for better spacing
        // marginLeft: '30px',
        // marginRight: '30px',
    },
    icon: {
        width: '70px', // Adjusted to match the second screenshot
        // height: '100px',
        position:'relative',
        bottom:'17px'
    },
    text: {
        margin: 0,
        fontSize: '16px',
        fontWeight: '500',
        color: 'black',
        marginBottom:'10px'
        
    },
    footer: {
        display: 'flex',
        gap: '10px',
        justifyContent: 'flex-end',
        padding: '10px', // Add padding instead of paddingRight
        background: '#f3f4f6',
        borderBottomLeftRadius: '8px', // Match modal border radius
        borderBottomRightRadius: '8px', // Match modal border radius
        width: '100%', // Ensure it spans full width
        boxSizing: 'border-box', // Include padding in width
    },
    deleteButton: {
        backgroundColor: '#ffc3c3',
        color: '#b3372f',
        borderRadius: '4px',
        cursor: 'pointer',
        fontWeight: '500',
    },
};
