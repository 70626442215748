import React, { useState, useRef, useEffect } from 'react';
import { setFlag, setSelectedDocProProject } from '../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { MoreVertical, Trash, X, FileText, ScanText, Languages, Brain, Search } from 'lucide-react';
import Breadcrumb from '../../utils/Breadcrumb';
import { Tooltip, tooltipClasses } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import tracelableicon from '../../assets/images/tracelabel.png';
import { AlertNotification } from '../../utils/AlertNotification';
import { getAllDocProProjectsForUser, createDocproProject, deleteDocproProject, docproIndexing, docproIndexingStatus } from '../../services/chatService';

const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#2B3D44',
        fontSize: "12px",
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: '#2B3D44',
    },
});

const ProjectCards = ({ myProjects, onCardClick, onDeleteProject, indexing,setCreateProjectFolderOpen, setInputProjectName  }) => {
    const activeUser = useSelector((state) => state.activeUser);
    const activeUserName = activeUser.username;
    const activeUserFullName = activeUser.name;
    const activeUserInitial = activeUser.initial;

    const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
    const dropdownRefs = useRef({});
    const navigate = useNavigate();

    const toggleDropdown = (index) => {
        setOpenDropdownIndex(openDropdownIndex === index ? null : index);
    };
    useEffect(() => {
        const handleClickOutside = (event) => {
            const isOutsideClick = Object.values(dropdownRefs.current).every(
                (ref) => ref && !ref.contains(event.target)
            );

            if (isOutsideClick) {
                console.log("Click detected outside the dropdown. Closing.");
                setOpenDropdownIndex(null); // Close all dropdowns
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="row max-h-200px" style={{ marginTop: "8px", overflow: 'scroll' }}>
            {myProjects.length > 0 ? (
                myProjects.map((project, index) => (
                    <div
                        className="col-4 mt-3"
                        key={index}
                        onClick={(e) => {
                            if (e.target.closest('.card')) {
                              onCardClick(project.index, project.name);
                            }
                          }}
                        style={{ marginBottom: '7px' }}
                    >
                        <div className="card min-h-100p mb-0" style={{ borderRadius: "8px", position: "relative", marginTop: "2px", cursor: "pointer" }}>
                            <div className="card-body d-grid align-content-between" >
                                <div className="d-flex justify-content-between">

                                    <div className="text-start">
                                        <h6 className="fw-medium" style={{ cursor: "pointer", textTransform: 'capitalize' }} >{project.name}</h6>
                                        <p
                                            className="font-size-12 d-flex align-items-center"
                                            style={{ marginTop: "-6px" }}
                                        >
                                            <i className="ri-calendar-2-line" style={{ marginRight: "4px", fontSize: "13px" }}></i>
                                            Updated on {project.updatedAt} by {project.updatedBy}
                                        </p>


                                        {/* Dynamic Avatars */}
                                        <div className="avatra-group " style={{ marginBottom: "1.2rem" }}>
                                            {/* =========================== dont delete this code. This is the real code =========================== */}
                                            {/* {project.sharedUsersInitials.slice(0, 5).map((initials, idx) => (
                                                <CustomTooltip
                                                    key={idx}
                                                    title={project.sharedUsersNames[idx]}
                                                    placement="top"
                                                    arrow
                                                >
                                                    <div className={`avatra custom-color${(idx % 6) + 1}`}>
                                                        {initials}
                                                    </div>
                                                </CustomTooltip>
                                            ))}
                                            {project.sharedUsersInitials.length > 5 && (
                                                <CustomTooltip
                                                    title={`${project.sharedUsersNames.length - 5} more users`}
                                                    placement="top"
                                                    arrow
                                                >
                                                    <div className="avatra custom-color6">
                                                        +{project.sharedUsersInitials.length - 5}
                                                    </div>
                                                </CustomTooltip>
                                            )} */}

                                            {/* ===========================this is for demo purpose=========================== */}
                                            <CustomTooltip
                                                key={index}
                                                title={activeUserFullName}
                                                placement="top"
                                                arrow
                                            >
                                                <div className={`avatra custom-color${(index % 6) + 1}`}>
                                                    {activeUserInitial}
                                                </div>
                                            </CustomTooltip>
                                            {/* ===========================this is for demo purpose=========================== */}
                                        </div>

                                        {/* Badges */}
                                        <div
                                            className="d-flex mt-4 flex-wrap align-items-center"
                                            style={{ cursor: "default", gap: "5px" }}
                                        >
                                            <span
                                                className="badge d-flex align-items-center"
                                                style={{
                                                    background: "#F4F4F4",
                                                    color: "#6B7280",
                                                    fontWeight: "500",
                                                    cursor: "default",
                                                }}
                                            >
                                                <FileText className="pe-2" />
                                                <span style={{marginTop:"3px"}}>Files: {project.files}</span>
                                            </span>

                                            <span
                                                className="badge d-flex align-items-center"
                                                style={{
                                                    background: "#F4F4F4",
                                                    color: "#6B7280",
                                                    fontWeight: "500",
                                                    cursor: "default",
                                                }}
                                            >
                                                <ScanText className="pe-2" />
                                                <span style={{marginTop:"3px"}}>Performed OCR: {project.performedOCR}</span>
                                            </span>

                                            <span
                                                className="badge d-flex align-items-center"
                                                style={{
                                                    background: "#F4F4F4",
                                                    color: "#6B7280",
                                                    fontWeight: "500",
                                                    cursor: "default",
                                                }}
                                            >
                                                <Languages className="pe-2" />
                                                <span style={{marginTop:"3px"}}>Translated Docs: {project.translatedDocuments}</span>
                                            </span>

                                            <span
                                                className="badge d-flex align-items-center"
                                                style={{
                                                    background: "#F4F4F4",
                                                    color: "#6B7280",
                                                    fontWeight: "500",
                                                    cursor: "default",
                                                }}
                                            >
                                                <Brain className="pe-2" />
                                                <span style={{marginTop:"3px"}}>Trained Docs: {project.trainedIndexDocuments}</span>
                                            </span>
                                        </div>
                                    </div>


                                    <div className="text-end">
                                        <div className="dropdown d-none d-sm-inline-block" ref={(el) => (dropdownRefs.current[index] = el)}>
                                            <MoreVertical
                                                className="cursor-pointer"
                                                onClick={(e) => {
                                                    e.stopPropagation(); // Prevent triggering parent card click    
                                                    toggleDropdown(index);
                                                }}

                                                size={18}
                                            />
                                            <div
                                                className={`dropdown-menu dropdown-menu-end ${openDropdownIndex === index ? "show" : ""}`}
                                                style={{
                                                    minWidth: "130px",
                                                    position: "absolute",
                                                    top: "0",
                                                    right: "0",
                                                    transform: "translateY(30px)", // Adjust dropdown below the icon
                                                    zIndex: 1050, // Ensure it's above other elements
                                                }}
                                                onClick={(e) => e.stopPropagation()}
                                            >
                                                <a
                                                    href="#"
                                                    className="dropdown-item notify-item"
                                                    style={{ paddingLeft: '14px' }}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        // indexing(project.name);
                                                        indexing(project.name, index);
                                                        setOpenDropdownIndex(null);
                                                    }}
                                                >
                                                    <p style={{ display: 'flex', alignItems: 'center', marginBottom: 0, fontSize: '12px', marginLeft: "-2px" }}>
                                                        <Search style={{ paddingRight: '4px' }} size={20} />
                                                        <span style={{ marginTop: "4px" }}>
                                                            Indexing
                                                        </span>
                                                    </p>
                                                </a>
                                                <a
                                                    href="#"
                                                    className="dropdown-item notify-item"
                                                    style={{ paddingLeft: '14px' }}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        onDeleteProject(project.name)
                                                    }}
                                                >
                                                    <p style={{ display: 'flex', alignItems: 'center', marginBottom: 0, fontSize: '12px' }}>
                                                        <Trash style={{ paddingRight: '4px' }} />
                                                        <span style={{ marginTop: "4px" }}>
                                                            Delete Project
                                                        </span>
                                                    </p>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                ))
            ) : (
                <div
                className="col-12 d-flex flex-column align-items-center justify-content-center text-center"
                style={{ backgroundColor: "white", height: "66vh", borderRadius: "8px",marginTop:"18px" }}
              >
                <img
                  src="/assets/images/nofiles.gif"
                  alt="No results found"
                  width="20%"
                  style={{ paddingTop: "0" }}

                />
                <div style={{ fontSize: "20px", fontWeight: 600,color:"rgba(29, 18, 46, 1)" ,lineHeight:"45px"}} className="mt-3">
                  No Projects or Folders
                </div>
                <p style={{ fontSize: "16px",color:"rgba(0, 0, 0, 1)" }}>
                  Looks like there are no Projects or Folders created yet.<br/>
                  Ready to get started? Just tap the button to create one!
                </p>
                <button
                     className="btn btn-warning btn-sm text-dark right-bar-toggle centered-button"
                     
                  style={{ fontSize: "12.6px", fontWeight:"500"}}
                  onClick={() => {
                    setCreateProjectFolderOpen(true);
                    setInputProjectName('');
                }}
                >
                  Create Project Folder
                </button>
              </div>
              
            )}
        </div>
    );
};


const DocProProjects = () => {
    const activeUser = useSelector((state) => state.activeUser);
    const activeUserName = activeUser.username;
    const activeUserId = activeUser.user_id;
    const activeUserInitial = activeUser.initial;
    const navigate = useNavigate();

    const d = new Date();
    let current_date = d.getDate() + "-" + (d.getMonth() + 1) + "-" + d.getFullYear();

    const dispatch = useDispatch();
    const inputRef = useRef(null); // Create a ref for the input box


    const [createProjectFolderOpen, setCreateProjectFolderOpen] = useState(false);
    const [myProjects, setMyProjects] = useState([]);
    const [inputProjectName, setInputProjectName] = useState('');
    const [indexingErrorMessage, setIndexingErrorMessage] = useState('');
    const [indexingStatus, setIndexingStatus] = useState('');
    const [myProjectName, setMyProjectName] = useState('');
    const [searchQuery, setSearchQuery] = useState('');

    // Filter projects based on the search query
    const filteredProjects = myProjects.filter((project) =>
        project.name.toLowerCase().includes(searchQuery.toLowerCase())
    );



    useEffect(() => {
        if (createProjectFolderOpen) {
            setTimeout(() => {
                inputRef.current?.focus();
            }, 0);
        }
    }, [createProjectFolderOpen]);

    useEffect(() => {
        dispatch(setFlag('overlayFlag', createProjectFolderOpen));
    }, [createProjectFolderOpen, dispatch]);

    // ----------------------------------------------- Alert Notification
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('info');
    // -----------------------------------------------


    // ===========================================================================
    const fetchDocProProjectsFiles = async () => {
        try {
            const response = await getAllDocProProjectsForUser(activeUserName);

            if (response?.status === true) {
                const formattedProjects = response.results.map((folder, index) => ({
                    index,
                    name: folder.folder_name,
                    updatedAt: new Date(folder.last_updated).toLocaleString(),
                    updatedBy: folder.last_updated_by,
                    sharedUsersInitials: folder.authorized_users.map(user => user.initial),
                    sharedUsersNames: folder.authorized_users.map(user => user.full_name),
                    files: folder.total_files,
                    performedOCR: folder.ocr_files.total_count,
                    translatedDocuments: folder.translated_files.total_count,
                    trainedIndexDocuments: folder.indexed_files.total_count,
                }));

                setMyProjects(formattedProjects);
            } else {
                setAlertMessage(response.message);
                setAlertType('error');
                setShowAlert(true);
                console.error('Unexpected response format:', response);
            }
        } catch (error) {
            console.error('Error fetching uploaded files:', error);
        }
    };

    // ===========================================================================
    useEffect(() => {
        fetchDocProProjectsFiles();
    }, [activeUserId]);
    // ===========================================================================

    const handleCreateProject = async () => {
        if (!inputProjectName.trim()) {
            setCreateProjectFolderOpen(false);
            setAlertMessage('Project name cannot be empty.');
            setAlertType('error');
            setShowAlert(true);
            return;
        }

        try {
            const response = await createDocproProject(activeUserName, inputProjectName)
            console.log("create project response: ", response)
            if (response?.status === true) {
                setCreateProjectFolderOpen(false);
                setInputProjectName('');
                setAlertMessage(response.message);
                setAlertType('success');
                setShowAlert(true);

                console.log("project created: ", inputProjectName)

                setMyProjects([]);
                await fetchDocProProjectsFiles();
            }
            else {
                setCreateProjectFolderOpen(false);
                setInputProjectName('');
                setAlertMessage(response.message);
                setAlertType('error');
                setShowAlert(true);
            }
        } catch (error) {
            setCreateProjectFolderOpen(false);
            setInputProjectName('');
            setAlertMessage(error.message);
            setAlertType('error');
            setShowAlert(true);
            console.error('create project error:', error);
        }
    };

    const handleDeleteProject = async (projectName) => {
        try {
            console.log(`Deleting project: ${projectName}`);

            const response = await deleteDocproProject(activeUserName, projectName)
            console.log("delete project response: ", response)

            if (response?.status === true) {
                setAlertMessage(response.message);
                setAlertType('success');
                setShowAlert(true);

                console.log("project deleted: ", projectName);

                setMyProjects([]);
                await fetchDocProProjectsFiles();
            }
            else {
                setAlertMessage(response.message);
                setAlertType('error');
                setShowAlert(true);
            }
        } catch (error) {
            setAlertMessage('Failed to delete project. Please try again.');
            setAlertType('error');
            setShowAlert(true);
            console.error('Error deleting project:', error);
        }
    };


    const handleCardClick = (projectId, projectName) => {
        console.log("DocProProjects Id: ", projectId);
        console.log("DocProProjects Name: ", projectName);

        dispatch(setSelectedDocProProject(projectName));
        dispatch(setFlag('showDocProProjects', false));
        dispatch(setFlag('showDocProModal', true));
    };


    const startIndexing = async () => {
        setAlertMessage('Indexing started successfully.');
        setAlertType('success');
        setShowAlert(true);
        setIndexingErrorMessage('');
        setIndexingStatus('running');

        try {
            const response = await docproIndexing(activeUserName, myProjectName);
            console.log("Indexing response: ", response);

            if (response?.status === true) {
                setAlertMessage('Index created successfully and indexing process initiated.');
                setAlertType('success');
                setShowAlert(true);
            } else {
                setAlertMessage(response.message || 'Failed to start indexing.');
                setAlertType('error');
                setShowAlert(true);
            }
        } catch (error) {
            setIndexingStatus('error');
            setIndexingErrorMessage(error.message);
            setAlertMessage('Failed to start indexing. Please try again.');
            setAlertType('error');
            setShowAlert(true);
            console.error('Error starting indexing:', error);
        }
    };

    const checkStatus = async () => {
        try {
            const response = await docproIndexingStatus(activeUserName, myProjectName);
            if (response?.status === true) {
                setIndexingStatus('completed')
                setAlertMessage(response.message || 'Successfully retrieved indexer status');
                setAlertType('success');
                setShowAlert(true);
                setMyProjects([]);
                await fetchDocProProjectsFiles();
            } else {
                setIndexingStatus('error')
                setAlertMessage(response.message || 'Failed to start indexing.');
                setAlertType('error');
                setShowAlert(true);
            }
        } catch (error) {
            setIndexingErrorMessage(error.message);
            console.error('Status check error:', error);
        }
    };

    useEffect(() => {
        let interval;
        if (indexingStatus === 'running' || indexingStatus.toLowerCase() === 'inprogress') {
            interval = setInterval(checkStatus, 5000);
        }
        return () => {
            if (interval) clearInterval(interval);
        };
    }, [indexingStatus]);


    const handleStartIndexing = async (projectName) => {
        setMyProjectName(projectName);
        await startIndexing()
    };

    return (
        <>
            <div id="modal" className="tab_content">
                {/* <div className="container-fluid"> */}
                <div className="d-flex justify-content-between">
                    <Breadcrumb title="DocPro" homeLink="#" currentPage={`My Projects ${myProjects?.length ? `(${myProjects.length} Docs)` : ''}`} />
                    <div>
                        <button
                            style={{ paddingBottom: "7px" }}
                            className="btn btn-warning btn-sm text-dark right-bar-toggle centered-button"
                            onClick={() => {
                                setCreateProjectFolderOpen(true);
                                setInputProjectName('');
                            }}
                        >
                            Create Project Folder
                        </button>
                    </div>
                </div>

                <input
                    type="text"
                    placeholder="Search by Project name"
                    className="form-control font-size-12 doc-search "
                    style={{
                        width: '340px',
                        height: '37px',
                        backgroundColor: '#fff',
                        borderRadius: "8px",
                        marginTop: "17px",
                        border: '1px solid #E1E0E3'
                    }}
                    value={searchQuery} // Controlled input
                    onChange={(e) => setSearchQuery(e.target.value)} // Update search query
                />

                <ProjectCards
                    // myProjects={myProjects}
                    myProjects={filteredProjects}
                    onCardClick={handleCardClick}
                    onDeleteProject={handleDeleteProject}
                    indexing={handleStartIndexing}
                    setCreateProjectFolderOpen={setCreateProjectFolderOpen} 
                    setInputProjectName={setInputProjectName}
                />

                {createProjectFolderOpen && (
                    <div className="right-bar p-3 w-25" >
                        <div data-simplebar className="h-100" style={{ visibility: "hidden" }}>
                            <div className="d-flex align-item-center justify-content-between">
                                <h6 className=" mb-0 " style={{fontWeight:"500",color:"#12262E",opacity:"1"}}>Create Project</h6>
                                <X className="cursor-pointer ms-auto" onClick={() => setCreateProjectFolderOpen(false)} size={16} />
                            </div>
                            <div className="trace-label p-2 d-flex align-item-center mt-1 w-fit-content" style={{backgroundColor:"#FBEBB3"}}>
                                <img src={tracelableicon} className="w-14px me-2" alt="trace label" />
                                <h6 className="font-size-10 mb-0 text-dark">Set up a new workspace to manage your tasks and documents effectively</h6>
                            </div>
                            <div style={{ marginLeft: "6px" }}>
                                <h6 className="font-size-12 mb-1 pt-3" style={{fontWeight:"500",}}>Project Name</h6>
                            </div>
                            <input
                                ref={inputRef}
                                type="text"
                                placeholder="Enter new Project name"
                                value={inputProjectName}
                                onChange={(e) => setInputProjectName(e.target.value)}
                                className="form-control font-size-12 doc-search mt-4 mb-2"
                                style={{
                                    // width: '340px',
                                    height: '37px',
                                    backgroundColor: '#fff',
                                    border: '1px solid #E1E0E3',
                                }}
                            />

                            {/* <div className="px-3"> */}
                            <div className="position-absolute w-100 " style={{ bottom: "4px", height: "26px" }} >
                                <div className="d-flex "  style={{ gap: "10px" ,marginTop:"-1px"}}>
                                    <button
                                         style={{width: "fit-content",marginLeft:"2px"}}
                                       
                                         className="btn btn-light btn-sm centered-button"
                                        onClick={() => setCreateProjectFolderOpen(false)}
                                    >
                                        Close
                                    </button>

                                    <button style={{ paddingBottom: "6px" }}
                                        className="btn btn-warning btn-sm text-dark centered-button"
                                        onClick={handleCreateProject}
                                        disabled={!inputProjectName.trim()}
                                    >
                                        Create
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {showAlert && (
                    <AlertNotification
                        message={alertMessage}
                        type={alertType}
                        onClose={() => setShowAlert(false)}
                        autoClose={true}
                        autoCloseTime={5000}
                    />
                )}
            </div>
        </>
    );
};

export default DocProProjects;

